<div class="image-container">
    <nav class="tab-navigation" role="tablist">
        <div class="tab-container">
        <button
            *ngFor="let tab of tabs"
            [class]="'tab-button ' + (tab.isActive ? 'tab-active' : '')"
            role="tab"
            [attr.aria-selected]="tab.isActive"
            [attr.aria-controls]="tab.id + '-panel'"
            [id]="tab.id"
            tabindex="0"
            (click)="selectTab(tab.id)">
            <span class="tab-label">{{tab.label}}</span>
        </button>
        </div>
    </nav>

  <div class="image-gallery" role="tabpanel">
    <img
    *ngFor="let image of activeImages"
    [src]="image"
    class="gallery-image"
    alt="Survey image"
    loading="lazy"
  />
  </div>
</div>