<div class="item-selector-container">
  <!-- Left Pane -->
  <div class="left-pane-container">
    <header class="items-header">
      <div class="items-title"><span>{{ itemsTitle }}</span></div>
      <div class="search-section-main">
        <div class="search-section add" *ngIf="!listReadOnly">
          <app-autocomplete-dropdown
            id="search-bar"
            class="autocomplete-dropdown"
            [placeholder]="searchConfig?.searchPlaceholder"
            [apiUrl]="searchConfig?.apiUrl"
            [selectFields]="searchConfig?.selectFields"
            [whereFields]="searchConfig.whereFields"
            [limit]="10"
            [orderBy]="searchConfig.orderBy"
            [bindLabel]="searchConfig.bindLabel"
            [bindValue]="searchConfig.bindValue"
            [displayColumns]="searchConfig.displayColumns"
            [additionalFilters]="searchConfig?.additionalFilters"
            [disable]="items.length === this.allowedItemSize"
            [(ngModel)]="searchText"
            (suggestionSelected)="onSuggestionSelected($event[searchConfig.bindValue])"
          >
          </app-autocomplete-dropdown>
          <button type="button" class="add-button secondary_button" [disabled]="disableAddButton" (click)="addItem()">
            <app-svg-icon class="image-icon"
                          [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
            <span>Add</span>
          </button>
        </div>
        <div class="search-section read" *ngIf="listReadOnly">
          <input
            id="search-bar-readonly"
            class="search-bar"
            type="search"
            [placeholder]="searchConfig?.searchPlaceholder || 'Search'"
          >
          <app-svg-icon class="search-icon" [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
        </div>
      </div>
    </header>

    <div class="item-list">
      <div
        class="item-row"
        *ngFor="let item of items; let i = index"
        (click)="selectItem(item, i)"
        [ngClass]="{
          'selected': isItemSelected(item),
          'disabled': item.disableCheckBox
        }"
        tabindex="0"
        role="button"
      >
        <div class="checkbox-wrapper">
          <input type="checkbox"
                 id="itemCheckbox"
                 [disabled]="item.disableCheckBox"
                 (click)="toggleCheckboxSelection(item,i); $event.stopPropagation() "
                 class="item-checkbox" aria-label="Select item" />
        </div>
        <div class="item-info">
          <span class="item-name">{{ item[entityConfig.displayValueField] }}</span>
        </div>
        <div class="remove-item" *ngIf="!listReadOnly" (click)="onDeleteItem(i); $event.stopPropagation()">
          <app-svg-icon class="delete-icon"
                        [path]="'assets/icons/delete_icon.svg'"
          ></app-svg-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Pane -->
  <div class="right-pane-container">
    <ng-template #rightPaneContent></ng-template>
  </div>
</div>
