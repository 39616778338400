import { Component, ViewContainerRef } from "@angular/core";
import { EditableTableComponent } from "../../editable-table/editable-table.component";
import { EditableTableHeader } from "../../../models/editable-table-header.interface";
import { FormsModule } from "@angular/forms";
import { filter } from "rxjs/operators";
import { TableListRequest } from "../../../models/table-list-request.interface";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../../pipes/highlight.pipe";
import { NoDataComponent } from "../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../../table-menu/table-menu.component";
import { PaginatedListDataResponse } from "../../../models/paginated-list-data-response";
import { TooltipDirective } from "../../../directives/tooltip.directive";
import { OperationService } from "../../../services/operations.service";
import { LoadingService } from "../../../services/loading.service";
import { User } from "../../../models/user.interface";
import { CargoItem } from "../../../models/cargo-item.interface";
import { ModalComponent } from "../../modal/modal.component";
import { AdvancedFilterComponent } from "../../advanced-filter/advanced-filter.component";

@Component({
  selector: "app-item-filing",
  standalone: true,
  imports: [
    EditableTableComponent,
    FormsModule,
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    NgClass,
    TooltipDirective
  ],
  templateUrl: "./item-filing.component.html",
  styleUrl: "./item-filing.component.scss"
})
export class ItemFilingComponent {
  searchTerm: any;
  focusSearch!: boolean;
  tableLoaded: boolean = false;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  sortColumn: string = "modifiedDate";
  totalTablePages: number = 0;
  currentTablePage: any;
  sortDirection: "asc" | "desc" = "desc";
  private rowsPerPage: number = 25;
  private currentPage: number = 0;
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "igmNo",
      "itemNo",
      "subItemNo",
      "igmDate",
      "blDate",
      "blNo",
      "vesselId",
      "viaNo",
      "portOfLoading",
      "modifiedDate",
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'View', value: 'view' },
  ];

  // filter
  filterFields = [
    {
      displayName: 'IGM Item Number',
      key: 'igmItemNo',
    },
    {
      displayName: 'Via Number',
      key: 'viaNo',
    },
  ];
  isFilterApplied: boolean = false;

  allTableRecordsSelected: boolean = false;
  private selectedItems: CargoItem[] = [];
  private previousTerm: string = "";

  constructor(private router: Router,
              private route: ActivatedRoute,
              private operationService: OperationService,
              private loadingService: LoadingService,
              private viewContainerRef:ViewContainerRef) {}

  ngOnInit() {
    this.fetchAllRecords()
  }

  fetchAllRecords() {
    this.loadingService.show();
    this.operationService.getImportCargoList(this.tableListRequest).subscribe({
      next: (res: any) => {
        console.log("Response received:", res.data.records);
        this.tableListData = res.data;
        this.tableLoaded = true;
        this.loadingService.hide()
      },
      error: (error: Error) => {
        console.error("Error occurred:", error);
        this.tableLoaded = false;
        this.loadingService.hide()
      }
    });
  }

  private clearSelection() {

  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length >= 3) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: "igmItemNo",
            alias: "A",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "A"
      };
      // Search when Enter is pressed
      if (event.key === 'Enter') {
      this.fetchAllRecords();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.fetchAllRecords();
    }
    this.previousTerm = searchTerm;
  }

  addNewRecord() {
    this.router.navigate(['add-new'], { relativeTo: this.route });
  }

  isAllRecordsOfCurrentPageSelected() {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: CargoItem) =>
          this.isSelected(item)
        ))
    );
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: any) {
    if (isChecked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }
  }

  filter() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = "Advanced Filter";
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput("filterFields", this.filterFields);
    if (this.tableListRequest.where) {
      filterDialogRef.setInput("appliedFilters", this.tableListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.fetchAllRecords();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression
          };
          this.isFilterApplied = true;
        } else {
          console.error("Invalid filter !");
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.fetchAllRecords();
      }
      modalRef.destroy();
    });
  }

  onResizeEnd(event: ResizeEvent, column: string) {
    if (event.edges.right) {
      const columnHeader = document.querySelector(`th.${column}_th`) as HTMLElement;
      const columnCells = document.querySelectorAll(`td.${column}_td`);
      const newWidth = `${event.rectangle.width}px`;

      if (columnHeader) {
        columnHeader.style.width = newWidth;
      }

      columnCells.forEach(cell => {
        const cellHtml = cell as HTMLElement;
        cellHtml.style.width = newWidth;
      });
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortColumn = column;
      this.sortDirection = "asc";
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection
    };
    this.fetchAllRecords()
  }

  isSelected(item: CargoItem) {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleSelection(item: CargoItem) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }
  }

  handleMenuToggle(i: number) {
    this.openMenuIndex = this.openMenuIndex === i ? null : i;
  }

  handleOptionSelected(record: any, actionName: any) {
    console.log(record);
    const recordId = record.id;
    if (actionName === 'edit') {
      this.router.navigate([`edit/${recordId}`], { relativeTo: this.route });
    } else if (actionName === 'view') {
      this.router.navigate([`view/${recordId}`], { relativeTo: this.route });
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.fetchAllRecords();
    }
  }

  getDisplayedPages() {
    const total = this.totalTablePages;
    const current = this.currentTablePage;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push("...");
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }
    console.log(pages)

    return pages;
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.fetchAllRecords();
  }

  calculateTotalPages() {
    if (this.tableListRequest.where) {
      this.totalTablePages = Math.ceil(
        this.tableListData.totalCount / this.rowsPerPage
      );
    }
  }

  updateRowsPerPage(newRowsPerPage: any) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.fetchAllRecords();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.fetchAllRecords();
    }
  }
}
