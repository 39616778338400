import { Component,
  ElementRef,
  Input,
  QueryList,
  ViewContainerRef,
  ViewChild,
  ViewChildren, } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OperationService } from '../../../../services/operations.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ConfirmDialogV2Component } from '../../../confirm-dialog/confirm-dialog-v2.component';
import { FormsModule } from '@angular/forms';
import { LoadingService } from '../../../../services/loading.service';
import { SuggestionDropdownComponent } from '../../../suggestion-dropdown/suggestion-dropdown.component';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { CheckboxComponent } from '../../../common/checkbox/checkbox.component';
import {VehicleType} from "../../../../models/vehicle-type.interface"
import { InventoryType, Lane, getGateInOptions } from '../../../../constants/gate-ops-constants';
import { GateInRequest } from '../../../../models/gate-in-request.interface';
import { ToasterService } from '../../../../services/toaster.service';
import {AutocompleteDropdownComponentValidator} from '../../../../validators/autoCompleteValidator'
import { CycleType } from '../../../../constants/app-constants';
import { DateTimeValidator } from '../../../../validators/dateTimeValidator';
import { convertEpochToISTDateTime, getMaxDateTimeForDatePicker } from '../../../../utils/date-time-utils';
import { DateUtils } from '../../../../utils/date-utils';
import { JobOrderBannerComponent } from '../../../common/job-order-banner/job-order-banner.component';

@Component({
  selector: 'app-truck-gate-in',
  standalone: true,
  imports: [
    SharedModule,
    FormsModule,
    SuggestionDropdownComponent,
    AutocompleteDropdownComponent,
    JobOrderBannerComponent,
    CheckboxComponent
  ],
  templateUrl: './truck-gate-in.component.html',
  styleUrl: './truck-gate-in.component.scss'
})
export class TruckGateInComponent {
  gateInForm: FormGroup;
  ownVehicle: boolean = false;
  isOwnMovement: boolean = false;
  isOwnMovementDisabled: boolean = false;
  selectedTruckNo: string | null = null;
  selectedTruckId: string | null = null;
  laneOptions = Object.values(Lane);
  cycleOptions = Object.values(CycleType);
  maxDateTime: string = '';
  selectedJobOrder: any = null;

  supportedGateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];
  gateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];

  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog
  ) {
    this.gateInForm = this.fb.group({
      cycle: ["IMPORT", Validators.required],
      gateInMode: ['', Validators.required],
      laneNo: ['LANE01', Validators.required],
      ownVehicle: [false],
      ownMovement: [false],
      truckNo: ['', Validators.required],
      transporterId: ['', AutocompleteDropdownComponentValidator()],
      truckTypeId: ['', Validators.required],
      //inspectionType: ['', Validators.required],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required],
      gateInTime: ['', [Validators.required, DateTimeValidator()]],
      jobOrderNo: ['']
    });
  }
  ngOnInit(): void {
    this.loadDropdownData();
    this.updateGateInTime();
  }

  updateGateInTime() {
    this.maxDateTime = getMaxDateTimeForDatePicker();
    this.gateInForm.get("gateInTime")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  loadDropdownData(): void {
      this.supportedGateInOptions = getGateInOptions(InventoryType.TRUCK);
      console.log('Gate In Options:', this.gateInOptions);
      this.gateInForm.get('gateInMode')?.disable();
      this.updateGateInOptions(this.gateInForm.get('cycle')?.value);
  }

  onCycleChange(event: Event): void {
    const selectedCycleLabel = (event.target as HTMLSelectElement).value;
    this.updateGateInOptions(selectedCycleLabel);
  }

  updateGateInOptions(selectedCycle: string) {
    if (!selectedCycle) return;
    this.gateInOptions = this.supportedGateInOptions.filter(option =>
      !option.cycle || option.cycle.key === selectedCycle
    );
    this.gateInForm.get('gateInMode')?.setValue('');
    this.gateInForm.get('gateInMode')?.enable();
  }

  onOwnVehicleChange(ownVehicleChecked: boolean): void {
    this.gateInForm.get('ownVehicle')?.setValue(ownVehicleChecked);
    this.ownVehicle = ownVehicleChecked;
    if (ownVehicleChecked) {
      this.isOwnMovementDisabled = true;
      this.isOwnMovement = true;
      this.gateInForm.get('ownMovement')?.setValue(this.isOwnMovement);
      this.gateInForm.get('ownMovement')?.disable();
      this.selectedTruckId = null;
      this.selectedTruckNo = null;
    } else {
      this.isOwnMovementDisabled = false;
      this.selectedTruckId = null;
      this.gateInForm.get('ownMovement')?.enable();
    }

    this.gateInForm.get('truckNo')?.setValidators([
      Validators.required,
      this.ownVehicle ? AutocompleteDropdownComponentValidator() : Validators.nullValidator
    ]);
    this.gateInForm.get('truckNo')?.updateValueAndValidity();
  }

  onOwnMovementChange(ownMovementChecked: boolean): void {
    this.gateInForm.get('ownMovement')?.setValue(ownMovementChecked);
  }

  resetForm() {
    this.gateInForm.reset({
      ownVehicle: false,
      ownMovement: false,
      cycle: 'IMPORT',
      gateInMode: '',
      laneNo: this.gateInForm.get('laneNo')?.value,
    });
    this.updateGateInOptions("IMPORT");
    this.ownVehicle = false;
    this.isOwnMovement = false;
    this.isOwnMovementDisabled = false;
    this.updateGateInTime();
    this.clearJobOrderSelection();
  }

  saveForm() {
    this.gateInForm.markAllAsTouched();
    if (this.gateInForm.valid) {
      this.openConfirmDialog();
    }
  }

  onTruckSelected(selectedItem: any) {
    this.gateInForm.get('transporterId')?.setValue({key: selectedItem.vendorId, value: selectedItem.vendorName});
    this.gateInForm.get('truckTypeId')?.setValue({ key : selectedItem.vehicleTypeId, value: selectedItem.vehicleTypeValue });
    this.selectedTruckId = selectedItem.id;
    this.selectedTruckNo = selectedItem.trailerNumber;
  }

  onJobOrderSelection(selectedJO: any) {
    this.selectedJobOrder = selectedJO;
    this.selectedJobOrder.issueDate = DateUtils.formatDate(selectedJO.issueDate);
    this.selectedJobOrder.expiryDate = DateUtils.formatDate(selectedJO.expiryDate);
    console.log(this.selectedJobOrder);
  }

  clearJobOrderSelection() {
    this.selectedJobOrder = null;
  }

  openConfirmDialog() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);
    // Set the message in the modal
    confirmDialogRef.instance.title = "Gate-In Confirmation"
    confirmDialogRef.instance.message = "Do you want to confirm and save the changes?";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    // Subscribe to modal confirm action
    confirmDialogRef.instance.confirm.subscribe(() => {
      const gateInRequest = this.constructRequest();
      console.log("GateInRequest => ", gateInRequest);
        this.api.submitGateInRequest(gateInRequest).subscribe((response) => {
          this.toasterService.success("Gate-in was successful");
          this.resetForm();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
      confirmDialogRef.destroy();
    });

    // Subscribe to modal cancel action
    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  constructRequest(): any {
    const formData = this.gateInForm.value;
    const gateInRequest = {
        joNo: formData.jobOrderNo?.value,
        gateInType: formData.gateInMode,
        gateInVehicleType: InventoryType.TRUCK,
        gateInTime: new Date(formData.gateInTime).getTime(),
        laneNo: formData.laneNo,
        vehicle: {
          trailerMasterId: this.selectedTruckId,
          vehicleNo: this.selectedTruckNo,
          vehicleTypeId: formData.truckTypeId?.key,
          transporterId: formData.transporterId.key,
          ownVehicle: formData.ownVehicle,
          ownMovement: formData.ownMovement,
          driverName: formData.driverName,
          driverLicenseNo: formData.driverLicenseNo,
          truck: true
        },
    };
    return gateInRequest;
  }
}
