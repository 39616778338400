<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">User Management</div>
      <!-- <div class="page_desc">View and manage the list of Users</div> -->
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="addNewRecord()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New
      </button>
    </div>
  </div>
</header>

<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by name or email"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>
  </div>
</div>

<div class="tos-table-template">
  <div class="table-stats" *ngIf="false">
    <div class="left">
      <div class="total-users">Total Users: {{ tableStats.total_users }}</div>
      <div class="active-users">
        Active: {{ tableStats.active_users }}
      </div>
      <div class="inactive-users">
        Inactive: {{ tableStats.inactive_users }}
      </div>
    </div>

    <div class="right">
      <div class="bulk_actions">
        <ng-container *ngIf="selectedItems.length > 0 || allTableRecordsSelected">
          <div class="selection_count">
            <div class="count">
              {{
                allTableRecordsSelected
                  ? tableStats.total_users
                  : selectedItems.length
              }}
              selected
            </div>
            <!-- <button
              class="table_select_all_button"
              *ngIf="tableStats.total_users"
              (click)="selectAllTableRecords()"
              [disabled]="allTableRecordsSelected"
            >
              <span *ngIf="!allTableRecordsSelected">
                Select all ({{ tableStats.total_users }}
                users)
              </span>
              <span *ngIf="allTableRecordsSelected">
                Selected all ({{ tableStats.total_users }} users)
              </span>
            </button> -->
          </div>
          <div class="actions">
            <button
              class="tick"
              (click)="bulkActionConfirmation('setActive')"
            >
              <!--                <app-svg-icon [path]="'assets/icons/tick.svg'"></app-svg-icon>-->
              Make Users Active
            </button>
            <button
              class="close"
              (click)="bulkActionConfirmation('setInActive')"
            >
              <!--                <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>-->
              Make Users Inactive
            </button>
            <!-- <button
              class="secondary_button lock"
              (click)="bulkActionConfirmation('unlock')"
            >
              <app-svg-icon [path]="'assets/icons/lock_icon.svg'"></app-svg-icon>
              Unlock
            </button> -->
          </div>
        </ng-container>
      </div>
    </div>

  </div>
  <div class="table-container">
    <ng-container *ngIf="!tableLoaded && tableStats.total_users === 0">
      <app-no-data
        [msg]="
            'No users are available in the system. Please click on Add New User button to create one.'
          "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableStats.total_users > 0 &&
          tableListData.records?.length > 0
        "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                    toggleAllRecordsOfCurrentPageSelections($event.target.checked)
                  "
            />
          </th>
          <!--            <th class="sl_no_th">Sl No.</th>-->
          <th
            class="name_th sortable"
            [ngClass]="{ sorted: sortColumn === 'name' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'name')"
          >
            <div class="icon_cover" (click)="sortData('name')">
              Name
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th class="email_th"
              mwlResizable
              (resizing)="onResizeEnd($event,'email')"
          >Email
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th class="username_th"
              mwlResizable
              (resizing)="onResizeEnd($event,'username')"
          >Username
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th class="phone_th"
              mwlResizable
              (resizing)="onResizeEnd($event,'phone')"
          >Phone
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th
            class="status_th sortable"
            [ngClass]="{ sorted: sortColumn === 'isActive' }"
            (click)="sortData('isActive')"
          >
            <div class="icon_cover">
              Status
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="locked_th sortable"
            [ngClass]="{ sorted: sortColumn === 'isLocked' }"
            (click)="sortData('isLocked')"
          >
            <div class="icon_cover">
              Locked
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="admin_th sortable"
            [ngClass]="{ sorted: sortColumn === 'isSuperAdmin' }"
            (click)="sortData('isSuperAdmin')"
          >
            <div class="icon_cover">
              Admin
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th class="roles_th">Roles</th>
          <th
            class="created_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdDate' }"
            (click)="sortData('createdDate')"
          >
            <div class="icon_cover">
              Created date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th class="table_menu_th">
            <div class="icon_cover plus_icon">
              <app-svg-icon
                [path]="'assets/icons/plus_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'record_sl_no_' + record.slNo"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <!--            <td class="sl_no_td">-->
          <!--              {{ record.slNo }}-->
          <!--            </td>-->
          <td class="name_td" [title]="record.name">
            <span [innerHTML]="record.name | highlight : searchTerm"></span>
          </td>
          <td class="email_td" [title]="record.email">
            <span [innerHTML]="record.email | highlight : searchTerm"></span>
          </td>
          <td class="username_td" [title]="record.username">
            {{ record.username }}
          </td>
          <td class="phone_td">
            {{ record.mobile }}
          </td>
          <td class="status_td">
            <div
              class="icon_cover"
              [ngClass]="record.isActive ? 'active' : 'inactive'"
            >
              <div class="badge_status">
                <app-svg-icon
                  [path]="'assets/icons/circle_icon.svg'"
                ></app-svg-icon>
                {{ record.isActive ? "Active" : "Inactive" }}
              </div>
            </div>
          </td>
          <td class="locked_td">
            <div
              class="icon_cover"
              [ngClass]="record.isLocked ? 'locked' : 'not_locked'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/lock_icon.svg'"
                ></app-svg-icon>
                {{ record.isLocked ? "Yes" : "No" }}
              </div>

            </div>
          </td>
          <td class="admin_td">
            <div
              class="icon_cover"
              [ngClass]="record.isSuperAdmin ? 'admin' : 'not_admin'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/shield_icon.svg'"
                ></app-svg-icon>
                {{ record.isSuperAdmin ? "Yes" : "No" }}
              </div>
            </div>
          </td>
          <td class="roles_td">
            @if (record.roles && record.roles.length > 0) {
              <div class="role_container">
                <div class="first_role">{{ record.roles[0].name }}</div>
                <div class="second_role" *ngIf="record.roles?.[1]?.name">{{ record.roles?.[1]?.name }}</div>
                <div class="third_role" *ngIf="record.roles?.[2]?.name">{{ record.roles?.[2]?.name }}</div>
                <div
                  class="role_count"
                  [appTooltip]="record.roles.slice(3)"
                  [keyToPick]="'name'"
                  position="bottom"
                  *ngIf="record.roles.length > 3"
                >
                  + {{ record.roles.length - 3 }}
                </div>
              </div>
            } @else {

            }
          </td>
          <td class="created_date_td">
            {{ record.createdDate | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableStats.total_users > 0 &&
          tableListData.records?.length === 0
        "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>

  <div class="pagination" [hidden]="tableListData.totalCount === 0">
    <!--        <span class="info">-->
    <!--          Showing-->
    <!--          {{-->
    <!--            currentTablePage === 1-->
    <!--              ? 1-->
    <!--              : currentTablePage * rowsPerPage + 1 - rowsPerPage-->
    <!--          }}-->
    <!--          to-->
    <!--          {{-->
    <!--            currentTablePage * rowsPerPage > tableListData.totalCount-->
    <!--              ? tableListData.totalCount-->
    <!--              : currentTablePage * rowsPerPage-->
    <!--          }}-->
    <!--          of {{ tableListData.totalCount }} users-->
    <!--        </span>-->
    <!--      <div class="buttons">-->
    <!--        <button-->
    <!--          class="previous_btn"-->
    <!--          (click)="previousPage()"-->
    <!--          [disabled]="currentTablePage === 1"-->
    <!--          title="Previous Page"-->
    <!--        >-->
    <!--          <app-svg-icon [path]="'assets/icons/left-arrow.svg'"></app-svg-icon>-->
    <!--        </button>-->

    <!--        &lt;!&ndash; <button *ngIf="getVisiblePages()[0] > 1" disabled>...</button>-->

    <!--        <button-->
    <!--          *ngFor="let page of getVisiblePages()"-->
    <!--          (click)="goToPage(page)"-->
    <!--          [class.active]="currentPage === page"-->
    <!--        >-->
    <!--          {{ page }}-->
    <!--        </button>-->

    <!--        <button-->
    <!--          *ngIf="getVisiblePages().slice(-1)[0] < totalTablePages"-->
    <!--          disabled-->
    <!--        >-->
    <!--          ...-->
    <!--        </button> &ndash;&gt;-->

    <!--        &lt;!&ndash; <div class="buttons_scroll">-->
    <!--          <button-->
    <!--            *ngFor="-->
    <!--              let page of [].constructor(totalTablePages);-->
    <!--              let i = index-->
    <!--            "-->
    <!--            (click)="goToPage(i + 1)"-->
    <!--            [class.active]="currentPage === i + 1"-->
    <!--          >-->
    <!--            {{ i + 1 }}-->
    <!--          </button>-->
    <!--        </div> &ndash;&gt;-->

    <!--        <ng-container *ngIf="totalTablePages">-->
    <!--          <button-->
    <!--            *ngFor="let page of [].constructor(totalTablePages); let i = index"-->
    <!--            (click)="goToPage(i + 1)"-->
    <!--            [class.active]="currentTablePage === i + 1"-->
    <!--          >-->
    <!--            {{ i + 1 }}-->
    <!--          </button>-->
    <!--        </ng-container>-->

    <!--        <button-->
    <!--          class="next_btn"-->
    <!--          (click)="nextPage()"-->
    <!--          [disabled]="currentTablePage === totalTablePages"-->
    <!--          title="Next Page"-->
    <!--        >-->
    <!--          <app-svg-icon [path]="'assets/icons/right-arrow.svg'"></app-svg-icon>-->
    <!--        </button>-->
    <!--      </div>-->

    <div class="button_wrapper_left">
      <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>

    <!--    <span class="info"> Per page:</span>-->
    <!--    <select-->
    <!--      id="rowsPerPage"-->
    <!--      class="row-options"-->
    <!--      #rowOptions-->
    <!--      (change)="updateRowsPerPage(rowOptions.value)"-->
    <!--    >-->
    <!--      <option-->
    <!--        class="option"-->
    <!--        *ngFor="let option of rowsPerPageOptions"-->
    <!--        [value]="option"-->
    <!--      >-->
    <!--        {{ option }}-->
    <!--      </option>-->
    <!--    </select>-->
  </div>
</div>

