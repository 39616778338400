<div class="container">
  @if (dataLoaded) {
    <div class="item-selector-panel">
      <app-item-selector-panel
        #itemSelectorComponent
        [listReadOnly]="true"
        [items]="truckTrailerDetails"
        [entity]="'TRUCK'"
        [itemsTitle]="'Associated Trucks'"
        (onItemSelection)="handleItemSelection($event)"
      ></app-item-selector-panel>
    </div>
    <div class="tally_stats">
      <div class="page_stats">
        <app-stats [stats]="jobOrderStats"
                   [title]="'Job Order Details'"
                   [headerIconUrl]="'assets/icons/job_order_stats_icon.svg'"
                   [headerIconBgColor]="'rgba(245, 243, 255, 1)'"
                   [headerIconColor]="'rgba(159, 26, 177, 1)'"
        ></app-stats>
                <app-stats [stats]="itemStats"
                           [title]="'Item Details'"
                           [headerIconUrl]="'assets/icons/box_icon.svg'"
                           [headerIconBgColor]="'#CFF9FE'"
                           [headerIconColor]="'#124E4F'"
                ></app-stats>
      </div>
    </div>
  }
</div>
