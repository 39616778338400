import { Component, forwardRef, Input, NgModule } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from '../../../modules/shared/shared.module';

@Component({
  selector: 'app-chip-input',
  templateUrl: './chip-input.component.html',
  standalone: true,
  styleUrls: ['./chip-input.component.scss'],
  imports: [SharedModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipInputComponent),
      multi: true,
    },
  ],
})
export class ChipInputComponent implements ControlValueAccessor {
  @Input() placeholder: string = 'Type and press enter';

  value: string[] = []; 
  inputValue: string = '';

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(chips: string[]): void {
    this.value = chips || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onBlur(value: string): void {
    this.addChip(value);
    this.onTouched();
  }

  onKeyDown(event: KeyboardEvent, chipInput: HTMLInputElement): void {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      this.addChip(chipInput.value);
    } else if (event.key === 'Backspace' && !this.inputValue) {
      this.removeChip(this.value.length - 1);
    }
  }

  addChip(value: string): void {
    const trimmedValue = value.trim();
    if (trimmedValue && !this.value.includes(trimmedValue)) {
      this.value.push(trimmedValue);
      this.onChange(this.value);
    }
    this.inputValue = '';
  }

  removeChip(index: number): void {
    if (index >= 0 && index < this.value.length) {
      this.value.splice(index, 1);
      this.onChange(this.value);
    }
  }

  onChipClick(index: number): void {
    console.log(`Chip clicked: ${this.value[index]}`);
  }
}
