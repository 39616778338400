import { Component, ViewChild, ViewChildren, Input, ViewContainerRef, ElementRef, QueryList } from '@angular/core';
import { DynamicFormService } from '../../../../../services/dynamic-form.service';
import { Control, DynamicForm } from '../../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../../dynamic-form/dynamic-form.component';
import { ItemSelectorPanelComponent } from '../../../../item-selector-panel/item-selector-panel.component';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderAndData } from '../../../../../models/table-header-and-data';
import { JobOrderVendorComponent } from '../../vendor-form/job-order-vendor.component';
import { NoDataComponent } from '../../../../no-data/no-data.component';
import { AccordionTableComponent } from '../../../../accordion-table/accordion-table.component';
import { allowedJobOrderAdditionalInfoKeys, JobOrderInventoryInfo, JobOrderItem, JobOrderVendor } from '../../../../../models/job-orders.interface';
import { OperationService } from '../../../../../services/operations.service';
import { LoadingService } from '../../../../../services/loading.service';
import { ToasterService } from '../../../../../services/toaster.service';
import { ConfirmDialogV2Component } from '../../../../confirm-dialog/confirm-dialog-v2.component';
import { DatePipe } from '@angular/common'
import { JobOrderFormActions } from '../host/manage-jo-renderer.component';
import { JobOrderType } from '../../../../../constants/operations-contsants';
import { ApiService } from '../../../../../services/api.service';
import { convertEpochToISTDateTime } from '../../../../../utils/date-time-utils';

@Component({
  selector: 'app-examination-job-order',
  standalone: true,
  imports: [DynamicFormComponent, ItemSelectorPanelComponent, CommonModule, AccordionTableComponent, ConfirmDialogV2Component],
  templateUrl: './examination-job-order.component.html',
  styleUrl: './examination-job-order.component.scss',
  providers:[DatePipe]
})
export class ExaminationJobOrderComponent implements JobOrderFormActions {
  @Input() jobOrderType!: string;
  @Input() jobOrderId!: string;
  @Input() jobOrderConfig!: any;

  jobOrderForm!: FormGroup;
  dynamicForm!: DynamicForm;
  itemDynamicForm!:DynamicForm
  vendorDynamicForm!: DynamicForm;
  containerTableHeaderAndData: any = {};
  containerSearchConfig!: any;
  itemSearchConfig!: any;
  containerList: any = [];
  itemList: any = [];
  selectedItemIndex!: number;
  readOnlyMode:boolean = false;
  editMode: boolean = false;
  vendorMap: Map<string, any[]> =  new Map();
  selectedConsignee: any = {};
  selectedShippingLine: any = {};
  consigneeAddressControl!: any;
  private initialVendorIds: Set<string> = new Set();
  private sectionMap: Map<string, any> = new Map();
  loadedAccordianIndexSet: Set<string> = new Set();
  protected readonly JobOrderVendorComponent = JobOrderVendorComponent;

  selectedTab: string = "basicDetailsSection";

  @ViewChild("basicDetailsSection") basicDetailsSection: ElementRef | undefined;
  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("vendorDetailsSection", { read: ElementRef }) vendorDetailsSection: ElementRef | undefined;
  @ViewChild('itemSelectorComponent', { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @ViewChild('accordianTableComponent', { static: false }) accordianTableComponent?: AccordionTableComponent;

  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  @ViewChild(AccordionTableComponent) accordionTableComponent!: AccordionTableComponent;


  constructor (private dynamicFormService: DynamicFormService,
    private fb: FormBuilder,
    private api: OperationService,
    private commonApiService: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private datePipe: DatePipe
  ) {
    this.jobOrderForm = fb.group({
      itemDetails : this.fb.array([]),
    });
  }

  ngOnInit() {
    console.log(this.jobOrderConfig);
    this.dynamicForm = this.jobOrderConfig?.jobOrderDetailsFormElements;
    this.vendorDynamicForm = this.jobOrderConfig?.vendorFormElements;
    this.itemDynamicForm = this.jobOrderConfig?.itemFormElements;
    this.dynamicFormService.addControlsToForm(this.jobOrderForm, this.dynamicForm);
    this.itemSearchConfig = this.jobOrderConfig?.itemSearchConfig;
    this.containerSearchConfig = this.jobOrderConfig?.containerSearchConfig;
    this.containerTableHeaderAndData.headers = this.jobOrderConfig?.tableHeaders;
    this.consigneeAddressControl = this.itemDynamicForm?.formRows.flatMap(row => row.formGroups).find((formGroup: any) => formGroup?.control?.label === 'consigneeAddress');

    if (this.jobOrderId === "") {
      console.log("Job Order Id not found");
      this.readOnlyMode = false;
      this.editMode = false;
      this.setDefaultValues()
      return;
    }

    if (this.route.snapshot.url[0].path === "edit") {
      this.editMode = true;
      this.readOnlyMode = false;
    } else {
      this.editMode = false;
      this.readOnlyMode = true;
    }

    this.loadingService.show();
    this.loadJobOrderData(this.jobOrderId);
    this.updateFieldConfig(this.jobOrderType);
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  ngAfterViewInit() {
    if (this.basicDetailsSection) this.sectionMap.set("basicDetailsSection", this.basicDetailsSection.nativeElement);
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
  }

  get itemFormArray(): FormArray {
    return this.jobOrderForm.get('itemDetails') as FormArray;
  }

  get containerFormArray(): FormArray {
    return this.jobOrderForm.get('containerDetails') as FormArray;
  }

  handleItemSelection(event: { item: any, index: number }) {
    const formGroup = this.itemFormArray.at(event.index) as FormGroup;
    this.selectedItemIndex = event.index;
    const dynamicFormConfig = {
      formConfig: this.jobOrderConfig?.itemFormElements,
      formGroup: formGroup,
    };
    const componentsToLoad: any = [
      { component: DynamicFormComponent, data: dynamicFormConfig }
    ];
    const componentRefs = this.itemSelectorComponent?.loadComponents(componentsToLoad);
    if (componentRefs && componentRefs.length > 0 && this.consigneeAddressControl) {
      const dynamicFormRef = componentRefs[0];
      if (dynamicFormRef.instance instanceof DynamicFormComponent) {
        dynamicFormRef.instance.onAutoCompleteSuggestionSelected.subscribe((event) => {
          this.handleSuggestionSelected(event);
        });
        dynamicFormRef.instance.onAutoCompleteSearchTextCleared.subscribe((event) => {
          this.handleSearchTextCleared(event);
        });
      }
    }
  }

  handleSuggestionSelected(event: { controlName: string; result: any }) {
    if (event.controlName === 'consigneeId') {
      console.log("Inside consignee select");
      const defaultContactInformation = event?.result?.contactInformation?.find((contact: any) => contact.defaultContact);
      const itemFormGroup = this.itemFormArray.at(this.selectedItemIndex);
      itemFormGroup.get('consigneeAddress')?.enable();
      if (defaultContactInformation) {
        itemFormGroup.patchValue({
          consigneeAddress: defaultContactInformation?.address
        });
      } else {
        itemFormGroup.get('consigneeAddress')?.setValue('');
      }
    }
    this.setConsigneeAddressOptions(event.result);
  }

  handleSearchTextCleared(controlName: string) {
    console.log("Inside handleSearchTextCleared: ", controlName);
    if (controlName === 'consigneeId') {
      const itemFormGroup = this.itemFormArray.at(this.selectedItemIndex);
      itemFormGroup.get('consigneeAddress')?.setValue(null);
      itemFormGroup.get('consigneeAddress')?.disable();
      const control: Control = this.consigneeAddressControl?.control;
      if (control?.fieldInfo?.options) {
        control.fieldInfo.options = [];
    }
    }
  }

  setConsigneeAddressOptions(consigneeData: any) {
    const control: Control = this.consigneeAddressControl?.control;
      if (control?.fieldInfo?.options) {
        control.fieldInfo.options = consigneeData?.contactInformation?.map((contactInfo: any) => ({label: contactInfo?.address, value: contactInfo?.address}));
    }
  }

  validateItemAddition(itemToBeAdded: any) {
    console.log("Inside validate item");
    if (!(!!itemToBeAdded?.associatedContainerList?.length) || itemToBeAdded.associatedContainerList.length < 1) {
      this.toasterService.error("No containers found for the selected item");
      return;
    }
    this.itemSelectorComponent?.addValidatedItem(itemToBeAdded);
  }

  handleItemAddition(event: { item: any, index: number }) {
    this.dynamicFormService.addToFormArray(this.jobOrderForm, "itemDetails", this.jobOrderConfig?.itemFormElements);
    const itemFormGroup = this.itemFormArray.at(event.index) as FormGroup;
    itemFormGroup.patchValue({
      ...event.item,
    });
    if (itemFormGroup?.get('consigneeId')) {
      itemFormGroup.get('consigneeId')?.setValue({ key: event.item?.consigneeId, value: event.item?.consigneeName});
    }
    if (itemFormGroup?.get('shippingLineId')) {
      itemFormGroup.get('shippingLineId')?.setValue({ key: event.item?.shippingLineId, value: event.item?.shippingLineName});
    }
    if (itemFormGroup?.get('commodityId')) {
      itemFormGroup.get('commodityId')?.setValue({ key: event.item?.commodityId, value: event.item?.commodityValue});
    }

    this.jobOrderForm.addControl("containerDetails", this.fb.array([]));
    if (!this.readOnlyMode && !this.editMode) {
      if (event.item?.associatedContainerList?.length) {
        this.containerList = event.item?.associatedContainerList;
        this.containerTableHeaderAndData.data = this.containerList;
        event.item.associatedContainerList.forEach((container: any) => {
          this.containerFormArray.push(this.fb.group({}));
        });
      }
    }
    if (this.consigneeAddressControl && event.item?.consigneeId) {
      this.setConsigneeAddress(event.item?.consigneeId);
    }
    if (this.containerSearchConfig && this.containerSearchConfig?.clientSearchData) {
      this.containerSearchConfig.clientSearchData = [...event.item?.associatedContainerList];
    }
    console.log("Post Item Addition: ", itemFormGroup.value);
  }


  handleItemDeletion(event: {item: any, index: number}) {
    this.itemFormArray.removeAt(event.index);
    this.containerList = [];
    this.containerFormArray.clear();
    this.loadedAccordianIndexSet.clear();
  }

  handleContainerAddition(event: {item: any, index: number}) {
    this.dynamicFormService.addToFormArray(this.jobOrderForm, "containerDetails", this.vendorDynamicForm);
    const containerFormGroup = this.containerFormArray.at(event.index) as FormGroup;
    containerFormGroup.patchValue({
      id: event.item.id,
      ...event.item.additonalProperties
    });
  }

  handleContainerDeletion(event: {rowId: string, rowIndex: number}) {
    this.containerFormArray.removeAt(event.rowIndex);
    this.vendorMap.delete(event.rowId);
    this.loadedAccordianIndexSet.delete(event.rowId);
  }

  loadAccordionContent(event: {rowId: string, rowIndex: number}) {
    const rowId = event.rowId;
    const rowIndex = event.rowIndex;
    if (this.loadedAccordianIndexSet.has(rowId)) return;
    const containerFormGroup = this.containerFormArray.at(rowIndex);
    const containerInFocus = this.containerList[rowIndex];
    if (!this.vendorMap.has(containerInFocus.id)) {
      this.vendorMap.set(containerInFocus.id, []);
    }
    const vendorComponentInput = {
          dynamicForm: this.vendorDynamicForm,
          formGroup: containerFormGroup,
          index: rowIndex,
          itemDetails: containerInFocus,
          vendorList: this.vendorMap.get(containerInFocus.id),
          readOnly: this.readOnlyMode
    };
    this.accordionTableComponent.loadComponent(JobOrderVendorComponent, rowIndex, vendorComponentInput);
    this.loadedAccordianIndexSet.add(rowId);
  }

  cancel() {
    this.confirmConcellation();
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/job-orders");
  }

  saveForm() {
    this.jobOrderForm.markAllAsTouched();

    if (this.jobOrderForm.invalid) {
      this.printFormErrors(this.jobOrderForm);
      return;
    }
    if (this.itemFormArray.length < 1) {
      this.toasterService.error("Please select the item");
      return;
    }
    if (this.containerFormArray.length < 1) {
      this.toasterService.error("No containers selected for the job order");
      return;
    }
    const jobOrderRequest = this.mapJobOrderFormToRequest();
    console.log("Inside Save", jobOrderRequest);
    if (this.editMode) {
        this.api.updateJobOrder(this.jobOrderId, jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    } else {
      this.api.saveJobOrder(jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    }

  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  mapJobOrderFormToRequest(): any {
    const jobOrderFormValue = this.jobOrderForm.value;

    const jobOrderAdditionalInfo = Object.keys(jobOrderFormValue).reduce((acc: any, key) => {
        if (allowedJobOrderAdditionalInfoKeys.includes(key)) {
          acc[key] = jobOrderFormValue[key];
        }
        return acc;
    }, {});

    const associatedItems = this.containerList.map((container: any) => container.id);
    const additionalInventoryProperties = new Map<string, any>();
    this.containerFormArray.controls.forEach((control) => {
      const id = control.get('id')?.value;
      if (id) {
        const { id: _, ...fieldsWithoutId } = control.value;
        additionalInventoryProperties.set(id, fieldsWithoutId);
      }
    });

    const itemList = this.itemFormArray?.value as JobOrderItem[];
    itemList[0]['consigneeId'] = this.itemFormArray.at(0)?.get('consigneeId')?.value.key ?? null;
    itemList[0]['shippingLineId'] = this.itemFormArray.at(0)?.get('shippingLineId')?.value.key ?? null;
    itemList[0]['commodityId'] = this.itemFormArray.at(0)?.get('commodityId')?.value.key ?? null;
    itemList[0]['associatedItems'] = associatedItems;
    itemList[0]['additionalDetails'] = {
      associatedItemProperties: Object.fromEntries(additionalInventoryProperties),
      expectedExamination: itemList[0]?.expectedExamination
    };
    //jobOrderAdditionalInfo.warehouseId = this.jobOrderForm.get('warehouseId')?.value?.key ?? null;
    //jobOrderAdditionalInfo.warehouseName = this.jobOrderForm.get('warehouseId')?.value?.value ?? null;
    jobOrderAdditionalInfo.transporterId = this.jobOrderForm.get('transporterId')?.value?.key ?? null;
    jobOrderAdditionalInfo.transporterName = this.jobOrderForm.get('transporterId')?.value?.value ?? null;


    const isJobOrderAdditionalInfoEmpty = Object.values(jobOrderAdditionalInfo).every(
      (value) => value === undefined || value === null || value === ''
    );

    const jobOrderVendorMap = Object.fromEntries(this.vendorMap);
    const deletedJobOrderVendorIds = this.getDeletedVendorIds();
    console.log(deletedJobOrderVendorIds);
    return {
      jobOrderType: this.jobOrderType,
      expiryDate: new Date(this.jobOrderForm.get('expiryDate')?.value).getTime(),
      jobOrderAdditionalInfo : isJobOrderAdditionalInfoEmpty ? null : jobOrderAdditionalInfo,
      itemList,
      jobOrderVendorMap,
      deletedJobOrderVendorIds
    };
  }

  confirmConcellation() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? "
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }


  loadJobOrderData(jobOrderId: string) {
    let data = null;
    this.loadingService.show();
    this.api.getJobOrderById(jobOrderId, true, true, true, false).subscribe({
      next: (response: any) => {
        data = response?.data;
        this.hydrateForm(data);
        this.loadingService.hide();
      },
      error: (error) => {
        console.error(error);
        this.toasterService.error("Failed to get job order data");
        this.loadingService.hide();
      }
    });
  }

  hydrateForm(jobOrderData: any) {
    if (jobOrderData?.vendorDetails) {
      this.vendorMap = new Map(Object.entries(jobOrderData.vendorDetails));
      this.vendorMap.forEach((vendors) => {
        vendors.forEach((vendor: any) => {
          if (vendor.jovId) {
            this.initialVendorIds.add(vendor.jovId);
          }
        });
      });
    }
    const associatedContainers: Set<string> = new Set(
      jobOrderData.jobOrderInventoryList.find(
        (inventory:any) => inventory.inventoryType === 'CARGO'
      )?.associatedItems
    );

    this.itemList = jobOrderData?.cargoList;
    console.log(this.itemList.length);
    this.itemList.forEach((item: any, index: number) => this.handleItemAddition({item, index}));
    const itemAdditionalDetails = jobOrderData.jobOrderInventoryList[0]?.additionalDetails;
    const itemFormGroup = this.itemFormArray.at(0) as FormGroup;
    itemFormGroup.patchValue({
      ...itemAdditionalDetails
    });


    this.containerList = this.itemList[0]?.associatedContainerList.filter((container: any) => associatedContainers.has(container.id));
    this.containerTableHeaderAndData.data = this.containerList;
    this.accordianTableComponent?.updateAddedItems();
    this.containerList.forEach((container: any, index: number) => {
      container['additonalProperties'] = itemAdditionalDetails?.associatedItemProperties?.[container.id] || {};
      console.log("Container details: ", container);
      this.handleContainerAddition({item: container, index});
    });

    this.jobOrderForm.patchValue({
      ...jobOrderData,
      ...jobOrderData?.additionalDetails,
      expiryDate: convertEpochToISTDateTime(jobOrderData?.expiryDate),
      issueDate: convertEpochToISTDateTime(jobOrderData?.issueDate),
      transporterId: {key: jobOrderData?.additionalDetails?.transporterId, value: jobOrderData?.additionalDetails?.transporterName},
      //warehouseId: {key: jobOrderData?.additionalDetails?.warehouseId, value: jobOrderData?.additionalDetails?.warehouseName}
    });

    if (this.readOnlyMode) {
      this.jobOrderForm.disable();
    }
  }

  getDeletedVendorIds(): string[] {
    const currentVendorIds = new Set<string>();
    this.vendorMap.forEach((vendors) => {
      vendors.forEach((vendor: any) => {
        if (vendor.jovId) {
          currentVendorIds.add(vendor.jovId);
        }
      });
    });

    // Find the IDs that were in the initial set but are no longer in the current set
    const deletedVendorIds: string[] = [];
    this.initialVendorIds.forEach((jovId) => {
      if (!currentVendorIds.has(jovId)) {
        deletedVendorIds.push(jovId);
      }
    });

    return deletedVendorIds;
  }

  setDefaultValues() {
    this.jobOrderForm.get("issueDate")?.setValue(convertEpochToISTDateTime(new Date().getTime()));

    // const today = new Date();
    // const formattedDate = this.datePipe.transform(today, 'yyyy-MM-dd');
  }

  setConsigneeAddress(consigneeId: string) {
    this.commonApiService.getCustomer(consigneeId).subscribe( {
      next: (response: any) => {
        const data = response?.data;
        this.setConsigneeAddressOptions(data);
      },
      error: (error) => {
        console.error(error);
        this.toasterService.error("Failed to get job order data");
        this.loadingService.hide();
      }
    });
  }

  printFormErrors(formGroup: FormGroup | FormArray, path: string = ''): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = path ? `${path}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.printFormErrors(control, controlPath);
      } else if (control && control.invalid) {
        console.log(`Control: ${controlPath}`, control.errors);
      }
    });
  }

  private updateFieldConfig(jobOrderType: string) {
    if (jobOrderType === JobOrderType.DOCK_DELIVERY.key && this.editMode) {
      this.dynamicForm.formRows
        .flatMap(row => row.formGroups)
        .forEach(group => {
          const control = group.control;
          if (control?.label == 'destuffTo') {
            this.jobOrderForm.get("destuffTo")?.disable();
          }
        });
    }
  }
}
