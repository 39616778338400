<div class="modal-backdrop"></div>
<div class="modal">

  <div class="modal-header">
    <div class="modal-sub-header">
      <img *ngIf = "iconUrl" [src]="iconUrl || 'assets/icons/add_user_modal_icon.svg'" alt="modal_icon">
      <div class="modal_title">{{ title }}</div>
    </div>
    <div class="modal-actions">
      <app-svg-icon
        class="close-btn"
        (click)="onClose()"
        [path]="'assets/icons/close_icon.svg'"
      ></app-svg-icon>
    </div>
  </div>
  <div class="modal-body">
    <ng-template #modalContent></ng-template>
  </div>
</div>
