import { Component, Input, Type } from "@angular/core";
import { TallyType } from "../../../../../constants/operations-contsants";
import { NoDataComponent } from "../../../../no-data/no-data.component";
import { NgComponentOutlet } from "@angular/common";

@Component({
  selector: "app-manage-tally-renderer",
  standalone: true,
  imports: [
    NgComponentOutlet,
    NoDataComponent
  ],
  templateUrl: "./manage-tally-renderer.component.html",
  styleUrl: "./manage-tally-renderer.component.scss"
})
export class ManageTallyRendererComponent {
  @Input() tallyType!: TallyType;
  @Input() jobOrderInventoryDetails: any;
  @Input() jobOrderStats: any;

  resolveComponent(): Type<any> {
    const tallyConfig: any = this.tallyType

    if (!tallyConfig) {
      return NoDataComponent;
    }

    if ("getComponent" in tallyConfig) {
      return tallyConfig.getComponent(this.jobOrderInventoryDetails);
    }

    return tallyConfig.component;
  }
}
