import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  standalone: true,
  imports: [],
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent {
  @Input() imageSrc: string = '';
  @Input() altText: string = 'Image';
  @Input() imageWidth: string = 'auto';
  @Input() imageHeight: string = 'auto';
  @Input() enableDownload: boolean = true;

  onImageClick() {
    console.log('Image clicked');
  }

  downloadImage() {
    const urlParts = this.imageSrc.split('/');
    const filename = urlParts[urlParts.length - 1] || 'downloaded_image.jpg';
  
    const link = document.createElement('a');
    link.href = this.imageSrc;
    link.download = filename;
    link.click();
  }
}
