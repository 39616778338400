import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Menu } from "../../../models/sidebar-menu.interface";
import { Router, RouterLink } from "@angular/router";
import { SharedModule } from "../../../modules/shared/shared.module";

@Component({
  selector: 'app-sidebar-new-expanded',
  standalone: true,
  imports: [
    RouterLink,
    SharedModule
  ],
  templateUrl: './sidebar-new-expanded.component.html',
  styleUrl: './sidebar-new-expanded.component.scss'
})
export class SidebarNewExpandedComponent {
  constructor(private router: Router) {
  }

  @Input() selectedMenu!: Menu;
  @Input() selectingMenu!: Menu;
  @Input() childMenu!: Menu;

  groupedChildResources: { category: string; items: Menu[] }[] = [];
  itemsWithOutCategory: Menu[] = [];


  ngOnInit(){
    const menu = this.selectingMenu || this.selectedMenu;
    if (menu?.childResources) {
      this.groupChildResources(menu.childResources);
      this.separateNoCategoryItems();
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectingMenu'] || changes['selectedMenu']) {
      this.updateChildResources();
    }
  }

  updateChildResources() {
    const menu = this.selectingMenu || this.selectedMenu;
    this.groupedChildResources = [];
    this.itemsWithOutCategory = [];
    if (menu?.childResources) {
      this.groupChildResources(menu.childResources);
      this.separateNoCategoryItems();
    }
  }

  isActive(id: string) {
    return this.childMenu?.id === id;
  }

  groupChildResources(childResources: Menu[]): void {
    const grouped = childResources.reduce((acc, child) => {
      const category = child.resourceProperties?.category || "noCategory";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(child);
      return acc;
    }, {} as Record<string, Menu[]>);

    this.groupedChildResources = Object.entries(grouped).map(([category, items]) => ({
      category: category,
      items
    }));

  }

  separateNoCategoryItems(): void {
    const noCategoryGroup = this.groupedChildResources.find(group => group.category === 'noCategory');
    this.itemsWithOutCategory = noCategoryGroup ? noCategoryGroup.items : [];
    
    // Remove 'noCategory' from groupedChildResources for the second-level grouping
    this.groupedChildResources = this.groupedChildResources.filter(group => group.category !== 'noCategory');
  }

}
