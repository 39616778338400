<div class="image-gallery">
    <img
      [src]="imageSrc"
      [alt]="altText"
      class="image"
      [style.width]="imageWidth"
      [style.height]="imageHeight"
      loading="lazy"
      (click)="onImageClick()"
    />
    <div class="action-buttons" *ngIf="enableDownload">
      <button (click)="downloadImage()">Download</button>
    </div>
</div>