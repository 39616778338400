import { Component, ElementRef, EventEmitter, input, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AutocompleteDropdownComponent } from "../../autocomplete-dropdown/autocomplete-dropdown.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditableTableHeader } from "../../../models/editable-table-header.interface";
import { ToggleComponent } from "../../common/toggle/toggle.component";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-editable-table-dynamic-input',
  standalone: true,
  imports: [
    AutocompleteDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    ToggleComponent
  ],
  templateUrl: './editable-table-dynamic-input.component.html',
  styleUrl: './editable-table-dynamic-input.component.scss'
})
export class EditableTableDynamicInputComponent {
  @Input() header!: any;
  @Input() value: string = '' ;
  @Input() row!: number;
  @Output() updateValue = new EventEmitter<Event>();
  @Input() disabled: boolean = false;
  @Input() max: number = 0;
  @ViewChild(AutocompleteDropdownComponent) autocompleteDropdown!: AutocompleteDropdownComponent;

  private inputSubject: Subject<Event> = new Subject<Event>();

  ngOnInit(){
    if(this.header.fieldInfo?.dataType === 'date'){
      this.value = this.value?.split('T')[0];
    }

    this.inputSubject.pipe(
      debounceTime(300)
    ).subscribe(event => {
      this.updateValue.emit(event);
    });
  }

  updateValueEmitter(event: Event) {
    this.inputSubject.next(event);
  }
}
