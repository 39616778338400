{
  "formName": "dynamicForm",
  "formRows": [
        {
          "style": {
            "gridRow": "span 10"
          },
          "formGroups": [
            {
              "style": {
                "gridColumn": "span 2"
              },
              "condition": "true",
              "control": {
                "label": "sealCuttingDate",
                "displayLabel": "Seal Cutting Date",
                "disabled": false,
                "placedHolder": "Enter Seal Cutting Date",
                "validators": [
                  {
                    "type": "required",
                    "message": "Seal Cutting Date is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "date"
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 2"
              },
              "control": {
                "label": "test",
                "displayLabel": "Test",
                "disabled": false,
                "placeHolder": "Enter Test",
                "validators": [
                  {
                    "type": "required",
                    "message": "Test is required"
                  },
                  {
                    "type": "autocomplete",
                    "message": "Custom Validator"
                  }
                ],
                "fieldInfo": {
                  "dataType": "text"
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 2"
              },
              "control": {
                "label": "name",
                "displayLabel": "Name",
                "placeHolder": "Enter Name",
                "disabled": true,
                "validators": [
                  {
                    "type": "required",
                    "message": "Name is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "text"
                }
              }
            }
          ]
        },
        {
          "formGroups": [
            {
              "style": {
                "gridColumn": "span 2"
              },
              "control": {
                "label": "smtp",
                "displayLabel": "SMTP",
                "defaultValue": true,
                "disabled": false,
                "fieldInfo": {
                  "dataType": "boolean"
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 4"
              },
              "control": {
                "label": "description",
                "displayLabel": "Description",
                "disabled": false,
                "placeHolder": "Enter Description",
                "validators": [],
                "fieldInfo": {
                  "dataType": "textarea"
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 2"
              },
              "control": {
                "label": "testMultiSelect",
                "displayLabel": "Multi Selact",
                "disabled": false,
                "placeHolder": "Select something",
                "fieldInfo": {
                  "dataType": "multi-select",
                  "displayKey": "label",
                  "valueKey": "value",
                  "options": [
                      {
                          "label": "Option1",
                          "value": "TRUCK1"
                      },
                      {
                          "label": "Option2",
                          "value": "TRUCK2"
                      },
                      {
                          "label": "Option3",
                          "value": "TRUCK3"
                      }
                  ]
                },
                "validators": [
                  {
                    "type": "selectRequired",
                    "message": "Items selection is required"
                  }
                ]
              }
            },
            {
              "style": {
                "gridColumn": "span 2"
              },
              "control": {
                "label": "chip-input-test",
                "displayLabel": "Chip Input",
                "disabled": false,
                "placeHolder": "Type and hit Enter",
                "fieldInfo": {
                  "dataType": "chip-input"
                },
                "validators": [
                  {
                    "type": "required",
                    "message": "Expiry Date is required"
                  }
                ]
              }
            }
          ]
        },
        {
          "formGroups": [
            {
              "style": {
                "gridColumn": "span 3"
              },
              "control": {
                "label": "address",
                "displayLabel": "Address",
                "disabled": false,
                "placeHolder": "Enter Address",
                "validators": [
                  {
                    "type": "required",
                    "message": "Address is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "text"
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 3"
              },
              "control": {
                "label": "city",
                "displayLabel": "City",
                "placeHolder": "Enter City",
                "disabled": false,
                "validators": [
                  {
                    "type": "required",
                    "message": "City is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "select",
                  "options": [
                    {
                      "label": "Bangalore",
                      "value": "BLR"
                    }
                  ]
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 3"
              },
              "control": {
                "label": "state",
                "displayLabel": "State",
                "disabled": false,
                "placeHolder": "Enter State",
                "validators": [
                  {
                    "type": "required",
                    "message": "State is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "picklist",
                  "lookupDetails": {
                    "apiUrl": "v1/locations/list",
                    "selectFields": ["id", "name", "panNumber"],
                    "whereFields": ["name", "panNumber"],
                    "limit": 10,
                    "orderBy": { "name": "asc" },
                    "filterByKey": "name",
                    "bindLabel": "name",
                    "bindValue": "id",
                    "responseBindValue": "toLocationId",
                    "responseBindLabel": "toLocationName",
                    "displayColumns": [
                      { "key": "name", "header": "Name" },
                      { "key": "panNumber", "header": "PAN Number" }
                    ]
                  }
                }
              }
            },
            {
              "style": {
                "gridColumn": "span 3"
              },
              "control": {
                "label": "zip",
                "displayLabel": "Zip",
                "disabled": false,
                "placeHolder": "Enter ZIP",
                "validators": [
                  {
                    "type": "required",
                    "message": "Zip is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "number"
                }
              }
            }
          ]
        },
        {
          "formGroups": [
            {
              "style": {
                "gridColumn": "span 8"
              },
              "control": {
                "label": "email",
                "displayLabel": "Email",
                "disabled": false,
                "placeHolder": "Enter Email",
                "validators": [
                  {
                    "type": "email",
                    "message": "Email is invalid"
                  },
                  {
                    "type": "required",
                    "message": "Email is required"
                  }
                ],
                "fieldInfo": {
                  "dataType": "email"
                }
              }
            }
          ]
        }
      ]
}
