<div class="container">

  <div class="page_header">
    @if (readOnlyMode) {
      <app-back-button></app-back-button>
    }
    <div class="left">
        <div class="title">
          <span>
            @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
            {Create New} Service
          </span>
        </div>
    </div>
    <div class="right">
        @if (!readOnlyMode) {
            <button class="btn secondary_button" (click)="goBack()">Cancel</button>
            <button class="btn primary_button" (click)="submitMasterForm()">Save</button>
        }
    </div>
  </div>

  <div class="master-content">
    <div class="form-container">
      <app-dynamic-form [formGroup]="serviceMasterForm" [formConfig]="dynamicFormConfig"></app-dynamic-form>
    </div>

    <div class="verticle-divider"></div>

    <div class="equipment-container">

        <div class="section-title">
          @if (readOnlyMode && equipmentList.length > 0) {
            <span>Selected Equipment(s)</span>
          } @else if (readOnlyMode) {
            <span>No Equipment(s) selected</span>
          } @else {
            <span>Add Equipment(s)</span>
          }
        </div>
        <div *ngIf="!readOnlyMode" class="search-container">
            <app-autocomplete-dropdown
              id="search-bar"
              class="autocomplete-dropdown"
              [placeholder]="'Search Equipment'"
              [apiUrl]="'v1/equipments/list'"
              [selectFields]="['id', 'name', 'active']"
              [whereFields]="['name']"
              [limit]="10"
              [orderBy]="{'name': 'asc'}"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[{
                key: 'name', header: 'Equipment Name' ,
              }]"
              [additionalFilters]="[
                {
                  'name': 'active',
                  'alias': 'active',
                  'operator': 'EQ',
                  'value': ['true']
                }
              ]"
              [(ngModel)]="equipmentSearchText"
              (suggestionSelected)="onEquipmentSelected($event)"
          >
          </app-autocomplete-dropdown>
          <button type="button" class="add-button secondary_button" [disabled]="disableAddButton" (click)="addSelectedEquipment()">
              <app-svg-icon class="image-icon" [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
              <span>Add</span>
          </button>
        </div>

      <div class="equipment-table-container" *ngIf="equipmentList.length > 0">
        <table class="table-view-data">
          <thead>
            <tr>
              <th><span>Sl No</span></th>
              <th *ngFor="let column of tableHeaders">{{ column.displayName }}</th>
              <th>Default</th>
              <th class="delete_column" *ngIf = "!readOnlyMode"><span></span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of equipmentList; let i = index">
              <td><span> {{ i+1 }} </span></td>
              <td *ngFor="let column of tableHeaders" [ngClass]="{ primary_column: column.fieldName === 'name' }">
                @if (column.fieldName === 'active') {
                  <span> {{ result[column.fieldName] ? 'Y' : 'N' }} </span>
                } @else {
                  <span> {{ result[column.fieldName] }} </span>
                }
              </td>
              <td class="checkbox_column">
                <input type="checkbox" (click)="selectAsDefault(i)" [readOnly]="readOnlyMode"  [checked]="result.isDefault ?? false" >
              </td>
              <td *ngIf = "!readOnlyMode" class="delete_column">
                <app-svg-icon class="delete-icon"
                      [path]="'assets/icons/delete_icon.svg'"
                      (click)="removeEquipment(i)"
                  ></app-svg-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
