<div class="chip-input-container">
  <input
    #chipInput
    type="text"
    [placeholder]="placeholder"
    (keydown)="onKeyDown($event, chipInput)"
    (blur)="onBlur(chipInput.value)"
    [(ngModel)]="inputValue"
  />
  <div class="chip-list" *ngIf = "value.length > 0">
    <span class="chip" *ngFor="let chip of value; let i = index" (click)="onChipClick(i)">
      {{ chip }}
      <span class="remove-chip" (click)="removeChip(i)">&times;</span>
    </span>
  </div>
</div>