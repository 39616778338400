import { Component, ViewContainerRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RightPanelComponent } from "../../right-panel/right-panel.component";
import { ModalComponent } from "../../modal/modal.component";
import { AdvancedFilterComponent } from "../../advanced-filter/advanced-filter.component";
import { TableListRequest } from "../../../models/table-list-request.interface";
import { FileUploadComponent } from "../../file-upload/file-upload.component";
import { ManageEquipmentComponent } from './manage-equipment/manage-equipment.component';
import { EditableTableComponent } from "../../editable-table/editable-table.component";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../../pipes/highlight.pipe";
import { NoDataComponent } from "../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../../table-menu/table-menu.component";
import { PaginatedListDataResponse } from "../../../models/paginated-list-data-response";
import { LoadingService } from "../../../services/loading.service";
import { User } from "../../../models/user.interface";
import { MasterService } from "../../../services/masters-service";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { ToasterService } from "../../../services/toaster.service";

@Component({
  selector: 'app-equipment-master',
  standalone: true,
  imports: [FormsModule,
    EditableTableComponent,
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    NgClass],
  templateUrl: './equipment-master.component.html',
  styleUrl: './equipment-master.component.scss'
})
export class EquipmentMasterComponent {
filterFields = [
    {
      displayName: 'Number',
      key: 'name',
    }
  ];
  isFilterApplied: boolean = false;
  searchTerm: any;
  focusSearch!: boolean;
  tableLoaded: boolean = false;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  sortColumn: string = "modifiedDate";
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
  ];
  totalTablePages: number = 0;
  currentTablePage: number = 1;
  sortDirection: "asc" | "desc" = "desc";
  rowsPerPage: number = 25;
  currentPage: number = 0;
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "name",
      "description",
      "active",
      "modifiedDate",
      "modifiedBy"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    limit: this.rowsPerPage,
    page: this.currentPage
  };
  allTableRecordsSelected: boolean = false;
  selectedItems: any[] = [];
  previousTerm: string = "";

  constructor(private viewContainerRef: ViewContainerRef,
              private loadingService: LoadingService,
              private masterService: MasterService,
              private toasterService: ToasterService) {
  }

  ngOnInit(){
    this.fetchAllRecords()
  }

  fetchAllRecords() {
    this.loadingService.show();
    this.masterService.getEquipmentList(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
        this.calculateTotalPages();
        this.tableLoaded = true;
        this.loadingService.hide()

      },
      error: (error: Error) => {
        console.error("Error occurred:", error);
        this.tableLoaded = false;
        this.loadingService.hide()
      }
    });
  }

  addEquipment() {
    const rightPanelRef = this.viewContainerRef.createComponent(RightPanelComponent);
    rightPanelRef.instance.title = "Add Equipment";
    const manageEquipmentRef = rightPanelRef.instance.loadComponent(ManageEquipmentComponent);
    manageEquipmentRef.instance.onSuccessfulSave.subscribe(() => {
      this.fetchAllRecords();
      rightPanelRef.destroy();
    });
    rightPanelRef.instance.close.subscribe((res: any) => {
      if (res && res.refresh) {
        this.refreshTableList();
      }
      rightPanelRef.destroy();
    });
  }

  editEquipment(equipmentId: String) {
    const rightPanelRef = this.viewContainerRef.createComponent(RightPanelComponent);
    rightPanelRef.instance.title = "Edit Equipment";
    const manageEquipmentRef = rightPanelRef.instance.loadComponent(ManageEquipmentComponent);
    manageEquipmentRef.instance.onSuccessfulSave.subscribe(() => {
      this.fetchAllRecords();
      rightPanelRef.destroy();
    });
    manageEquipmentRef.setInput("equipmentId", equipmentId);
    rightPanelRef.instance.close.subscribe((res: any) => {
      if (res && res.refresh) {
        this.refreshTableList();
      }
      rightPanelRef.destroy();
    });
  }

  filter() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = "Advanced Filter";
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput("filterFields", this.filterFields);
    if (this.tableListRequest.where) {
      filterDialogRef.setInput("appliedFilters", this.tableListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.refreshTableList();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression
          };
          this.isFilterApplied = true;
        } else {
          console.error("Invalid filter !");
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.refreshTableList();
      }
      modalRef.destroy();
    });
  }

  private clearSelection() {
    this.selectedItems = [];
  }

  private refreshTableList() {
    this.fetchAllRecords()
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length >= 3) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: "name",
            alias: "A",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "A"
      };
      // Search when Enter is pressed
      if (event.key === 'Enter') {
        this.fetchAllRecords();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.fetchAllRecords();
    }
    this.previousTerm = searchTerm;
  }

  isAllRecordsOfCurrentPageSelected() {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: User) =>
          this.isSelected(item)
        ))
    );

  }

  toggleAllRecordsOfCurrentPageSelections(checked: any) {
    if (checked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }
  }

  onResizeEnd(event: ResizeEvent, column: string) {
    if (event.edges.right) {
      const columnHeader = document.querySelector(`th.${column}_th`) as HTMLElement;
      const columnCells = document.querySelectorAll(`td.${column}_td`);
      const newWidth = `${event.rectangle.width}px`;

      if (columnHeader) {
        columnHeader.style.width = newWidth;
      }

      columnCells.forEach(cell => {
        const cellHtml = cell as HTMLElement;
        cellHtml.style.width = newWidth;
      });
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortColumn = column;
      this.sortDirection = "asc";
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection
    };
    this.fetchAllRecords()
  }

  isSelected(item: any) {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }
  }

  handleMenuToggle(i: number) {
    this.openMenuIndex = this.openMenuIndex === i ? null : i;
  }

  handleOptionSelected(record: any, actionName: any) {
    const recordId = record.id;
    if (actionName === 'edit') {
      this.editEquipment(recordId);
    } else {
        let actionTitle = 'Confirmation';
        let message = 'Are you sure ?';
        let successMsg = 'Done !';
        let flag = false;
        switch (actionName) {
          case 'active':
          case 'inactive':
            if (actionName === 'active') {
              flag = true;
            }
            actionTitle = `${flag ? 'Activate' : 'Inactivate'} Equipment`;
            message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${record.name
            } ?`;
            successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${record.name
            } !`;
            break;
  
          default:
            console.error('unknown actionName');
            break;
        }
        const modalRef = this.viewContainerRef.createComponent(ModalComponent);
        modalRef.instance.title = actionTitle;
        const confirmDialogRef = modalRef.instance.loadComponent(
          ConfirmDialogComponent
        );
        confirmDialogRef.instance.message = message;
        confirmDialogRef.instance.confirm.subscribe(() => {
          this.menuAction(actionName, successMsg, flag, recordId!);
          modalRef.destroy();
        });
        confirmDialogRef.instance.cancel.subscribe(() => {
          modalRef.destroy();
        });
        modalRef.instance.close.subscribe(() => modalRef.destroy());
      }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.masterService.setEquipmentStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableList();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.fetchAllRecords();
    }
  }

  getDisplayedPages() {
    const total = this.totalTablePages;
    const current = this.currentTablePage;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push("...");
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }
    console.log(pages)

    return pages;
  }

  goToPage(page: any) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.fetchAllRecords();
  }

  calculateTotalPages() {
      this.totalTablePages = Math.ceil(
        (this.tableListData?.totalCount || 50) / this.rowsPerPage
      );
  }

  updateRowsPerPage(newRowsPerPage: any) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.fetchAllRecords();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.fetchAllRecords();
    }
  }
}
