import { Component, Input } from '@angular/core';
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";

@Component({
  selector: 'app-job-order-banner',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './job-order-banner.component.html',
  styleUrl: './job-order-banner.component.scss'
})
export class JobOrderBannerComponent {

  @Input() issueDate: any;
  @Input() expiryDate: any;

}
