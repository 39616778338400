[
  {
    "label": "containerNo",
    "displayLabel": "Container",
    "editable": false,
    "isPinned": true
  },
  {
    "label": "hazardous",
    "displayLabel": "hazardous",
    "editable": true,
    "fieldInfo": {
      "dataType": "boolean"
    }
  },
  {
    "label": "imdgClass",
    "displayLabel": "IMDG Class",
    "placeholder": "Enter IMDG Class",
    "editable": true,
    "fieldInfo": {
      "dataType": "text"
    }
  },
  {
    "label": "unNo",
    "displayLabel": "UN No",
    "placeholder": "Enter UN No",
    "editable": true,
    "fieldInfo": {
      "dataType": "text"
    }
  },
  {
    "label": "deliveryMode",
    "displayLabel": "Delivery Mode",
    "placeholder": "Enter Delivery Mode",
    "editable": true,
    "fieldInfo": {
      "dataType": "select",
      "options": [
        {
          "value": "FACTORY",
          "label": "Factory Delivery"
        },
        {
          "value": "DOCK",
          "label": "Dock Delivery"
        }
      ]
    }
  },
  {
    "label": "deliveryModeFromDO",
    "displayLabel": "Delivery Mode From DO",
    "placeholder": "Enter Delivery Mode From DO",
    "editable": true,
    "fieldInfo": {
      "dataType": "select",
      "options": [
        {
          "value": "FACTORY",
          "label": "Factory Delivery"
        },
        {
          "value": "DOCK",
          "label": "Dock Delivery"
        }
      ]
    }
  },
  {
    "label": "doNo",
    "displayLabel": "Delivery Order No",
    "placeholder": "Enter Delivery Order No",
    "editable": true,
    "fieldInfo": {
      "dataType": "text"
    }
  },
  {
    "label": "doDate",
    "displayLabel": "DO Date",
    "placeholder": "Enter Delivery Order Date",
    "editable": true,
    "fieldInfo": {
      "dataType": "date"
    }
  },
  {
    "label": "doValidityDate",
    "displayLabel": "DO Validity Date",
    "editable": true,
    "fieldInfo": {
      "dataType": "date"
    }
  },
  {
    "label": "toLocationId",
    "displayLabel": "Empty Drop Yard",
    "placeholder": "Enter Empty Drop Yard",
    "id": "toLocationId",
    "hasLookup": true,
    "fieldInfo": {
      "dataType": "picklist",
      "lookupDetails": {
        "apiUrl": "v1/locations/list",
        "selectFields": ["id", "name", "panNumber"],
        "whereFields": ["name", "panNumber"],
        "limit": 10,
        "orderBy": { "name": "asc" },
        "filterByKey": "name",
        "bindLabel": "name",
        "bindValue": "id",
        "responseBindValue": "toLocationId",
        "responseBindLabel": "toLocationName",
        "displayColumns": [
          { "key": "name", "header": "Name" },
          { "key": "panNumber", "header": "PAN Number" }
        ]
      }
    },
    "editable": true
  },
  {
    "label": "containerSize",
    "displayLabel": "Size",
    "editable": false
  },
  {
    "label": "containerType",
    "displayLabel": "Type",
    "editable": false
  },
  {
    "label": "importType",
    "displayLabel": "FCL / LCL",
    "editable": false
  },
  {
    "label": "arrivalDate",
    "displayLabel": "Arrival Date",
    "editable": false
  },
  {
    "label": "totalPackages",
    "displayLabel": "NoP",
    "editable": false
  },
  {
    "label": "cargoWeight",
    "displayLabel": "Cargo wt",
    "editable": false
  },
  {
    "label": "tareWeight",
    "displayLabel": "Tare wt",
    "editable": false
  },
  {
    "label": "grossWeight",
    "displayLabel": "Gross wt",
    "editable": false
  },
  {
    "label": "weightmentWt",
    "displayLabel": "Weightment wt",
    "editable": false
  },
  {
    "label": "volume",
    "displayLabel": "Volume",
    "editable": false
  },
  {
    "label": "scanType",
    "displayLabel": "Scan Y/N",
    "editable": false
  },
  {
    "label": "scanStatus",
    "displayLabel": "Scan Status",
    "editable": false
  },
  {
    "label": "remarks",
    "displayLabel": "Remarks",
    "editable": false
  }
]
