import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { AutocompleteDropdownComponent } from "../../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { StatsComponent } from "../../../../stats/stats.component";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { Stats } from "../../../../../models/stats.interface";
import ExaminationTallyFormConfig from "../config/ExaminationTallyFormConfig.json";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import { Container } from "../../../../../models/container.interface";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import { JobOrderVendor, JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";
import { ManageTally } from "../manage-tally.interface";
import { ConfirmDialogV2Component } from "../../../../confirm-dialog/confirm-dialog-v2.component";
import { Tally } from "../../../../../models/tally.interface";
import { getTallyTypeByJobOrderType, TallyType } from "../../../../../constants/operations-contsants";
import { InventoryType } from "../../../../../constants/gate-ops-constants";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { NgClass } from "@angular/common";
import { ToasterService } from "../../../../../services/toaster.service";
import { StatsService } from "../../../../../services/stats.service";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { LocalStorageService } from "../../../../../services/local-storage.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-manage-examination-tally",
  standalone: true,
  imports: [
    AutocompleteDropdownComponent,
    ItemSelectorPanelComponent,
    StatsComponent,
    NgClass
  ],
  templateUrl: "./manage-examination-tally.component.html",
  styleUrl: "./manage-examination-tally.component.scss"
})
export class ManageExaminationTallyComponent implements ManageTally {
  cargoItem!: CargoItem;
  containerList: Array<Container> | undefined;
  dataLoaded: boolean = false;
  examinationTallyForm!: FormGroup;
  examinationTallyFormJson!: DynamicForm;
  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;
  tallyData!: Tally;
  examinationTallyId: String = "";
  readOnlyMode: boolean = false;
  editingEnabled: boolean = false;
  addNewMode: boolean = false;
  examinationTallyDetails: any;
  inventoryDetailStats: Stats[] = [];
  isSidebarPinned: boolean = false;
  isSidebarExpanded: boolean = false;
  selectedItems: string[] = [];
  savedTallyData: any[] = [];

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;
  @Input() jobOrderStats!: Stats[];

  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;


  constructor(private router: Router,
              private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private viewContainerRef: ViewContainerRef,
              private route: ActivatedRoute,
              private toasterService: ToasterService,
              private statsService: StatsService,
              private localStorageService: LocalStorageService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.examinationTallyFormJson = ExaminationTallyFormConfig.examinationTallyFormElements;
    this.setAppMode(this.route.snapshot?.url[0]?.path);

    if (this.addNewMode) { //return from here in add new mode
      return;
    }

    //only for edit/read mode
    this.examinationTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
    this.examinationTallyId = this.route.snapshot.paramMap.get("tally-id") || "";
    if (this.examinationTallyId === "") {
      console.log("Item Filing Id not found");
      this.readOnlyMode = false;
      this.editingEnabled = false;
      this.addNewMode = true;
      return;
    }
    this.getExaminationTallyDetails(this.examinationTallyId);
    if (this.readOnlyMode)
      this.examinationTallyForm.disable();
  }

//only user in add case when search field passes the job order details
  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {
      this.loadingService.show();
      this.examinationTallyForm = this.fb.group({
        tallyDetails: this.fb.array([])
      });
      this.getJobOrderDetailsForAddNewMode(this.jobOrderInventoryDetails);
    }
  }

  get itemFormArray() {
    return this.examinationTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: Container, index: number }) {
    console.log(event.item.id);
    this.changeDetectorRef.detectChanges();
    this.addTallyDetailsFromArrayForAssociatedContainers(event.item);
    const formGroup = this.itemFormArray.at(event.index) as FormGroup;
    this.patchSavedTallyData(event, formGroup);

    formGroup.get("inventoryId")?.setValue(event.item.id);
    formGroup.get("inventoryNo")?.setValue(event.item.containerNo);
    formGroup.get("cargoId")?.setValue(this.jobOrderInventoryDetails.inventoryId);
    formGroup.get("documentNo")?.setValue(this.jobOrderInventoryDetails.inventoryNo);

    if (!this.addNewMode) {
      this.examinationTallyDetails = {
        ...this.examinationTallyDetails,
        startTime: this.formatDateTimeForInput(this.examinationTallyDetails.startTime),
        endTime: this.formatDateTimeForInput(this.examinationTallyDetails.endTime)
      };
      formGroup.patchValue(this.examinationTallyDetails);
      if (this.readOnlyMode) formGroup.disable();
    }

    const componentsToLoad: any = [
      {
        component: StatsHorizontalComponent,
        data: { stats: this.statsService.createContainerStatsForTally(event.item) }
      },
      { component: DynamicFormComponent, data: { formConfig: this.examinationTallyFormJson, formGroup } }
    ];

    if (this.jobOrderVendorsGroupByInventoryId[event.item?.id]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.id] }
      });
    }
    this.itemSelectorComponent?.loadComponents(componentsToLoad);

  }

  private patchSavedTallyData(event: { item: Container; index: number }, formGroup: FormGroup<any>) {
    if (this.savedTallyData?.length === 0) return;

    const filteredTallyData = this.savedTallyData.find((tally: { inventoryId: string }) => tally.inventoryId === event.item.id);
    if (filteredTallyData?.length === 0) return;

    formGroup.patchValue({
      ...filteredTallyData,
      startTime: this.formatDateTimeForInput(filteredTallyData.startTime),
      endTime: this.formatDateTimeForInput(filteredTallyData.endTime)
    });
    formGroup.disable();

  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  cancel(): void {
    if (this.readOnlyMode)
      this.goBack();

    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? ";
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  save(): void {
    if (!this.filterAndValidateTallyForm()) return;
    console.log(this.examinationTallyForm.value);
    this.tallyData = {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      tallyType: !this.editingEnabled ? getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType)?.code : this.examinationTallyDetails.tallyType,
      tallyItems: this.examinationTallyForm.value.tallyDetails
        .filter((detail: any) => {
          return this.selectedItems.includes(detail.inventoryId);
        })
        .map((detail: any) => ({
          ...detail,
          // startTime: new Date(detail.startTime).getTime(),
          // endTime: new Date(detail.endTime).getTime(),
          type: "examination_tally",
          inventoryType: InventoryType.CONTAINER
        }))
    };
    console.log(this.tallyData);
    this.saveTally();
  }

  handleCheckBoxSelection(event: any) {
    this.selectedItems = Array.from(event);
  }

  private filterAndValidateTallyForm(): boolean {
    const tallyDetailsFormGroup = this.examinationTallyForm.get("tallyDetails") as FormArray;
    if (tallyDetailsFormGroup.length === 0 || this.selectedItems.length === 0) {
      this.toasterService.error("Please select at least one item to save the tally");
      return false;
    }

    this.examinationTallyForm.markAllAsTouched();
    const invalidSelectedItems = this.itemFormArray.controls
      .filter((control) => this.selectedItems.includes(control.get("inventoryId")?.value))
      .some((control) => control.invalid);

    if (invalidSelectedItems) {
      //TODO: logic to go through all selectedItems and show the error notifications on the left panel
      this.toasterService.error("Please fill all required fields to save the tally");
      console.log("Form is invalid", this.examinationTallyForm);
      return false;
    }

    return true;
  }

  private saveTally() {
    this.loadingService.show();
    this.operationService.saveTally(this.tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide();
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private getExaminationTallyDetails(examinationTallyId: String) {
    this.operationService.getTallyDetailsById(examinationTallyId).subscribe({
      next: (response: any) => {
        this.examinationTallyDetails = response.data;
        this.jobOrderInventoryDetails = { ...response?.data };
        this.jobOrderStats = this.statsService.createJobOrderStatsForTallyEditMode(this.jobOrderInventoryDetails);
        this.getJobOrderDetailsForEditReadMode(response.data.jobOrderNo);
        // tallyDetailsArray.controls[0]?.patchValue(response.data)
      },
      error: (error: any) => {
        this.goBack();
        this.toasterService.error(error.error.errorDesc);
      }
    });
  }

  private addTallyDetailsFromArrayForAssociatedContainers(associatedContainers: Container | undefined) {
    this.dynamicFormService.addToFormArray(this.examinationTallyForm, "tallyDetails", this.examinationTallyFormJson);
  }

  private getJobOrderDetailsForAddNewMode(jobOrderInventoryDetails: JobOrderInventoryDetails) {
    const jobOrderDetails$ = this.operationService.getJobOrderDetails(jobOrderInventoryDetails?.jobOrderId, true, true, true);
    const tallies$ = this.operationService.listTalliesByJobOrder(encodeURIComponent(jobOrderInventoryDetails?.jobOrderNo));

    forkJoin([jobOrderDetails$, tallies$]).subscribe({
      next: ([jobOrderDetailsResponse, talliesResponse]: [any, any]) => {
        const data: JobOrderDetails = jobOrderDetailsResponse?.data;
        this.cargoItem = data.cargoList[0];
        this.containerList = data.cargoList[0]?.associatedContainerList?.map((container) => {
          return {
            ...container,
            disableCheckBox: !!talliesResponse.data.find((tally: {
              inventoryId: string
            }) => tally.inventoryId === container.id)
          };
        });
        this.inventoryDetailStats = this.statsService.createInventoryDetailStatsForTally(this.cargoItem);
        this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;

        this.savedTallyData = talliesResponse?.data;

        this.dataLoaded = true;
        this.loadingService.hide();
      },
      error: (error: any) => {
        console.log(error);
        this.loadingService.hide();
      }
    });
  }

  private getJobOrderDetailsForEditReadMode(jobOrderId: String) {
    this.operationService.getJobOrderDetails(jobOrderId, true, true, true).subscribe({
      next: (res: any) => {
        const data: JobOrderDetails = res?.data;
        this.cargoItem = data.cargoList[0];
        this.containerList = data.cargoList[0]?.associatedContainerList?.filter(container => container.id === this.examinationTallyDetails.inventoryId);
        this.inventoryDetailStats = this.statsService.createInventoryDetailStatsForTally(this.cargoItem);
        // this.addTallyDetailsFromArrayForAssociatedContainers(this.containerList);

        this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;
        this.dataLoaded = true;
        this.loadingService.hide();
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private formatDateTimeForInput(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // This will give you YYYY-MM-DDThh:mm
  }

  private setAppMode(path: string | undefined) {
    switch (path) {
      case "add-new":
        this.addNewMode = true;
        this.readOnlyMode = false;
        this.editingEnabled = false;
        break;
      case "edit":
        this.editingEnabled = true;
        this.readOnlyMode = false;
        this.addNewMode = false;
        break;
      case "view":
        this.readOnlyMode = true;
        this.editingEnabled = false;
        this.addNewMode = false;
        break;
      default:
        this.addNewMode = false;
        this.readOnlyMode = false;
        this.editingEnabled = false;
        break;
    }
  }
}
