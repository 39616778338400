<div class="vendor_info">Vendor Information</div>
<div class="vendor_table">
  <table>
    <thead>
    <tr>
      <th>Vendor</th>
      <th>Service</th>
      <th>Equipment</th>
    </tr>
    </thead>
    <tbody>
      @for (vendor of jobOrderVendors; track $index) {
        <tr>
          <td>{{ vendor.vendorName }}</td>
          <td>{{ vendor.serviceName }}</td>
          <td>{{ vendor.equipmentName }}</td>
        </tr>
      }
    </tbody>
  </table>
</div>
