<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">Tally</div>
      <div class="page_desc">Fill in Item Details and add Container Information</div>
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="goToAddRecordPage()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Add Tally
      </button>
    </div>
  </div>
</header>

<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by Job Order No. or Document No."
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>
  </div>
</div>

<div class="tos-table-template">


  <div class="table-container">
    <ng-container
      *ngIf="
            tableLoaded &&
            tableListData.records?.length > 0
          "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                      toggleAllRecordsOfCurrentPageSelections(
                        $event.target.checked
                      )
                    "
            />
          </th>
          <th
            class="jobOrderNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'jobOrderNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'jobOrderNo')"
            (click)="sortData('jobOrderNo')"
          >
            <div class="icon_cover">
              Job Order No.
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </div>
          </th>
          <th
            class="documentNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'documentNo' }"
            (click)="sortData('documentNo')"
          >
            <div class="icon_cover">
              Document No.
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th
            class="documentType_th sortable"
            [ngClass]="{ sorted: sortColumn === 'documentType' }"
            (click)="sortData('documentType')"
          >
            <div class="icon_cover">
              Document Type
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="tallyType_th sortable"
            [ngClass]="{ sorted: sortColumn === 'tallyType' }"
            (click)="sortData('tallyType')"
          >
            <div class="icon_cover">
              Tally Type
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="created_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdDate' }"
            (click)="sortData('createdDate')"
          >
            <div class="icon_cover">
              Created date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="modified_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedDate' }"
            (click)="sortData('modifiedDate')"
          >
            <div class="icon_cover">
              Modified date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="modifiedBy_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
            (click)="sortData('modifiedBy')"
          >
            <div class="icon_cover">
              Modified By
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th class="table_menu_th">
            <!-- <div class="icon_cover plus_icon">
                  <app-svg-icon
                    [path]="'assets/icons/plus_icon.svg'"
                  ></app-svg-icon>
                </div> -->
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'user_sl_no_' + i + 1"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="jobOrderNo_td" [title]="record.jobOrderNo">
                  <span
                    [innerHTML]="record.jobOrderNo | highlight : searchTerm"
                  ></span>
          </td>
          <td class="documentNo_td" [title]="record.documentNo">
            {{ record.documentNo }}
          </td>
          <td class="documentType_td" [title]="record.documentType">
            {{ record.documentType }}
          </td>
          <td class="tallyType_td" [title]="record.tallyType">{{ record.tallyType }}</td>
          <td class="modified_date_td">
            {{ record.modifiedDate | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="created_date_td">
            {{ record.createdDate | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="modifiedBy_td">
            {{ record.modifiedBy || "N/A" }}
          </td>
          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
            tableLoaded &&
            tableListData.records?.length === 0
          "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>
  <div class="pagination" [hidden]="tableListData.totalCount === 0">

    <div class="button_wrapper_left">
      <button (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>
  </div>
</div>
