import { Component, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ConfigLoaderService } from '../../../../../services/config-loader.service';
import { ExaminationJobOrderComponent } from '../import-fcl/examination-job-order.component';
import { getJobOrderByKey, JobOrderType } from '../../../../../constants/operations-contsants';
import { LclDestuffComponent } from '../lcl-destuff/lcl-destuff.component';
import { LclTruckDeliveryComponent } from '../lcl-truck-delivery/lcl-truck-delivery.component';
import { StuffingComponent } from '../stuffing/stuffing.component';
import { ContainerDeliveryComponent } from '../container-delivery/container-delivery.component';

@Component({
  selector: 'app-manage-jo-renderer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './manage-jo-renderer.component.html',
  styleUrl: './manage-jo-renderer.component.scss'
})
export class ManageJoRendererComponent {

  jobOrderId: string = '';
  jobOrderType: string = '';
  jobOrderConfig: any = {};
  viewMode: boolean = false;
  jobOrderLabel: string = '';

  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true })
  dynamicContainer!: ViewContainerRef;

  private componentRef!: ComponentRef<JobOrderFormActions>;

  constructor(private configLoader: ConfigLoaderService,
            private router: Router,
            private route: ActivatedRoute
            ) {
              
  }

  ngOnInit() {
    this.jobOrderId = this.route.snapshot.paramMap.get("job-order-id") || "";
    this.jobOrderType = this.route.snapshot.queryParamMap.get("joType") || "";

    if (this.jobOrderId && this.route.snapshot.url[0].path === "view") {
      this.viewMode = true;
      console.log("View Mode activated");
    }
    this.loadComponent(this.jobOrderType);
  }

  loadComponent(jobOrderType: string): void {
    this.dynamicContainer.clear();

    let componentToRender: any;
    let configFileName: string;

    const jobOrderTypeObj = getJobOrderByKey(this.jobOrderType);
    this.jobOrderLabel = jobOrderTypeObj?.value || "";
    switch (jobOrderTypeObj) {
      case JobOrderType.SEAL_CUT_EXAMINATION:
      case JobOrderType.RMS_AND_EXAMINATION:
        componentToRender = ExaminationJobOrderComponent;
        configFileName = 'seal-cut-examination';
        break;
      case JobOrderType.RMS_SEAL_VERIFICATION:
        componentToRender = ExaminationJobOrderComponent;
        configFileName = 'rms-seal-verification';
        break;
      case JobOrderType.FACTORY_DELIVERY:
        componentToRender = ExaminationJobOrderComponent;
        configFileName = 'loaded-delivery';
        break;
      case JobOrderType.DOCK_DELIVERY:
        componentToRender = ExaminationJobOrderComponent;
        configFileName = 'destuff-delivery';
        break;
      case JobOrderType.LCL_DESTUFF:
        componentToRender = LclDestuffComponent;
        configFileName = 'lcl-destuff'
        break;
      case JobOrderType.LCL_DELIVERY:
        componentToRender = LclTruckDeliveryComponent;
        configFileName = 'lcl-delivery';
        break;
      case JobOrderType.SMTP_TRUCK_MOVEMENT:
        componentToRender = LclTruckDeliveryComponent;
        configFileName = 'smtp-truck-delivery';
        break;
      case JobOrderType.SMTP_STUFFING:
        componentToRender = StuffingComponent;
        configFileName = 'smtp-stuffing';
        break;
      case JobOrderType.SMTP_CONTAINER_DELIVERY:
        componentToRender = ContainerDeliveryComponent;
        configFileName = 'smtp-container-delivery';
        break;
      default:
        console.error('Unknown component type');
        return;
    }

    this.configLoader.loadJobOrderConfig(configFileName).subscribe((config) => {
      this.componentRef = this.dynamicContainer.createComponent(componentToRender);
      (this.componentRef.instance as any).jobOrderConfig = config;
      (this.componentRef.instance as any).jobOrderId = this.jobOrderId;
      (this.componentRef.instance as any).jobOrderType = this.jobOrderType;
    });
  }

  saveForm() {
    if (this.componentRef) {
      const instance = this.componentRef.instance as JobOrderFormActions;
      instance.saveForm();
    }
  }

  cancel() {
    if (this.componentRef) {
      const instance = this.componentRef.instance as JobOrderFormActions;
      instance.cancel();
    }
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/job-orders");
  }

}


export interface JobOrderFormActions {
  saveForm(): void;
  cancel(): void;
}
