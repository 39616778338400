<ng-container>
  <mat-form-field appearance="outline" class="custom-select-field">
    <mat-select
      id="roles"
      [placeholder]="placeholder"
      [multiple]="multiple"
      [value]="multiple ? [] : value"
      (selectionChange)="onChange($event.value)"
      disableOptionCentering
    >
      <mat-select-trigger *ngIf="multiple && selectedOptionsCount > 0">
        {{ selectedOptionsCount }} selected
      </mat-select-trigger>
      <mat-option *ngFor="let option of options" [value]="option" disableRipple>
        {{ option[displayKey] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="multiple && selectedOptionsCount > 0">
    <div class="role_chips">
      <div class="chip" *ngFor="let option of value">
        <span> {{ option[displayKey] }}</span>
        <app-svg-icon
          [path]="'assets/icons/close.svg'"
          (click)="removeChip(option)"
        ></app-svg-icon>
      </div>
    </div>
  </ng-container>
</ng-container>