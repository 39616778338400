import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Control, DynamicForm, FormConditions } from "../../models/dynamic-form";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { of } from "rxjs";
import { NgClass, NgStyle } from "@angular/common";
import { group } from "@angular/animations";
import {
  EditableTableDynamicInputComponent
} from "../editable-table/editable-table-dynamic-input/editable-table-dynamic-input.component";
import { DynamicInputComponent } from "./dynamic-input/dynamic-input.component";
import { DynamicFormService } from "../../services/dynamic-form.service";

@Component({
  selector: 'app-dynamic-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgStyle,
    NgClass,
    EditableTableDynamicInputComponent,
    DynamicInputComponent
  ],
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.scss'
})
export class DynamicFormComponent {
  @Input() formConfig: DynamicForm | undefined;
  @Input() formGroup!: FormGroup;

  @Output() onAutoCompleteSuggestionSelected = new EventEmitter<{controlName: string, result: any}>();
  @Output() onAutoCompleteSearchTextCleared = new EventEmitter<any>();

  onSearchSuggestionSelect(event: {controlName: string, result: any}) {
    this.onAutoCompleteSuggestionSelected.emit(event)
  }

  onAutoCompleteSearchTermCleared(controlName: string) {
    this.onAutoCompleteSearchTextCleared.emit(controlName);
  }

  private controlMetadataMap: Map<string, Control> = new Map();

  constructor(private dynamicFormService: DynamicFormService) {}

  ngOnInit() {
    this.listenToConditionChanges(this.formConfig);
  }


  isControlRequired(control: Control) {
    if (control.fieldInfo?.dataType.includes('select')) 
      return control.validators?.some(validator => validator.type === 'selectRequired');

    return control.validators?.some(validator => validator.type === 'required');
  }

  isControlVisible(control: Control) {
    return !(control.hidden?? false) && this.isVisible(control.conditions);
  }

  listenToConditionChanges(config: DynamicForm | undefined): void {
    if (!config) return;
    const dependentFieldMap: Map<string, Set<string>> = new Map();

    config.formRows.forEach(row => {
      row.formGroups.forEach(group => {
        const control = group.control;
        if (group.control?.label) {
          this.controlMetadataMap.set(group.control.label, group.control);
        }
        if (control.conditions) {
          ['visibility', 'required', 'enabled'].forEach(conditionType => {
            const condition = control.conditions?.[conditionType as keyof FormConditions];
            if (condition) {
              condition.rules.forEach(rule => {
                const dependentField = rule.field;
                if (dependentField && !dependentFieldMap.has(dependentField)) {
                  dependentFieldMap.set(dependentField, new Set());
                }
                dependentFieldMap.get(dependentField)?.add(control.label);
              });
            }
          });
        }
      });
});

    // Subscribe to dependentField changes
    dependentFieldMap.forEach((targetFields, dependentField) => {
      const fieldControl = this.formGroup.get(dependentField);

      if (fieldControl) {
        fieldControl.valueChanges.subscribe(() => {
          targetFields.forEach(targetField => {
            const targetControl = this.formGroup.get(targetField);
            const fieldControl = this.controlMetadataMap.get(targetField);

            if (fieldControl && targetControl) {

              const conditions = fieldControl.conditions;
              const isVisible = !(!!conditions?.visibility) || this.dynamicFormService.evaluateCondition(conditions.visibility, this.formGroup);
              const isRequired = isVisible && (this.isControlRequired(fieldControl) || (!!conditions?.required && this.dynamicFormService.evaluateCondition(conditions?.required, this.formGroup)));
              const isDisabled = (fieldControl.disabled ?? false) || (!!conditions?.disabled && this.dynamicFormService.evaluateCondition(conditions.disabled, this.formGroup));

              if (!isVisible) {
                fieldControl.hidden = true;
                targetControl.clearValidators();
                targetControl.disable();
              } else {
                fieldControl.hidden = false;
                const validators = this.dynamicFormService.getValidators(fieldControl.validators || []).concat(isRequired ? Validators.required : []);
                targetControl.setValidators(validators);
                isDisabled ? targetControl.disable() : targetControl.enable();
              }
              targetControl.updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  private isVisible(conditions?: FormConditions) {
    return !(!!conditions?.visibility) || this.dynamicFormService.evaluateCondition(conditions.visibility, this.formGroup)
  }
}
