import {
  Component, ComponentRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../pipes/highlight.pipe";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../table-menu/table-menu.component";
import { TableHeaderAndData } from "../../models/table-header-and-data";
import { UserListDataResponse } from "../../models/user-list-data-response";
import { dateTimestampProvider } from "rxjs/internal/scheduler/dateTimestampProvider";
import { LoginHistoryComponent } from "../login-history/login-history.component";
import { NoDataComponent } from "../no-data/no-data.component";
import { NoDataFoundComponent } from "../no-data-found/no-data-found.component";
import { SharedModule } from "../../modules/shared/shared.module";
import { animate, state,style, transition, trigger } from "@angular/animations";
import { DynamicInputComponent } from "../dynamic-form/dynamic-input/dynamic-input.component";
import { AutocompleteDropdownComponent } from "../autocomplete-dropdown/autocomplete-dropdown.component";
import { convertEpochToReadableISTDateTime } from "../../utils/date-time-utils";
import {
  EditableTableDynamicInputComponent
} from "../editable-table/editable-table-dynamic-input/editable-table-dynamic-input.component";
import {
  MatAccordion, MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import { TableListRequest } from "../../models/table-list-request.interface";

@Component({
  selector: "app-accordion-table",
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    NgClass,
    NoDataComponent,
    NoDataFoundComponent,
    SharedModule,
    DynamicInputComponent,
    EditableTableDynamicInputComponent,
    AutocompleteDropdownComponent,  
    MatExpansionModule
  ],
  templateUrl: "./accordion-table.component.html",
  styleUrl: "./accordion-table.component.scss",
  animations: [
    trigger('accordionSlide', [
      state('closed', style({
        transform: 'translateY(-100%)',
        opacity: '0',
        height: '0px',
        padding: '0',
        visibility: 'hidden',
        overflow: 'hidden'
      })),
      state('open', style({
        transform: 'translateY(0)',
        opacity: '1',
        height: '*',
        padding: '16px',
        visibility: 'visible',
        overflow: 'visible'
      })),
      transition('closed <=> open', [
        animate('500ms cubic-bezier(0.4, 0, 0.2, 1)')
      ])
    ]),
    trigger('chevronRotate', [
      state('closed', style({
        transform: 'rotate(0deg)'
      })),
      state('open', style({
        transform: 'rotate(180deg)'
      })),
      transition('closed <=> open', [
        animate('500ms cubic-bezier(0.4, 0, 0.2, 1)')
      ])
    ])
  ]
})
export class AccordionTableComponent {
  @Input() tableHeaderAndData!: TableHeaderAndData;
  @Input() readOnly: boolean = false;
  @Output() onAccordionOpen = new EventEmitter<any>();
  @Output() onItemAddition = new EventEmitter<any>();
  @Output() onItemDeletion = new EventEmitter<any>();
  
  componentToLoad!: any;
  @Input() componentData: any;
  @Input() searchConfig: any;
  sortColumn: string = "modifiedDate";

  loadedAccordianSet: Set<string> = new Set();
  addedItemsSet: Set<string> = new Set();

  convertEpochToISTDateTime = convertEpochToReadableISTDateTime;

  @ViewChildren("accordionContent", { read: ViewContainerRef }) accordionContents!: QueryList<ViewContainerRef>;
  expandedRowIndex: number = -1;
  accordionOpen: boolean = false;
  searchText: string = "";
  selectedItem: any = "";
  disableAddButton: boolean = true;

  ngOnInit() {
    
  }

  isAllRecordsOfCurrentPageSelected() {

  }

  toggleAllRecordsOfCurrentPageSelections(checked: any) {

  }

  onResizeEnd($event: ResizeEvent, label: string) {

  }

  sortData(name: string) {

  }

  isSelected(record: UserListDataResponse) {
    return false;
  }

  toggleSelection(record: UserListDataResponse) {

  }

  onTableDataInit() {
    this.tableHeaderAndData?.data.forEach((item: any) => this.addedItemsSet.add(item.id));
  }

  loadComponent(component: any, rowIndex: number, data?: Partial<any>) {
    const componentRef = this.accordionContents.toArray()[rowIndex].createComponent(component)
      if (componentRef.instance && data) {
        Object.assign(componentRef.instance, data);
    }
  }

  onPanelClosed(index: number) {
    console.log('Panel closed', index);
    // this.accordionContents.toArray()[index].clear();
  }

  accordionOpened(index: number) {
    this.expandedRowIndex = index;
    const id = this.tableHeaderAndData.data[index].id;
    this.onAccordionOpen.emit({rowId: id, rowIndex: index});
  }

  accordionClosed(index: number){
    this.expandedRowIndex = -1
  }

  onSuggestionSelected(result: any) {
    this.selectedItem = result;
    this.disableAddButton = false;
  }

  addItem() {
    if (!this.addedItemsSet.has(this.selectedItem.id)) {
      this.tableHeaderAndData.data.push(this.selectedItem);
      this.onItemAddition.emit({item: this.selectedItem, index: this.tableHeaderAndData.data.length - 1});
      this.addedItemsSet.add(this.selectedItem.id);
    }
    this.searchText = '';
    this.disableAddButton = true;
    this.selectedItem = null;
  }

  updateAddedItems() {
    this.addedItemsSet.clear();
    if (this.tableHeaderAndData?.data) {
      this.tableHeaderAndData.data.forEach((record: any) => this.addedItemsSet.add(record.id));
    }
  }

  deleteItem(index: number) {
    const deletedItem = this.tableHeaderAndData.data[index];
    this.tableHeaderAndData.data.splice(index, 1);
    this.addedItemsSet.delete(deletedItem.id);
    this.onItemDeletion.emit({rowId: deletedItem.id, rowIndex: index});
  } 
}
