import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateUtils {


  /**
   * Formats an ISO date string to a specified format with AM/PM.
   * @param isoDate - The ISO string to format (e.g., 2024-12-18T18:30:00.000+00:00).
   * @param format - The desired Angular DatePipe format (default: 'dd-MM-yyyy, hh:mm a').
   * @param locale - The locale to use for formatting (default: 'en-US').
   * @returns The formatted date string or an empty string if input is invalid.
   */
  static formatDate(isoDate: string, format: string = 'dd-MM-yyyy, hh:mm a', locale: string = 'en-US'): string {
    if (!isoDate) {
      console.warn('Date string is invalid.');
      return '';
    }

    try {
      return formatDate(isoDate, format, locale);
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }
}
