import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-multi-select',
  standalone: true,
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  imports: [SharedModule, SvgIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({
        height: '*',
        opacity: 1,
        visibility: 'visible',
        overflow: 'auto'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        visibility: 'hidden',
        overflow: 'auto'
      })),
      transition('open <=> closed', animate('100ms ease-in-out'))
    ])
  ]
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() options: any[] = [];
  @Input() valueKey!: string;
  @Input() displayKey!: string;
  @Input() isMultiSelect: boolean = true;
  @Input() placeholder!: string;
  @Input() selectedItems: any[] = [];
  @Input() showChips: boolean = true;
  @Input() disable: boolean = false;

  @Output() onOptionSelection = new EventEmitter<any>();

  filteredOptions: any[] = [];
  searchQuery: string = '';
  dropdownOpen = false;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor() {
  }

  ngOnInit() {
    this.filteredOptions = this.options;
  }


  @ViewChild('dropdown', { static: false }) dropdown!: ElementRef;
  
  highlightedIndex = -1;

  // toggleSelection(option: any): void {
  //   if (!this.dropdownOpen) {
  //     return;
  //   }
  //   if (this.isSelected(option)) {
  //     this.selectedItems = this.selectedItems.filter((item) => item[this.valueKey] !== option[this.valueKey]);
  //   } else {
  //     this.selectedItems.push(option);
  //   }
  //   console.log("Selected Items: ", this.selectedItems);
  //   this.onChange(this.selectedItems);
  // }

  toggleSelection(option: any): void {
    if (this.isMultiSelect) {
      if (this.isSelected(option)) {
        this.selectedItems = this.selectedItems.filter((item) => item[this.valueKey] !== option[this.valueKey]);
      } else {
        this.selectedItems.push(option);
      }
    } else {
      this.selectedItems.length = 0;
      this.selectedItems.push(option);
      this.dropdownOpen = false;
      this.onOptionSelection.emit(option);
    }
    this.onChange(this.selectedItems);
  }

  setOptions(options: any[]): void {
    this.options = options;
    this.filteredOptions = [...this.options];
  }

  filterOptions(): void {
    if (!this.searchQuery) {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter(option =>
        option[this.displayKey].toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }

  isSelected(option: any): boolean {
    return this.selectedItems.some((item: any) => item[this.valueKey] === option[this.valueKey]);
  }

  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }

  removeChip(option: any): void {
    this.selectedItems = this.selectedItems.filter((item) => item[this.valueKey] !== option[this.valueKey]);
    this.onChange(this.selectedItems);
  }

  writeValue(value: any[]): void {
    if (Array.isArray(value)) {
      this.selectedItems = value;
    } else {
      this.selectedItems = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleInputKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }

  // Handle keydown on individual options
  handleOptionKeydown(event: KeyboardEvent, option: any): void {
    console.log("event.key => ", event.key, option);
    if (event.key === 'Enter') {
      this.toggleSelection(option);
    } else if (event.key === 'ArrowDown') {
      this.highlightNextOption();
    } else if (event.key === 'ArrowUp') {
      this.highlightPreviousOption();
    } else if (event.key === ' ') {
      this.toggleSelection(option);
    }
  }

  highlightNextOption(): void {
    if (this.highlightedIndex < this.options.length - 1) {
      this.highlightedIndex++;
    }
  }

  highlightPreviousOption(): void {
    if (this.highlightedIndex > 0) {
      this.highlightedIndex--;
    }
  }

  highlightOption(index: number): void {
    this.highlightedIndex = index;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.dropdown && this.dropdown.nativeElement && this.dropdownOpen) {
      if (!this.dropdown.nativeElement.contains(event.target)) {
        this.dropdownOpen = false;
      }
    }
  }
  
}
