export function convertEpochToISTDateTime(epochMillis: number) {
    if (!epochMillis) return '';
    const ISTOffset = 330;
    const ISTDate = new Date(epochMillis + ISTOffset * 60 * 1000);
    console.log("ISTDate: ", epochMillis);
    return ISTDate.toISOString().slice(0, 16);
}

export function convertEpochToReadableISTDateTime(epochMillis: number) {
    if (!epochMillis) return '';
    const ISTOffset = 330;
    const ISTDate = new Date(epochMillis + ISTOffset * 60 * 1000);
    // Extract components
    const day = String(ISTDate.getUTCDate()).padStart(2, '0');
    const month = String(ISTDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = ISTDate.getUTCFullYear();
    let hours = ISTDate.getUTCHours();
    const minutes = String(ISTDate.getUTCMinutes()).padStart(2, '0');

    // Determine AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes} ${period}`;
}

export function getMaxDateTimeForDatePicker(): any {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const date = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${date}T${hours}:${minutes}`;
}