import { Component, Input } from "@angular/core";
import { Stats } from "../../models/stats.interface";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-stats-horizontal',
  standalone: true,
  imports: [
    SvgIconComponent,
    DatePipe
  ],
  templateUrl: './stats-horizontal.component.html',
  styleUrl: './stats-horizontal.component.scss'
})
export class StatsHorizontalComponent {
  @Input() stats: Stats[] = [];
}
