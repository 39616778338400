import { Component, ElementRef, QueryList, ViewChildren, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ApiService } from "../../../../services/api.service";
import { ToasterService } from "../../../../services/toaster.service";
import { LoadingService } from "../../../../services/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Vendors } from "../../../../models/vendor-master.interface";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { BackButtonComponent } from "../../../back-button/back-button.component";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { SharedModule } from "../../../../modules/shared/shared.module";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";

@Component({
  selector: 'app-manage-vendor',
  standalone: true,
  imports: [
    SharedModule,
    AutocompleteDropdownComponent,
    BackButtonComponent,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    SvgIconComponent,
    NgClass
  ],
  templateUrl: './manage-vendor.component.html',
  styleUrl: './manage-vendor.component.scss'
})
export class ManageVendorComponent {
  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = '';
  serviceList: any[] = [];
  selectedService: any = {};
  deletedServiceIds: Set<String> = new Set();
  addedServicesSet: Set<String> = new Set();
  serviceSearchText: string = '';
  disableAddButton: boolean = true;

  selectedTab: string = "vendorDetailsSection";
  private sectionMap: Map<string, any> = new Map();

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
  @ViewChild("vendorDetailsSection") vendorDetailsSection: ElementRef | undefined;
  @ViewChild("manageServiceSection") manageServiceSection: ElementRef | undefined;

  private destroy$ = new Subject<void>();
  vendorId: string[] | undefined;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get('vendorId') || '';
    console.log(this.masterId)
    this.setupComponentMode();
    if (this.masterId) {
      this.loadMasterData();
    }
  }

  ngAfterViewInit() {
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
    if (this.manageServiceSection) this.sectionMap.set("manageServiceSection", this.manageServiceSection.nativeElement);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      services: [[]],
      serviceTaxNumber: [''],
      pdaBalance: [''],
      incomeTaxNumber: [''],
      tdsNumber: [''],
      tdsPercentage: [''],
      tdsApplicable: [''],
      contactInformation: this.fb.array([this.createContactFormGroup()]),
    });
  }

  createContactFormGroup(): FormGroup {
    return this.fb.group({
      address: [''],
      billingAddress: [''],
      mobileNumber1: [''],
      mobileNumber2: [''],
      faxNumber: [''],
      email: [''],
      pinCode: [''],
      cityId: [''],
      stateId: [''],
      countryId: [''],
      communicationPreference: ['mobile'],
      boardLine: [''],
      directLine: [''],
      gstNumber: [''],
      gstValidTill: [''],
      defaultContact: [false],
      panNumber: [''],
      sez: [false],
      lut: [false],
    });
  }

  get contactInformation(): FormArray {
    return this.masterForm.get('contactInformation') as FormArray;
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === 'edit';
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  private loadMasterData(): void {
    this.loadingService.show();
    this.api.getVendorMaster(this.masterId, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const vendorsData: Vendors = res.data;
          this.serviceList = vendorsData.selectedServices || [];
          this.serviceList.forEach(vendorService => this.addedServicesSet.add(vendorService.serviceId));
          this.patchFormWithMasterData(vendorsData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error('Error loading vendor data:', err);
          this.toasterService.error('Failed to load vendor data');
          this.loadingService.hide();
        },
      });
  }

  private patchFormWithMasterData(vendors: Vendors): void {
    this.masterForm.patchValue(vendors);
  }

  onServiceSelected(service: any) {
    this.selectedService = service;
    this.disableAddButton = false;
  }

  addSelectedService() {
    if (!this.addedServicesSet.has(this.selectedService.id)) {
      this.addedServicesSet.add(this.selectedService.id);
      this.deletedServiceIds.delete(this.selectedService.id);
      this.serviceList.push({
        serviceId: this.selectedService.id,
        serviceName: this.selectedService.name,
        isServiceActive: this.selectedService.active,
        isDefault: false
      });
    }
    this.serviceSearchText = "";
    this.disableAddButton = true;
  }

  selectVendorAsDefault(index: number) {
    this.serviceList[index].isDefault = !this.serviceList[index].isDefault;
  }

  removeService(index: number) {
    const serviceId = this.serviceList[index].serviceId;
    this.addedServicesSet.delete(serviceId);
    if (this.masterEditingEnabled) {
      this.deletedServiceIds.add(serviceId);
    }
    this.serviceList.splice(index, 1);
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const masterData: Vendors = this.masterForm.value;
      masterData.active = true; //always create new master resource with active=true

      masterData.selectedServices = this.serviceList;
      masterData.deletedServiceIds = [...this.deletedServiceIds];

      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateVendorMaster(masterData, this.masterId)
        : this.api.addVendorMaster(masterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `Vendor ${masterData.name} has been updated!`
            : `Vendor ${masterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || 'An error occurred');
          console.error('Error submitting form:', err);
          this.loadingService.hide();
        },
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains('ng-invalid');
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl('/manage-masters/vendors');
  }
}
