<header class="page_header">
    <div class="header_content">
      <div class="text_and_supporting_text">
        <div class="page_title">Equipment Master</div>
      </div>
      <div class="action_btn">
        <button class="add_new_btn" (click)="addEquipment()">
          <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New
        </button>
      </div>
    </div>
  </header>
  
  <div class="search_bar_action_btns">
    <div class="container">
      <div class="search_bar">
        <div class="search_container">
          <input
            type="text"
            name="search_table"
            id="search_table"
            [(ngModel)]="searchTerm"
            placeholder="Search by name or email"
            (focus)="focusSearch = true"
            (focusout)="focusSearch = false"
            (keyup)="handleTableSearch($event)"
          >
          <img src="assets/icons/search_icon.svg" class="search_icon">
        </div>
      </div>
  
      <div class="filter_btn">
        <button (click)="filter()">
          <img src="assets/icons/filter.svg">
          Filters
        </button>
      </div>
    </div>
  </div>
  
  <div class="tos-table-template">
    <div class="table-container">
      <ng-container *ngIf="!tableLoaded">
        <app-no-data
          [msg]="
              'No IGM Desk data are available in the system. Please click on Add New Item button to create one.'
            "
        ></app-no-data>
      </ng-container>
      <ng-container
        *ngIf="
            tableLoaded &&
            tableListData.records?.length > 0
          "
      >
        <table>
          <thead>
          <tr>
            <th class="cb_th select-all-cb">
              <input
                type="checkbox"
                id="select_all_users"
                [checked]="isAllRecordsOfCurrentPageSelected()"
                (change)="
                      toggleAllRecordsOfCurrentPageSelections($event.target.checked)
                    "
              />
            </th>
            <!--            <th class="sl_no_th">Sl No.</th>-->
            <th
              class="name_th sortable"
              [ngClass]="{ sorted: sortColumn === 'name' }"
              mwlResizable
              (resizing)="onResizeEnd($event,'name')"
            >
              <div class="icon_cover" (click)="sortData('name')">
                Equipment Name
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </th>
            <th
              class="description_th sortable"
              [ngClass]="{ sorted: sortColumn === 'description' }"
              mwlResizable
              (resizing)="onResizeEnd($event,'description')"
            >
              <div class="icon_cover" (click)="sortData('description')">
                Description
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </th>
  
            <th
              class="status_th sortable"
              [ngClass]="{ sorted: sortColumn === 'active' }"
              mwlResizable
              (resizing)="onResizeEnd($event,'active')"
            >
              <div class="icon_cover" (click)="sortData('active')">
                Status
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </th>

            <th
              class="modifiedDate_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedDate' }"
              mwlResizable
              (resizing)="onResizeEnd($event,'modifiedDate')"
            >
              <div class="icon_cover" (click)="sortData('modifiedDate')">
                Modified Date
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </th>

            <th
              class="modifiedBy_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
              mwlResizable
              (resizing)="onResizeEnd($event,'modifiedBy')"
            >
              <div class="icon_cover" (click)="sortData('modifiedBy')">
                Modified By
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }"
              ></div>
            </th>
  
            <th class="table_menu_th">
              <div class="icon_cover plus_icon">
                <app-svg-icon
                  [path]="'assets/icons/plus_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            *ngFor="let record of tableListData.records; let i = index"
            [ngClass]="isSelected(record) ? 'selected_row' : ''"
          >
            <td class="cb_td row-select-cb">
              <input
                type="checkbox"
                [id]="'record_sl_no_' + record.id"
                [checked]="isSelected(record)"
                (change)="toggleSelection(record)"
              />
            </td>
    
            <td class="name_td" [title]="record.name">
              <span [innerHTML]="record.name | highlight : searchTerm"></span>
            </td>
  
            <td class="description_td" [title]="record.description">
              <span>{{ record.description }}</span>
            </td>
  
            <td class="status_td">
                <div
                  class="icon_cover"
                  [ngClass]="record.active ? 'active' : 'inactive'"
                >
                  <div class="badge_status">
                    <app-svg-icon
                      [path]="'assets/icons/circle_icon.svg'"
                    ></app-svg-icon>
                    {{ record.active ? "Active" : "Inactive" }}
                  </div>
                </div>
              </td>
  
            <td class="modifiedDate_td">
              {{ record.modifiedDate | date : "dd/MM/yy hh:mm a" }}
            </td>

            <td class="modifiedBy_td">
                {{ record.modifiedBy }}
              </td>

            <td class="table_menu_td">
              <div class="menu_component">
                <app-table-menu
                  [options]="menuOptions"
                  [isOpen]="openMenuIndex === i"
                  (toggle)="handleMenuToggle(i)"
                  (optionSelected)="handleOptionSelected(record, $event)"
                  [tableRowData]="record"
                >
                </app-table-menu>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container
        *ngIf="
            tableLoaded &&
            tableListData.records?.length === 0
          "
      >
        <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
      </ng-container>
    </div>
  
    <div class="pagination" [hidden]="tableListData.totalCount === 0">
  
      <div class="button_wrapper_left">
        <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
      </div>
      <ng-container *ngIf="totalTablePages" class="page_numbers">
        <div
          *ngFor="let page of getDisplayedPages()"
          (click)="page !== '...' ? goToPage(page) : null"
          [class.page_number]="page !== '...'"
          [class.ellipsis]="page === '...'"
          [class.active]="currentTablePage === page"
        >
          {{ page }}
        </div>
      </ng-container>
  
      <div class="button_wrapper_right">
        <div class="rows_per_page">
          Rows per page
          <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
      </div>
    </div>
  </div>
  