import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormArray, FormsModule } from "@angular/forms";
import { DynamicForm } from '../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { TableHeaderAndData } from '../../../../models/table-header-and-data';
import { MasterService } from '../../../../services/masters-service';
import { forkJoin } from 'rxjs';
import { LoadingService } from '../../../../services/loading.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MultiSelectComponent } from '../../../select/multi-select.component';
import { convertEpochToReadableISTDateTime } from '../../../../utils/date-time-utils';

@Component({
  selector: 'app-job-order-vendor',
  standalone: true,
  imports: [DynamicFormComponent, AutocompleteDropdownComponent, SvgIconComponent, CommonModule, FormsModule, MultiSelectComponent],
  templateUrl: './job-order-vendor.component.html',
  styleUrl: './job-order-vendor.component.scss'
})
export class JobOrderVendorComponent {

  @Input() vendorList !: any;
  @Input() itemDetails!: any;
  @Input() index!: number;
  @Input() formGroup!: FormGroup;
  @Input() dynamicForm!: DynamicForm; 
  @Input() readOnly: boolean = false;
  @Input() tableHeaderAndData!: TableHeaderAndData;

  @Output() onVendorAddition = new EventEmitter<any>();
  @Output() onVendorDeletion = new EventEmitter<any>();

  convertEpochToISTDateTime = convertEpochToReadableISTDateTime;

  //selectedVendor!: any;
  selectedService!: any;
  selectedEquipments: any[] = [];
  selectedVendors: any[] = [];

  serviceVendorList: any[] = [];
  serviceEquipmentList: any[] = [];
  serviceSearchQuery: string = '';


  @ViewChild('vendorDropdown', { static: false }) vendorDropdown!: MultiSelectComponent;
  @ViewChild('equipmentDropdown', { static: false }) equipmentDropdown!: MultiSelectComponent;

  addedServiceVendorMap: Map<string, any> = new Map();
  addedServiceVendorEquipmentSet: Set<string> = new Set();


  headerMap: { label: string; value: string }[] = [
    { label: "Vendor", value: "vendorName" },
    { label: "Service", value: "serviceName" },
    { label: "Equipment", value: "equipmentName" }
  ];

  constructor(private dynamicFormService: DynamicFormService, 
              private masterService: MasterService,
              private loadingService: LoadingService,
              private toasterService: ToasterService) {
  }

  ngOnInit() {
    console.log("Inside JobOrderVendorComponent: ", this.tableHeaderAndData?.data?.length);
    if (this.dynamicForm && this.formGroup) {
      this.dynamicFormService.addControlsToForm(this.formGroup, this.dynamicForm, false);
      this.formGroup.patchValue({
        ...this.itemDetails
      });
    }

    if (this.vendorList?.length > 0) {
      this.vendorList.forEach((item: any) => this.addToSet(item?.vendorId, item?.serviceId, item?.equipmentId));
    }

    //this.dynamicFormService.getOrCreateFormArray(this.formGroup, "vendorDetails");
  }

  get showItemTable(): boolean {
    return !!this.tableHeaderAndData?.headers && !!this.tableHeaderAndData?.data?.length;
  }

  handleVendorSelection(result: any) {
    // const vendorDetails = this.getAddedVendorService(result.vendorId, this.selectedService.id);
    // if (vendorDetails) {
    //   //this.selectedEquipments = [...vendorDetails.selectedEquipments];
    // }
  }

  onServiceSelection(result: any) {
    this.selectedService = result;
    this.loadingService.show();
    forkJoin({
          vendorResult: this.masterService.listVendorsByService(result.id, true),
          equipmentResult: this.masterService.listEquipmentsByService(result.id, true)
        }).subscribe({
          next: ({vendorResult, equipmentResult}: any) => {
            this.serviceVendorList = vendorResult.data;
            this.serviceEquipmentList = equipmentResult.data;
            this.vendorDropdown.setOptions(this.serviceVendorList);
            this.equipmentDropdown.setOptions(this.serviceEquipmentList);
            this.loadingService.hide();
          },
          error: (err) => {
            console.error(err);
            this.loadingService.hide();
          },
        });
  }

  serviceSearchCleared() {
    this.selectedService = null;
    this.selectedVendors = [];
    this.selectedEquipments = [];
  }

  onEquipmentSelection(result: any) {
    //this.selectedEquipment = result;
  }

  get canSelectVendor(): boolean {
    return !!this.selectedService;
  }

  get canSelectEquipment(): boolean {
    return !!this.selectedService;
  }

  onDeleteRow(index: number): void {
    console.log('Delete row at index:', index);
    const vendor = this.vendorList[index];
    this.vendorList.splice(index, 1);
    this.removeFromSet(vendor?.vendorId, vendor?.serviceId, vendor?.equipmentId);
    //this.vendorFormArray.removeAt(index);
  }

  // get vendorFormArray() {
  //   return this.formGroup.get("vendorDetails") as FormArray;
  // }

  // addVendorToList() {
  //   const selectedVendor = this.selectedVendors[0];
  //   const vendorDetails = this.getAddedVendorService(selectedVendor.vendorId, this.selectedService.id);
  //   if (vendorDetails) {
  //     vendorDetails.selectedEquipments = this.selectedEquipments;
  //   } else {
  //     const vendor = {
  //       vendorId: selectedVendor.vendorId,
  //       vendorName: selectedVendor.vendorName,
  //       serviceId: this.selectedService.id,
  //       serviceName: this.selectedService.name,
  //       selectedEquipments: this.selectedEquipments
  //     }
  //     this.vendorList.push(vendor);
  //     this.addToVendorServiceKey(vendor);
  //   }
    
  //     // const vendorFormGroup = this.dynamicFormService.createEmptyFormGroup();
  //     // this.addVendorControlToGroup(vendorFormGroup, this.selectedVendor.id, this.selectedService.id, this.selectedEquipment.id);
  //     // this.vendorFormArray.push(vendorFormGroup);
  //   console.log(this.vendorList);
  //   this.selectedService = null;
  //   this.serviceSearchQuery = '';
  //   this.serviceVendorList = [];
  //   this.serviceEquipmentList = [];
  //   this.selectedEquipments = [];
  //   this.selectedVendors = [];
  // }

  addVendorToList() {
    const selectedVendor = this.selectedVendors[0];
    this.selectedEquipments.forEach((equipment: any) => {
      if (this.alreadyAdded(selectedVendor.vendorId, this.selectedService.id, equipment.equipmentId)) {
        //this.toasterService.warning("Already added");
      } else if (selectedVendor && this.selectedService && equipment) {
          const vendor = {
            vendorId: selectedVendor.vendorId,
            vendorName: selectedVendor.vendorName,
            serviceId: this.selectedService.id,
            serviceName: this.selectedService.name,
            equipmentId: equipment.equipmentId,
            equipmentName: equipment.equipmentName
          }
          this.vendorList.push(vendor);
          this.addToSet(selectedVendor.vendorId, this.selectedService.id, equipment.equipmentId);
      }
    });
    
      // const vendorFormGroup = this.dynamicFormService.createEmptyFormGroup();
      // this.addVendorControlToGroup(vendorFormGroup, this.selectedVendor.id, this.selectedService.id, this.selectedEquipment.id);
      // this.vendorFormArray.push(vendorFormGroup);
    console.log(this.vendorList);
    this.selectedService = null;
    this.serviceSearchQuery = '';
    this.serviceVendorList = [];
    this.serviceEquipmentList = [];
    this.selectedEquipments = [];
    this.selectedVendors = [];
  }

  // private addToVendorServiceKey(vendor: any) {
  //   this.addedServiceVendorMap.set(vendor.vendorId + ":" + vendor.serviceId, vendor);
  // }

  // private getAddedVendorService(vendorId: string, serviceId: string): any {
  //   return this.addedServiceVendorMap.get(vendorId + ":" + serviceId)
  // }

  private addToSet(vendorId: string, serviceId: string, equipmentId: string) {
    this.addedServiceVendorEquipmentSet.add(vendorId + serviceId + equipmentId)
  }

  private removeFromSet(vendorId: string, serviceId: string, equipmentId: string) {
    this.addedServiceVendorEquipmentSet.delete(vendorId + serviceId + equipmentId)
  }

  private alreadyAdded(vendorId: string, serviceId: string, equipmentId: string): boolean {
    return this.addedServiceVendorEquipmentSet.has(vendorId + serviceId + equipmentId)
  }

  get canAddVendors(): boolean {
    return this.selectedVendors.length > 0 && this.selectedService && this.selectedEquipments.length > 0;
  }
}
