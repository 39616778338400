<div class="tos-table-template">
  <div class="table-container">
    <ng-container *ngIf="tableListData && tableListData?.records === 0">
      <app-no-data
        [msg]="
          'No users are available in the system. Please click on Add New User button to create one.'
        "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
        tableListData && tableListData.records?.length > 0
      "
    >
      <table>
        <thead>
        <tr>
          <th>Login Attempted At</th>
          <th>Login Success</th>
          <th>User Agent Details</th>
          <th>User IP Address</th>
          <th>Strategy</th>

        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let record of tableListData.records">
          <td>{{ record.loginAttemptedAt | date : "dd/MM/yy hh:mm a" }}</td>
          <td>
            <div
              class="icon_cover"
              [ngClass]="record.loginSuccess? 'admin' : 'not_admin'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/shield_icon.svg'"
                ></app-svg-icon>
                {{ record.loginSuccess ? "Yes" : "No" }}
              </div>
            </div>

          </td>
          <td>{{ record.userAgentDetails || "N/A" }}</td>
          <td>{{ record.userIPAddress || "N/A" }}</td>
          <td>{{ record.strategy }}</td>

        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
        tableListData.records?.length === 0 && dataLoaded
      "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>
  <div class="pagination" [hidden]="tableListData.totalCount === 0">

    <div class="button_wrapper_left">
      <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select  class="select_dropdown" (change)="updateLimit($event.target.value)">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>

    <!--    <span class="info"> Per page:</span>-->
    <!--    <select-->
    <!--      id="rowsPerPage"-->
    <!--      class="row-options"-->
    <!--      #rowOptions-->
    <!--      (change)="updateRowsPerPage(rowOptions.value)"-->
    <!--    >-->
    <!--      <option-->
    <!--        class="option"-->
    <!--        *ngFor="let option of rowsPerPageOptions"-->
    <!--        [value]="option"-->
    <!--      >-->
    <!--        {{ option }}-->
    <!--      </option>-->
    <!--    </select>-->
  </div>
</div>
