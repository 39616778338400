<div class="header">
  <div class="page_header">
    <div class="left">
      <div class="title">Tally</div>
      <div class="sub_heading">Find, add and edit the Tally information</div>
    </div>
    <div class="right">
      @if (readOnlyMode) {
        <button class="secondary_button" (click)="goBack()">Go Back</button>
      }
      @if (!readOnlyMode) {
        <button class="secondary_button" (click)="goBack()">Cancel</button>
        <button class="primary_button" (click)="submitForm()">@if (editingEnabled) {
          Update
        } @else if (addNewMode) {
          Save
        }</button>
      }
    </div>
  </div>
</div>
<div class="full_page_container">
  @if (addNewMode) {
    <div class="search_bar_tally">
      <label class="required_field_label">JO No/IGM item No</label>
      <app-autocomplete-dropdown
        #jobOrderInventorySearch
        id="JobOrderInventory"
        name="JobOrderInventory"
        [placeholder]="'Enter Job Order No/IGM Item No'"
        [apiUrl]="'v1/job-order-inventories/list?onlyOpenJobOrders=true'"
        [selectFields]="['id',
        'jobOrderNo',
        'jobOrderNo.id as jobOrderId',
        'jobOrderNo.expiryDate as dueDate',
        'jobOrderNo.createdDate as issueDate',
        'inventoryId',
        'inventoryNo',
        'jobOrderNo.jobOrderType as jobOrderType',
        'jobOrderNo.additionalDetails as additionalDetails'
        ]"
        [whereFields]="['jobOrderNo','inventoryNo']"
        [limit]="10"
        [orderBy]="{ jobOrderNo: 'asc' }"
        [filterByKey]="'jobOrderNo'"
        [bindLabel]="'jobOrderNo'"
        [bindValue]="'id'"
        [displayColumns]="[
                        { key: 'jobOrderNo', header: 'Job Order Number' },
                        { key: 'inventoryNo', header: 'Inventory Number' },
                        { key: 'jobOrderType', header: 'Job Order Type' }
                    ]"
        (suggestionSelected)="jobOrderSelected($event)"
      ></app-autocomplete-dropdown>
    </div>
  }
  <div class="content">
<!--    <app-manage-tally-renderer
      [tallyType]="tallyType"
      [jobOrderInventoryDetails]="jobOrderInventoryDetails"
      [jobOrderStats]="jobOrderStats"
    >
    </app-manage-tally-renderer>-->
    @if (tallyType === TallyType.SEAL_CUT_EXAMINATION_TALLY || tallyType === TallyType.RMS_EXAMINATION_TALLY) {
      <app-manage-examination-tally #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                    [jobOrderStats]="jobOrderStats"></app-manage-examination-tally>
    } @else if (tallyType === TallyType.DOCK_DELIVERY_TALLY && jobOrderInventoryDetails?.additionalDetails?.destuffTo === 'WAREHOUSE') {
      <app-manage-tally-destuffing-fcl #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                       [jobOrderStats]="jobOrderStats"></app-manage-tally-destuffing-fcl>
    } @else if (tallyType === TallyType.DOCK_DELIVERY_TALLY && jobOrderInventoryDetails?.additionalDetails?.destuffTo === 'TRUCK') {
      <app-manage-tally-destuffing-fcl-truck #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                             [jobOrderStats]="jobOrderStats"></app-manage-tally-destuffing-fcl-truck>
    } @else if (tallyType === TallyType.LCL_DESTUFF_TALLY) {
      <app-manage-tally-destuffing-lcl #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                       [jobOrderStats]="jobOrderStats"></app-manage-tally-destuffing-lcl>
    } @else if (tallyType === TallyType.LCL_DELIVERY_TALLY) {
      <app-manage-lcl-delivery-tally #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                     [jobOrderStats]="jobOrderStats"></app-manage-lcl-delivery-tally>
    } @else if (tallyType === TallyType.SMTP_STUFFING_TALLY) {
      <app-manage-smtp-stuffing-tally #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                      [jobOrderStats]="jobOrderStats"></app-manage-smtp-stuffing-tally>
    } @else if (tallyType === TallyType.SMTP_CONTAINER_DELIVERY_TALLY) {
<!--      <app-manage-tally-smtp-container-delivery #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                                [jobOrderStats]="jobOrderStats"></app-manage-tally-smtp-container-delivery>-->
      <app-no-data [msg]="'Enter the Item No or Job order number above to Perform Tally.'"
                   [path]="'assets/icons/tally-not-found.svg'"></app-no-data>
    } @else if (tallyType === TallyType.SMTP_TRUCK_MOVEMENT_TALLY) {
      <app-manage-tally-smtp-truck-movement #tallyForm [jobOrderInventoryDetails]="jobOrderInventoryDetails"
                                            [jobOrderStats]="jobOrderStats"></app-manage-tally-smtp-truck-movement>
    } @else {
      <app-no-data [msg]="'Enter the Item No or Job order number above to Perform Tally.'"
                   [path]="'assets/icons/tally-not-found.svg'"></app-no-data>
    }
  </div>
</div>
