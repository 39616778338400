import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    SvgIconComponent,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {
  //@Input() formControlName!: string; 
  @Input() displayKey: string = '';
  @Input() options: any[] = [];
  @Input() multiple: boolean = false;
  @Input() placeholder: string = 'Select an option';
  // @Input() preSelectedOptions: any[] = [];

  @Output() selectionChange = new EventEmitter<string | string[]>();

  value: any;
  onChange!: (value: any) => void;
  onTouched!: () => void;
  //selectControl = new FormControl();

  constructor() {
    // this.selectControl.valueChanges.subscribe((value) => {
    //   this.selectionChange.emit(value);
    // });
  }

  ngOnInit() {
    // if (this.preSelectedOptions && this.preSelectedOptions.length > 0) {
    //   this.selectControl.setValue(this.preSelectedOptions); // Initialize with preselected options
    // }
  }

  removeChip(option: string) {
    const value = this.value as string[];
    const index = value.indexOf(option);
    if (index >= 0) {
      value.splice(index, 1);
      this.value = value;
      this.selectionChange.emit(value);
    }
  }

  get selectedOptions(): string[] {
    return this.value || [];
  }

  get selectedOptionsCount(): number {
    return this.value?.length || 0;
  }

  writeValue(value: any): void {
    console.log("Inside Write value: ", value);
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onValueChange(event: any) {
    this.value = event;
    this.onChange(this.value);
  }
}
