import { ScrollingModule } from "@angular/cdk/scrolling";
import { ApiService } from "../../services/api.service";
import { SharedModule } from "../../modules/shared/shared.module";
import { MaterialModule } from "../../modules/material/material.module";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef, SimpleChanges
} from "@angular/core";
import { of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from "rxjs/operators";
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR, ValidationErrors
} from "@angular/forms";
import {
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent
} from "@ng-select/ng-select";
import { TableListRequest, FilterCondition } from "../../models/table-list-request.interface";
import { OrderBy } from "../../models/user-list-request.interface";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { ClickOutsideDirective } from "../../directives/click-outside.directive";
import { HighlightPipe } from "../../pipes/highlight.pipe";
import { select } from "@ngrx/store";
import { log } from "@angular-devkit/build-angular/src/builders/ssr-dev-server";

@Component({
  selector: "app-autocomplete-dropdown",
  standalone: true,
  imports: [
    SharedModule,
    ScrollingModule,
    MaterialModule,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    SvgIconComponent,
    ClickOutsideDirective,
    HighlightPipe
  ],
  templateUrl: "./autocomplete-dropdown.component.html",
  styleUrl: "./autocomplete-dropdown.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteDropdownComponent),
      multi: true
    }
  ]
})
export class AutocompleteDropdownComponent implements ControlValueAccessor {
  @Input() apiUrl: string = "";
  @Input() selectFields: string[] = [];
  @Input() whereFields: string[] = [];
  @Input() limit: number = 10;
  @Input() orderBy: OrderBy = {};
  @Input() filterByKey: string = "";
  @Input() placeholder: string = "Select";
  @Input() formControl!: FormControl;
  @Input() bindLabel: string = "";
  @Input() id: string = "";
  @Input() bindValue: string = "";
  @Input() skipIds: string[] = [];
  @Input() disable: boolean = false;
  @Input() additionalFilters: any = [];
  @Input() displayColumns: Array<{ key: string; header: string }> = [];
  @Input() preSelectedValue!: any;
  @Input() allowCustomInput: boolean = false;
  @Input() clientSideSearch: boolean = false;
  @Input() clientSearchData: any[] = [];
  @Output() searchCleared = new EventEmitter<void>();
  @Output() suggestionSelected = new EventEmitter<any>();
  @Output() customInputSelected = new EventEmitter<string>();

  value: any;
  onChange: (value: any) => void = () => {
  };
  onTouched: () => void = () => {
  };

  isDisabled: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  focusSearch: boolean = false;
  suggestions: any[] = [];
  showSuggestions = false;
  page = 0;
  canPullMoreData: boolean = false;
  loading = false;
  searchTermSubject: Subject<string> = new Subject<string>();
  searchListRequest: TableListRequest = {
    selectFields: [],
    orderBy: this.orderBy,
    limit: this.limit | 10,
    page: this.page | 0
  };
  maxVisibleItems: number = this.limit | 10;

  constructor(private apiService: ApiService) {
  }

  // Set initial value from form control
  writeValue(object: any): void {
    if (object && typeof object === 'object' && object.hasOwnProperty('key')) {
      this.value = object?.key ?? '';
      this.searchTerm = object.value || this.value;
    } else if (object && typeof object === 'string') {
      this.value = object;
      this.searchTerm = object;
    } else {
      this.value = '';
      this.searchTerm = '';
    }
  }

  // writeValue(value: any): void {
  //   if (value) {
  //     if (typeof value === 'string') {
  //       this.value.key = '';
  //       this.value.label = value;
  //     } else if (typeof value === 'object' && value !== null) {
  //       this.value = value
  //     }
  //   } else {
  //     this.value.key = '';
  //     this.value.label = '';
  //   }
  // }

  // get searchTerm() {
  //   return this.value.label;
  // }

  // When value changes in the form control
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // On blur or touch event
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // If the form control is disabled
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  fetchSuggestions() {
    if (this.clientSideSearch) {
      this.suggestions = this.clientSearchData.filter((item: any) =>
        item[this.whereFields[0]].toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      this.showSuggestions = true;
    } else {
      this.loading = true;
      const payload = this.getRequestPayload();
      this.apiService.getSuggestions(this.apiUrl, payload).subscribe({
        next: (res) => {
          const newRecords = res.data.records || [];
          if (this.page === 0) {
            this.suggestions = newRecords; // Initial load
          } else {
            this.suggestions = [...this.suggestions, ...newRecords]; // Append to existing data
          }
          if (newRecords.length < this.limit || newRecords.length === 0) {
            this.canPullMoreData = false;
          } else {
            this.canPullMoreData = true;
          }
          this.loading = false;
          this.showSuggestions = true;
        },
        error: (err) => {
          console.error('Error fetching suggestions:', err);
          this.loading = false;
        },
      });
    }
    
  }

  getRequestPayload(): TableListRequest {
    const payload: TableListRequest = {
      selectFields: this.selectFields,
      orderBy: this.orderBy,
      limit: this.limit,
      page: this.page,
    };

    const filterConditions: FilterCondition[] = this.whereFields.map((field) => ({
            name: field,
            alias: field,
            operator: 'CONTAINS',
            value: [this.searchTerm]
    }));

  console.log(filterConditions)

   let expression = filterConditions
        .map(fc => fc.alias)
        .join(' OR ');

    if (this.skipIds.length > 0) {
      filterConditions.push({
          name: 'id',
          alias: 'skipCondition',
          operator: 'NOT_IN',
          value: this.skipIds
        });
      expression = `(${expression}) AND skipCondition`;
    }
    console.log("this.additionalFilters: ", this.additionalFilters );
    if (this.additionalFilters && this.additionalFilters.length > 0) {
      const filterExpressions: string[] = [];
      this.additionalFilters.forEach((filter: any) => {
        filterConditions.push(filter);
        filterExpressions.push(filter.alias);
      });
      expression = `(${expression}) AND (${filterExpressions.join(" AND ")})`;
    }

    payload.where = {
      filterConditions: filterConditions,
      expression: expression,
    };
    return payload;
  }


  ngOnInit() {
    this.writeValue(this.preSelectedValue);
    this.preSelectedValue = {};

    // filter out skipIds list if it has null, undefined and empty string
    this.skipIds = this.skipIds.filter(
      (item) => item !== null && item !== undefined && item !== ""
    );
      this.searchTermSubject
      .pipe(
        debounceTime(500), // Wait for 500ms pause in typing
        distinctUntilChanged(), // Only continue if the search term changed
        switchMap(() => {
          if (this.clientSideSearch) {
            // Handle client-side filtering
            this.loading = false;
            return of(
              this.clientSearchData.filter((item: any) =>
                item[this.whereFields[0]].toLowerCase().includes(this.searchTerm.toLowerCase())
              )
            );
          } else {
            // Cancel previous API call and start a new one
            this.loading = true;
            return this.apiService
              .getSuggestions(this.apiUrl, this.getRequestPayload())
              .pipe(
                catchError((err) => {
                  console.error("Error fetching suggestions:", err);
                  this.loading = false;
                  return of([]); // Return an empty array if an error occurs
                })
              );
          }
        })
      )
      .subscribe((res: any) => {
        const newRecords = this.clientSideSearch ? res : res.data.records || [];
        if (this.page === 0) {
          this.suggestions = newRecords; // Initial load
        } else {
          this.suggestions = [...this.suggestions, ...newRecords]; // Append to existing data
        }
        //this.canPullMoreData = newRecords.length >= this.limit;
        this.canPullMoreData = !this.clientSideSearch ? newRecords.length >= this.limit : false; 
        this.loading = false;
        this.showSuggestions = true;
      });
  }

  // Handle lazy loading / pagination on scroll
  onScroll(event: any) {
    const element = event.target;
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight &&
      this.canPullMoreData
    ) {
      this.page++;
      this.fetchSuggestions();
    }
  }

  // calculateViewportHeight(resultCount: number): number {
  //   const itemHeight = 50; // Height of each result item in pixels
  //   const visibleItems = Math.min(resultCount, this.maxVisibleItems); // Calculate the number of visible items
  //   return visibleItems * itemHeight; // Set the viewport height dynamically
  // }

  // onScroll(): void {
  //   // Check if we can load more data and the loading is not already in progress
  //   if (this.canPullMoreData && !this.loading) {
  //     this.page++; // Increase the page number
  //     this.fetchSuggestions(); // Fetch more suggestions
  //   }
  // }

  updateSearchTerm() {
    const searchTerm = this.searchTerm;
    this.page = 0;
    if (!this.searchTerm.trim()) {
      this.value = null;
      this.onChange(null);
      this.searchCleared.emit();
      this.showSuggestions = false;
    } else {
      this.searchTermSubject.next(searchTerm);
    }
  }

  handleEnter(event: KeyboardEvent) {
    if (this.searchTerm === "  " && event.key === "Enter") {
      this.searchTerm = ""; // reset searchTerm
      this.page = 0;
      this.fetchSuggestions();
    }
  }

  select(result: any) {
    this.searchTerm = result[this.bindLabel].trim() || "";
    this.handleSelectionChange(result);
  }

  selectCustomInput() {
    const value = this.searchTerm.trim();
    this.value = {key: value, value: value};
    this.onChange(this.value);
    this.onTouched();
    this.showSuggestions = false;
    this.customInputSelected.emit(this.searchTerm);
  }

  // Handle the selection change in the autocomplete
  handleSelectionChange(selectedValue: any): void {
    this.value = selectedValue[this.bindValue];
    this.onChange({key: this.value, value: this.searchTerm});
    this.onTouched();
    this.showSuggestions = false;
    this.suggestionSelected.emit(selectedValue);
  }

  onInputFocus() {
    this.focusSearch = true;
    // if(this.value) {
    //   this.page = 0;
    //   this.fetchSuggestions();
    // }
    // if (this.suggestions.length > 0) {
    //   this.suggestions = [];
    //   this.updateSearchTerm();
    // }
  }

  onInputFocusOut() {
    this.focusSearch = false;
    console.log(this.value);
    console.log(this.searchTerm);
    if ((!this.allowCustomInput && !this.value) || !this.searchTerm.trim()) {
      this.onTouched()
      this.searchTerm = '';
    }
  }
}
