<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">Item Filing</div>
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="addNewRecord()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New Item
      </button>
    </div>
  </div>
</header>
<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by IGM No. or BL No. or Via No."
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>
  </div>
</div>

<div class="tos-table-template">
  <div class="table-container">
    <ng-container *ngIf="!tableLoaded">
      <app-no-data
        [msg]="
            'No items are available in the system. Please click on Add New Item button to create one.'
          "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableListData.records?.length > 0
        "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                    toggleAllRecordsOfCurrentPageSelections($event.target.checked)
                  "
            />
          </th>
          <!--            <th class="sl_no_th">Sl No.</th>-->
          <th
            class="igmNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'igmNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'igmNo')"
          >
            <div class="icon_cover" (click)="sortData('igmNo')">
              IGM Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="itemNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'itemNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'itemNo')"
          >
            <div class="icon_cover" (click)="sortData('itemNo')">
              Item Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="subItemNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'subItemNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'subItemNo')"
          >
            <div class="icon_cover" (click)="sortData('subItemNo')">
              Sub Item Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="igmDate_th sortable"
            [ngClass]="{ sorted: sortColumn === 'igmDate' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'igmDate')"
          >
            <div class="icon_cover" (click)="sortData('igmDate')">
              IGM Date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="blDate_th sortable"
            [ngClass]="{ sorted: sortColumn === 'blDate' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'blDate')"
          >
            <div class="icon_cover" (click)="sortData('blDate')">
              BL Date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="blNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'blNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'blNo')"
          >
            <div class="icon_cover" (click)="sortData('blNo')">
              BL Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="vesselId_th sortable"
            [ngClass]="{ sorted: sortColumn === 'vesselId' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'vesselId')"
          >
            <div class="icon_cover" (click)="sortData('vesselId')">
              Vessel ID
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="viaNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'viaNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'viaNo')"
          >
            <div class="icon_cover" (click)="sortData('viaNo')">
              Via Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="portOfLoading_th sortable"
            [ngClass]="{ sorted: sortColumn === 'portOfLoading' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'portOfLoading')"
          >
            <div class="icon_cover" (click)="sortData('portOfLoading')">
              Port of Loading
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="modifiedDate_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedDate' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'modifiedDate')"
          >
            <div class="icon_cover" (click)="sortData('modifiedDate')">
              Modified Date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th class="table_menu_th">
            <div class="icon_cover plus_icon">
              <app-svg-icon
                [path]="'assets/icons/plus_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'record_sl_no_' + record.id"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="igmNo_td" [title]="record.igmNo">
            <span [innerHTML]="record.igmNo | highlight : searchTerm"></span>
          </td>
          <td class="itemNo_td">
            {{ record.itemNo }}
          </td>
          <td class="subItemNo_td">
            {{ record.subItemNo }}
          </td>
          <td class="igmDate_td">
            {{ record.igmDate |  date : "dd/MM/yy"  }}
          </td>
          <td class="blDate_td">
            {{ record.blDate |  date : "dd/MM/yy"  }}
          </td>
          <td class="blNo_td" [title]="record.blNo">
            <span [innerHTML]="record.blNo | highlight : searchTerm"></span>
          </td>
          <td class="vesselId_td">
            {{ record.vesselId }}
          </td>
          <td class="viaNo_td" [title]="record.viaNo">
            <span [innerHTML]="record.viaNo | highlight : searchTerm"></span>
          </td>
          <td class="portOfLoading_td">
            {{ record.portOfLoading }}
          </td>
          <td class="modifiedDate_td">
            {{ record.modifiedDate | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableListData.records?.length === 0
        "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>

  <div class="pagination" [hidden]="tableListData.totalCount === 0">

    <div class="button_wrapper_left">
      <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>
  </div>
</div>
