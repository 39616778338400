import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function DateTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
          }
      
          const inputDate = new Date(control.value);
          const now = new Date();
      
          if (inputDate > now) {
            return { invalidDateTime: true };
          }
      
        return null;
    };
}
  