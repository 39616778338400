import { Component, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { DatePipe, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../../pipes/highlight.pipe";
import { NoDataComponent } from "../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";
import { SharedModule } from "../../../modules/shared/shared.module";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../../table-menu/table-menu.component";
import { UserListRequest } from "../../../models/user-list-request.interface";
import { User } from "../../../models/user.interface";
import { StatsData } from "../../../models/stats-response.interface";
import { PaginatedListDataResponse } from "../../../models/paginated-list-data-response";
import { TableListRequest } from "../../../models/table-list-request.interface";
import { LoadingService } from "../../../services/loading.service";
import { OperationService } from "../../../services/operations.service";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { ToasterService } from "../../../services/toaster.service";
import { ModalComponent } from "../../modal/modal.component";
import { AdvancedFilterComponent } from "../../advanced-filter/advanced-filter.component";

@Component({
  selector: "app-tally",
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    SharedModule,
    SvgIconComponent,
    TableMenuComponent,
    ResizableModule
  ],
  templateUrl: "./tally.component.html",
  styleUrl: "./tally.component.scss"
})
export class TallyComponent {
  tableLoaded: boolean = false;
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 10;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  selectedItems: any[] = [];
  sortColumn: string = "modifiedDate";
  sortDirection: "asc" | "desc" = "desc";
  allTableRecordsSelected: boolean = false;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "jobOrderNo",
      "documentNo",
      "documentType",
      "tallyType",
      "modifiedDate",
      "createdDate",
      "modifiedBy.name as modifiedBy",
      "createdBy.name as createdBy"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: "Edit", value: "edit" },
    { label: "View", value: "view" }
  ];

  // filter
  filterFields = [
    {
      displayName: "Job Order No",
      key: "jobOrderNo"
    },
    {
      displayName: "Document No",
      key: "documentNo"
    }
  ];
  isFilterApplied: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private loadingService: LoadingService,
              private operationService: OperationService,
              private toasterService: ToasterService,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.loadTableData();
  }

  goToAddRecordPage() {
    this.router.navigate(["add-new"], { relativeTo: this.route });
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length >= 3) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: "jobOrderNo",
            alias: "A",
            operator: "CONTAINS",
            value: [searchTerm]
          },
          {
            name: "documentNo",
            alias: "B",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "A OR B"
      };
      // Search when Enter is pressed
      // if (event.key === 'Enter') {
      this.searchTableList();
      // }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.searchTableList();
    }
    this.previousTerm = searchTerm;
  }

  searchTableList() {
    this.loadTableData()
  }

  filter() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = "Advanced Filter";
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput("filterFields", this.filterFields);
    if (this.tableListRequest.where) {
      filterDialogRef.setInput("appliedFilters", this.tableListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.refreshTableList();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression
          };
          this.isFilterApplied = true;
        } else {
          console.error("Invalid filter !");
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.refreshTableList();
      }
      modalRef.destroy();
    });
  }

  isAllRecordsOfCurrentPageSelected() {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: any) =>
          this.isSelected(item)
        ))
    );
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: any) {
    if (isChecked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortColumn = column;
      this.sortDirection = "asc";
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection
    };
    this.refreshTableList();
  }

  isSelected(item: any) {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }
  }

  handleMenuToggle(i: number) {
    this.openMenuIndex = this.openMenuIndex === i ? null : i;
  }

  handleOptionSelected(record: any, actionName: any) {
    console.log(record);
    const recordId = record.id;
    const navigationExtras: NavigationExtras = {
      relativeTo: this.route,
      state: { tallyType: record.tallyType }
    };
    if (actionName === 'edit') {
      this.router.navigate([`edit/${recordId}`], navigationExtras);
    } else if (actionName === 'view') {
      this.router.navigate([`view/${recordId}`],navigationExtras);
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.loadTableData();
    }
  }

  getDisplayedPages() {
    const total = this.totalTablePages;
    const current = this.currentTablePage;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push("...");
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }
    console.log(pages)

    return pages;
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.loadTableData();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.loadTableData();
    }
  }

  updateRowsPerPage(newRowsPerPage: any) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.loadTableData();
  }

  private loadTableData() {
    this.loadingService.show();
    this.operationService.listTally(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
        this.calculateTotalPages();
        this.tableLoaded = true;
        this.loadingService.hide();
      },
      error: (error: any) => {
        console.error(error.error.errorDesc);
        this.toasterService.error(error.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private calculateTotalPages() {
    this.totalTablePages = Math.ceil(
      this.tableListData.totalCount / this.rowsPerPage
    );
  }

  onResizeEnd(event: ResizeEvent, column: string) {
    if (event.edges.right) {
      const columnHeader = document.querySelector(`th.${column}_th`) as HTMLElement;
      const columnCells = document.querySelectorAll(`td.${column}_td`);
      const newWidth = `${event.rectangle.width}px`;

      if (columnHeader) {
        columnHeader.style.width = newWidth;
      }

      columnCells.forEach(cell => {
        const cellHtml = cell as HTMLElement;
        cellHtml.style.width = newWidth;
      });
    }
  }

  private clearSelection() {
    this.selectedItems = [];
  }

  private refreshTableList() {
    this.loadTableData();
  }
}
