import { Component, ViewChild, ViewChildren, Input, ViewContainerRef, ElementRef, QueryList } from '@angular/core';
import { DynamicFormService } from '../../../../../services/dynamic-form.service';
import { DynamicForm } from '../../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../../dynamic-form/dynamic-form.component';
import { ItemSelectorPanelComponent } from '../../../../item-selector-panel/item-selector-panel.component';
import { FormBuilder, FormGroup, FormArray, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JobOrderVendorComponent } from '../../vendor-form/job-order-vendor.component';
import { AccordionTableComponent } from '../../../../accordion-table/accordion-table.component';
import { JobOrderInventory, JobOrderItem } from '../../../../../models/job-orders.interface';
import { OperationService } from '../../../../../services/operations.service';
import { LoadingService } from '../../../../../services/loading.service';
import { ToasterService } from '../../../../../services/toaster.service';
import { ConfirmDialogV2Component } from '../../../../confirm-dialog/confirm-dialog-v2.component';
import { AutocompleteDropdownComponent } from '../../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { SvgIconComponent } from '../../../../svg-icon/svg-icon.component';
import { DatePipe } from '@angular/common'
import { JobOrderFormActions } from '../host/manage-jo-renderer.component';
import { StatsComponent } from '../../../../stats/stats.component';
import { Stats } from '../../../../../models/stats.interface';
import { InventoryType } from '../../../../../constants/gate-ops-constants';
import { convertEpochToISTDateTime, convertEpochToReadableISTDateTime } from '../../../../../utils/date-time-utils';
import { DateUtils } from '../../../../../utils/date-utils';

@Component({
  selector: 'app-lcl-destuff',
  standalone: true,
  imports: [DynamicFormComponent, AutocompleteDropdownComponent, AccordionTableComponent, StatsComponent, SvgIconComponent, FormsModule],
  templateUrl: './lcl-destuff.component.html',
  styleUrl: './lcl-destuff.component.scss',
  providers:[DatePipe]
})
export class LclDestuffComponent implements JobOrderFormActions {
  @Input() jobOrderType!: string;
  @Input() jobOrderId!: string;
  @Input() jobOrderConfig!: any;
  
  containerAdded: boolean = false;
  addButtonDisabled: boolean = true;
  searchText: string = '';
  jobOrderForm!: FormGroup;
  dynamicForm!: DynamicForm;
  itemTableHeaderAndData: any = {};
  itemList: any = [];
  selectedContainerId: string = '';
  selectedContainer: any;
  containerStats: Stats[] = [];
  jobOrderInventory!: JobOrderInventory;
  readOnlyMode:boolean = false;
  editMode: boolean = false;
  vendorMap: Map<string, any[]> =  new Map();

  private initialVendorIds: Set<string> = new Set();
  private sectionMap: Map<string, any> = new Map();
  loadedAccordianSet: Set<string> = new Set();
  protected readonly JobOrderVendorComponent = JobOrderVendorComponent;

  selectedTab: string = "basicDetailsSection";

  @ViewChild("basicDetailsSection") basicDetailsSection: ElementRef | undefined;
  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("vendorDetailsSection", { read: ElementRef }) vendorDetailsSection: ElementRef | undefined;
  @ViewChild('itemSelectorComponent', { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  @ViewChild(AccordionTableComponent) accordionTableComponent!: AccordionTableComponent;


  constructor (private dynamicFormService: DynamicFormService,
    private fb: FormBuilder,
    private api: OperationService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private datePipe: DatePipe
  ) {
    this.jobOrderForm = fb.group({
      itemDetails : this.fb.array([]),
    });
  }

  ngOnInit() {
    console.log(this.jobOrderConfig);
    this.dynamicForm = this.jobOrderConfig?.jobOrderDetailsFormElements;
    this.dynamicFormService.addControlsToForm(this.jobOrderForm, this.dynamicForm);
    this.itemTableHeaderAndData.headers = this.jobOrderConfig?.itemTableMeta;

    if (this.jobOrderId === "") {
      console.log("Job Order Id not found");
      this.readOnlyMode = false;
      this.editMode = false;
      this.setDefaultValues()
      return;
    }

    if (this.route.snapshot.url[0].path === "edit") {
      this.editMode = true;
      this.readOnlyMode = false;
    } else {
      this.editMode = false;
      this.readOnlyMode = true;
    }

    this.loadingService.show();
    this.loadJobOrderData(this.jobOrderId);
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  ngAfterViewInit() {
    if (this.basicDetailsSection) this.sectionMap.set("basicDetailsSection", this.basicDetailsSection.nativeElement);
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
  }

  onSuggestionSelected(containerId: string) {
    this.selectedContainerId = containerId;
    this.addButtonDisabled = false;
  }

  fetchAndAddItem() {
    this.fetchContainerAndItemData(this.selectedContainerId, (container) => {
      if (container) {
        this.addItem(container);
      }
    });
    this.searchText = '';
    this.addButtonDisabled = true;
  }

  addItem(container: any) {
    this.selectedContainer = container;
    this.itemList = container?.importItemList;
    this.itemTableHeaderAndData.data = this.itemList;
    this.containerAdded = true;
    this.createContainerStats(container);
    this.createJobOrderInventory(container);
  }

  private createContainerStats(container: any) {
    console.log("Container data => ", container);
    console.log("Container stats config => ", this.jobOrderConfig?.containerColumnMeta);
    this.containerStats = this.jobOrderConfig?.containerColumnMeta.map((statConfig: any) => ({
      displayLabel: statConfig.displayLabel,
      value: statConfig?.dataType === 'dateTime'
      ? convertEpochToReadableISTDateTime(container[statConfig.fieldName])
      : container[statConfig.fieldName]?.toString() || ''
    }));
  }

  private createJobOrderInventory(container: any) {
    this.jobOrderInventory = {
      inventoryId: container.id,
      inventoryNo: container.containerNo,
      inventoryType: InventoryType.CONTAINER 
    }
  }

  loadAccordionContent(event: {rowId: string, rowIndex: number}) {
    const rowIndex = event.rowIndex;
    if (this.loadedAccordianSet.has(event.rowId)) return;
    console.log("Inside loadAccordionContent: ", rowIndex);
    const itemInFocus = this.itemList[rowIndex];
    if (!this.vendorMap.has(itemInFocus.id)) {
      this.vendorMap.set(itemInFocus.id, []);
    }
    const vendorComponentInput = {
          index: rowIndex,
          itemDetails: itemInFocus,
          vendorList: this.vendorMap.get(itemInFocus.id),
          readOnly: this.readOnlyMode
    };
    this.accordionTableComponent.loadComponent(JobOrderVendorComponent, rowIndex, vendorComponentInput);
    this.loadedAccordianSet.add(event.rowId);
  }

  cancel() {
    this.confirmConcellation();
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/job-orders");
  }

  saveForm() {
    this.jobOrderForm.markAllAsTouched();
    
    const jobOrderRequest = this.createJobOrderRequest();
    console.log("Inside Save", jobOrderRequest);
    if (this.jobOrderForm.invalid) {
      this.scrollToFirstInvalidControl();
      this.printFormErrors(this.jobOrderForm);
      return;
    }
    if (!this.selectedContainer) {
      this.toasterService.error("Please select the container for destuffing");
      return;
    }
    if (this.itemList.length < 1) {
      this.toasterService.error("No items found");
      return;
    }

    if (this.editMode) {
        this.api.updateJobOrder(this.jobOrderId, jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    } else {
      this.api.saveJobOrder(jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    }
    
  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  createJobOrderRequest(): any {
    const jobOrderAdditionalInfo = this.jobOrderForm.value;
    //jobOrderAdditionalInfo.warehouseId = this.jobOrderForm.get('warehouseId')?.value?.key ?? null;
    //jobOrderAdditionalInfo.warehouseName = this.jobOrderForm.get('warehouseId')?.value?.value ?? null;

    const isJobOrderAdditionalInfoEmpty = Object.values(jobOrderAdditionalInfo).every(
      (value) => value === undefined || value === null || value === ''
    );
    const jobOrderVendorMap = Object.fromEntries(this.vendorMap);
    const deletedJobOrderVendorIds = this.getDeletedVendorIds();
    console.log(deletedJobOrderVendorIds);
    return {
      jobOrderType: this.jobOrderType,
      expiryDate: new Date(this.jobOrderForm.get('expiryDate')?.value).getTime(),
      jobOrderAdditionalInfo : isJobOrderAdditionalInfoEmpty ? null : jobOrderAdditionalInfo,
      jobOrderInventoryList: [this.jobOrderInventory],
      jobOrderVendorMap,
      deletedJobOrderVendorIds
    };
  }

  confirmConcellation() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? "
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  fetchContainerAndItemData(containerId: any, callback: (container: any | null) => void): void {
    this.api.fetchContainerById(containerId, true).subscribe({
      next: (res: any) => {
        callback(res.data);
      },
      error: (err) => {
        console.log(err);
        callback(null);
      },
    });
  }

  loadJobOrderData(jobOrderId: string) {
    let data = null;
    this.loadingService.show();
    this.api.getJobOrderById(jobOrderId, true, true, true, false).subscribe({
      next: (response: any) => {
        data = response?.data;
        this.hydrateForm(data);
        this.loadingService.hide();
      },
      error: (error) => {
        console.error(error);
        this.toasterService.error("Failed to get job order data");
        this.loadingService.hide();
      }
    });
  }

  hydrateForm(jobOrderData: any) {
    this.jobOrderInventory = jobOrderData?.jobOrderInventoryList.find(
      (inventory:any) => inventory.inventoryType === 'CONTAINER'
    );
    this.selectedContainerId = this.jobOrderInventory?.inventoryId;
    if (jobOrderData?.vendorDetails) {
      this.vendorMap = new Map(Object.entries(jobOrderData.vendorDetails));
      this.vendorMap.forEach((vendors) => {
        vendors.forEach((vendor: any) => {
          if (vendor.jovId) {
            this.initialVendorIds.add(vendor.jovId);
          }
        });
      });
    }
    this.addItem(jobOrderData?.containerList[0])
    this.jobOrderForm.patchValue({
      ...jobOrderData,
      ...jobOrderData?.additionalDetails,
      expiryDate: convertEpochToISTDateTime(jobOrderData?.expiryDate),
      issueDate: convertEpochToISTDateTime(jobOrderData?.issueDate),
      //warehouseId: {key: jobOrderData?.additionalDetails?.warehouseId, value: jobOrderData?.additionalDetails?.warehouseName}
    });

    if (this.readOnlyMode) {
      this.jobOrderForm.disable();
    }
  }

  getDeletedVendorIds(): string[] {
    const currentVendorIds = new Set<string>();
  
    this.vendorMap.forEach((vendors) => {
      vendors.forEach((vendor: any) => {
        if (vendor.jovId) {
          currentVendorIds.add(vendor.jovId);
        }
      });
    });
  
    const deletedVendorIds: string[] = [];
    this.initialVendorIds.forEach((jovId) => {
      if (!currentVendorIds.has(jovId)) {
        deletedVendorIds.push(jovId);
      }
    });
  
    return deletedVendorIds;
  }

  setDefaultValues() {
    this.jobOrderForm.get("issueDate")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  printFormErrors(formGroup: FormGroup | FormArray, path: string = ''): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = path ? `${path}.${key}` : key;
  
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.printFormErrors(control, controlPath);
      } else if (control && control.invalid) {
        console.log(`Control: ${controlPath}`, control.errors);
      }
    });
  }
}
