import {
  Component, ComponentRef,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { ItemSelectorPanelComponent } from "../../../item-selector-panel/item-selector-panel.component";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { JobOrderInventoryDetails } from "../../../../models/job-order-inventory-details.interface";
import { DatePipe, NgComponentOutlet, NgIf } from "@angular/common";
import { OperationService } from "../../../../services/operations.service";
import { LoadingService } from "../../../../services/loading.service";
import { CargoItem } from "../../../../models/cargo-item.interface";
import ExaminationTallyFormConfig from "../manage-tally/config/ExaminationTallyFormConfig.json";
import { DynamicFormService } from "../../../../services/dynamic-form.service";
import { DynamicForm } from "../../../../models/dynamic-form";
import { DynamicFormComponent } from "../../../dynamic-form/dynamic-form.component";
import { Container } from "../../../../models/container.interface";
import { NoDataComponent } from "../../../no-data/no-data.component";
import { StatsComponent } from "../../../stats/stats.component";
import { Stats } from "../../../../models/stats.interface";
import { ManageExaminationTallyComponent } from "./manage-examination-tally/manage-examination-tally.component";
import { getTallyTypeByJobOrderType, TallyType } from "../../../../constants/operations-contsants";
import { ManageTally } from "./manage-tally.interface";
import { ManageTallyDestuffingFclComponent } from "./manage-tally-destuffing-fcl/manage-tally-destuffing-fcl.component";
import { ManageTallyDestuffingLclComponent } from "./manage-tally-destuffing-lcl/manage-tally-destuffing-lcl.component";
import { ManageLclDeliveryTallyComponent } from "./manage-lcl-delivery-tally/manage-lcl-delivery-tally.component";
import { ManageSmtpStuffingTallyComponent } from "./manage-smtp-stuffing-tally/manage-smtp-stuffing-tally.component";
import {
  ManageTallyDestuffingFclTruckComponent
} from "./manage-tally-destuffing-fcl-truck/manage-tally-destuffing-fcl-truck.component";
import {
  ManageTallySmtpContainerDeliveryComponent
} from "./manage-tally-smtp-container-delivery/manage-tally-smtp-container-delivery.component";
import {
  ManageTallySmtpTruckMovementComponent
} from "./manage-tally-smtp-truck-movement/manage-tally-smtp-truck-movement.component";
import { ManageTallyRendererComponent } from "./manage-tally-renderer/manage-tally-renderer.component";

@Component({
  selector: "app-manage-tally",
  standalone: true,
  imports: [
    AutocompleteDropdownComponent,
    SvgIconComponent,
    ItemSelectorPanelComponent,
    ReactiveFormsModule,
    DatePipe,
    DynamicFormComponent,
    StatsComponent,
    ManageExaminationTallyComponent,
    NoDataComponent,
    NgComponentOutlet,
    NgIf,
    ManageTallyDestuffingFclComponent,
    ManageTallyDestuffingLclComponent,
    ManageLclDeliveryTallyComponent,
    ManageSmtpStuffingTallyComponent,
    ManageTallyDestuffingFclTruckComponent,
    ManageTallySmtpContainerDeliveryComponent,
    ManageTallySmtpTruckMovementComponent,
    ManageTallyRendererComponent
  ],
  templateUrl: "./manage-tally.component.html",
  styleUrl: "./manage-tally.component.scss"
})
export class ManageTallyComponent {
  readOnlyMode: boolean = false;
  editingEnabled: boolean = false;
  addNewMode: boolean = false;
  jobOrderInventoryDetails!: JobOrderInventoryDetails;
  tallyType!: any;
  jobOrderStats: Stats[] = [];

  @ViewChild("jobOrderInventorySearch") jobOrderInventorySearch!: AutocompleteDropdownComponent;
  @ViewChild("tallyForm") tallyForm!: ManageTally;

  constructor(private router: Router, private loadingService: LoadingService,public route:ActivatedRoute) {
  }

  ngOnInit(){
    const path = this.route.snapshot?.url[0]?.path;

    switch (path) {
      case "add-new":
        this.addNewMode = true;
        this.readOnlyMode = false;
        this.editingEnabled = false;
        break;
      case "edit":
        this.editingEnabled = true;
        this.readOnlyMode = false;
        this.addNewMode = false;
        break;
      case "view":
        this.readOnlyMode = true;
        this.editingEnabled = false;
        this.addNewMode = false;
        break;
      default:
        this.addNewMode = false;
        this.readOnlyMode = false;
        this.editingEnabled = false;
        break;
    }

    this.tallyType = Object.values(TallyType).find(type => type.code === history.state.tallyType);

    // this.route.queryParams.subscribe(params => {
    //   const tallyTypeParam = params['tallyType'];
    //   this.tallyType = Object.values(TallyType).find(type => type.code === tallyTypeParam);
    // });
  }

  goBack() {
    if (!this.tallyForm) {
      this.router.navigateByUrl("/manage-operations/import/tally");
      return;
    }
    this.tallyForm.cancel();
  }


  submitForm() {
    console.log(this.tallyForm);
    if (!this.tallyForm)
      return;
    // console.log(this.examinationTallyForm.value);
    this.tallyForm.save();
  }

  jobOrderSelected(selectedJobOrder: JobOrderInventoryDetails) {
    // this.loadingService.show();
    this.jobOrderInventorySearch.searchTerm = "";

  /*  if (this.jobOrderInventoryDetails?.jobOrderId === selectedJobOrder?.jobOrderId) // if same job order is selected again
      return;*/

    console.log("#selectedJO", selectedJobOrder);
    this.jobOrderInventoryDetails = selectedJobOrder;
    this.tallyType = getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType);
    this.jobOrderStats = this.createJobOrderStats(selectedJobOrder);
  }

  private createJobOrderStats(selectedJobOrder: any) {
    return [{
      displayLabel: "Job Order No.",
      value: selectedJobOrder?.jobOrderNo?.toString()
    },
      {
        displayLabel: "Job Order Type",
        value: selectedJobOrder?.jobOrderType.toString()
      },
      {
        displayLabel: "Issue Date",
        value: selectedJobOrder?.issueDate,
        dateType: "date",
        iconUrl: "assets/icons/calendar_icon.svg"
      },
      {
        displayLabel: "Validity Date",
        value: selectedJobOrder?.dueDate,
        dateType: "date"
      }

    ];
  }

  protected readonly TallyType = TallyType;
}
