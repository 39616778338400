import { Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { SharedModule } from "../../../../modules/shared/shared.module";
import { ApiService } from "../../../../services/api.service";
import { LoadingService } from "../../../../services/loading.service";
import { ToasterService } from "../../../../services/toaster.service";


import dynamicFormChargeConfig from "../config/ChargeFormConfig.json";
import { DynamicFormService } from "../../../../services/dynamic-form.service";
import { DynamicFormComponent } from "../../../dynamic-form/dynamic-form.component";
import { DynamicForm } from "../../../../models/dynamic-form";
import { Charge } from "../../../../models/charge.interface";


@Component({
  selector: "app-manage-charge",
  standalone: true,
  imports: [SharedModule, MatSelectModule, DynamicFormComponent],
  templateUrl: './manage-charge.component.html',
  styleUrl: './manage-charge.component.scss'
})
export class ManageChargeComponent {

  form!: FormGroup;
  dynamicForm!: DynamicForm;

  determinants: any = [];

  editingEnabled = false;

  // readOnlyMode = true;
  // masterEditingEnabled = false;
  // masterForm: FormGroup;
  chargeId: string = "";

  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  private destroy$ = new Subject<void>();

  constructor(
    private dynamicFormService: DynamicFormService,
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    private api: ApiService,
    private toasterService: ToasterService,
    private route: ActivatedRoute
  ) {
    // this.form = fb.group({});
    this.form = this.fb.group({
      itemDetails: this.fb.array([]),
      // determinants: this.fb.array([]),
      active: [true], // Default to true
    });
  }

  private setupComponentMode(): void {
    if (this.chargeId) {
      const path = this.route.snapshot.url[0]?.path || "";
      this.editingEnabled = path === "edit";
    } else {
      this.editingEnabled = false;

      // Reset the form and determinants if not editing
      this.resetFormAndDeterminants();
    }
  }

  private resetFormAndDeterminants(): void {
    this.form.reset({
      ...this.form.value, // Preserve current values
      active: true,       // Default to true
      determinants: [],   // Ensure determinants are cleared
    });
  
    const multiSelectField = this.dynamicForm?.formRows
      .flatMap(row => row.formGroups)
      .find((formGroup: any) => formGroup?.control?.label === 'determinants');
  
    if (this.hasFieldInfo(multiSelectField)) {
      multiSelectField.control.fieldInfo.options = [];
    }
  }



  cancel() {
    this.router.navigateByUrl('/manage-tariff/charges');
  }

  // ca(): void {
  //   this.router.navigateByUrl("/manage-masters/banks");
  // }

  save() {
    if (this.form.valid) {
      const formValue = this.form.value;

      if (formValue.determinants) {
        formValue.determinants = formValue.determinants.map((det: any) => det.value); // Extract the `value` property
      }


      this.loadingService.show();


      const action$ = this.editingEnabled
        ? this.api.updateCharge(formValue, this.chargeId)
        : this.api.createCharge(formValue);

      // const action$ = this.api.createCharge(formValue);
      console.log("action is " + action$)

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = "Charge Succesfully Created";
          this.toasterService.success(message);
          this.loadingService.hide();
          this.cancel();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || "An error occurred");
          console.error("Error submitting form:", err);
          this.loadingService.hide();
        }
      });
    } else {
      this.markFormGroupTouched(this.form);
      this.scrollToFirstInvalidControl();
    }
  }

  ngOnInit() {
    this.dynamicForm = dynamicFormChargeConfig;
    this.dynamicFormService.addControlsToForm(this.form, this.dynamicForm);
  
    console.log('Initial form value after initialization:', this.form.value);
  
    // Fetch determinants for dropdown options
    this.api.getDeterminants().subscribe({
      next: (res: any) => {
        this.determinants = res.data;
        this.addDeterminantsToDropdown();
      },
    });
  
    this.chargeId = this.route.snapshot.paramMap.get("chargeId") || "";
    console.log('Charge ID:', this.chargeId);
    this.setupComponentMode();
  
    if (this.chargeId) {
      this.loadChargeData();
    }
  }

  private addDeterminantsToDropdown(): void {
    const multiSelectField = this.dynamicForm?.formRows
      .flatMap(row => row.formGroups)
      .find((formGroup: any) => formGroup?.control?.label === 'determinants');
  
    if (this.hasFieldInfo(multiSelectField)) {
      const options = multiSelectField.control.fieldInfo.options;
  
      // Add determinants to dropdown options
      this.determinants.forEach((det: any) => {
        if (!options.find((option: any) => option.value === det.name)) {
          options.push({ label: det.name, value: det.name });
        }
      });
  
      console.log('Dropdown options after API call:', options);
    }
  }


  private loadChargeData(): void {
    this.loadingService.show();
    this.api.getChargesById(this.chargeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const chargeData: Charge = res.data;
          this.patchFormWithChargeData(chargeData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error("Error loading charge data:", err);
          this.toasterService.error("Failed to load charge data");
          this.loadingService.hide();
        }
      });
  }

  private patchFormWithChargeData(charge: Charge): void {
    this.form.patchValue({
      ...charge,
      active: charge.active !== undefined ? charge.active : true,
    });
  
    console.log('Form value after patching:', this.form.value);
  
    // Populate determinants field only for edit flow
    if (charge.determinants && Array.isArray(charge.determinants)) {
      const multiSelectField = this.dynamicForm?.formRows
        .flatMap(row => row.formGroups)
        .find((formGroup: any) => formGroup?.control?.label === 'determinants');
  
      if (this.hasFieldInfo(multiSelectField)) {
        const options = multiSelectField.control.fieldInfo.options;
  
        charge.determinants.forEach((det: string) => {
          // Add determinant to options if it doesn't exist
          if (!options.find((option: any) => option.value === det)) {
            options.push({ label: det, value: det });
          }
        });
  
        // Set the determinants in the form
        this.form.patchValue({
          determinants: charge.determinants.map(det => ({ label: det, value: det }))
        });
      }
    }

  }

  // Type guard to confirm existence of fieldInfo and options
  private hasFieldInfo(formGroup: any): formGroup is { control: { fieldInfo: { options: any[] } } } {
    return !!formGroup?.control?.fieldInfo?.options;
  }

  handleItemAddition(item: any) {

    if (!this.editingEnabled) return; // Skip addition for new charges

  const multiSelectField = this.dynamicForm?.formRows
    .flatMap(row => row.formGroups)
    .find((formGroup: any) => formGroup?.control?.label === 'determinants');

  if (this.hasFieldInfo(multiSelectField)) {
    const options = multiSelectField.control.fieldInfo.options;

    // Add the new item to the options if it doesn't already exist
    if (!options.find((option: any) => option.value === item.name)) {
      options.push({ label: item.name, value: item.name });
    }

    // Get the current selected values from the form
    const currentSelections = this.form.get('determinants')?.value || [];

    // Add the new item to the selected values
    this.form.patchValue({
      determinants: [...currentSelections, { label: item.name, value: item.name }]
    });
  }
  }

  // submitForm() {
  //   this.form.markAllAsTouched();
  //   console.log(this.form.value);
  //   if (!this.form.valid) {
  //     console.log("Form is invalid");
  //     return;
  //   }
  // }


  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains("ng-invalid");
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  // loadAccordionContent(rowIndex: number) {
  //   this.accordionTableComponent.loadComponent(FileUploadDialogComponent)
  // }
}

