import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output, SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { LeftPanelEntityConfig } from "./config/left-panel-config";
import { AutocompleteDropdownComponent } from "../autocomplete-dropdown/autocomplete-dropdown.component";
import { OperationService } from "../../services/operations.service";
import { ToasterService } from "../../services/toaster.service";
import entityConfigMap from "./config/left-panel-config.json";
import { NoDataComponent } from "../no-data/no-data.component";

@Component({
  selector: "app-item-selector-panel",
  standalone: true,
  imports: [FormsModule, CommonModule, SvgIconComponent, AutocompleteDropdownComponent],
  templateUrl: "./item-selector-panel.component.html",
  styleUrl: "./item-selector-panel.component.scss"
})
export class ItemSelectorPanelComponent {

  @Input() entity: string = "CARGO";
  @Input() allowedItemSize: number = -1;
  @Input() searchConfig!: any;
  @Input() listReadOnly: boolean = false;
  @Input() items: any = [];
  @Input() itemsTitle: string = "Cargo Items";
  @Input() validateBeforeItemAddition: boolean = false;

  @Output() onItemAddition = new EventEmitter<{ item: any, index: number }>();
  @Output() onItemDeletion = new EventEmitter<any>();
  @Output() onItemSelection = new EventEmitter<{ item: any, index: number }>();
  @Output() onItemAdditionRequest = new EventEmitter<any>();
  @Output() onCheckBoxSelection = new EventEmitter<any>();

  typedEntityConfigMap: Record<string, LeftPanelEntityConfig> = entityConfigMap;
  entityConfig: any = {};
  searchText: string = "";
  selectedItemId: string = "";
  disableAddButton: boolean = true;
  selectedRow: any = null;
  selectedRows: Set<string> = new Set();
  addedItemSet: Set<string> = new Set();


  @ViewChild("rightPaneContent", { read: ViewContainerRef, static: false })
  rightPaneContent!: ViewContainerRef;

  constructor(private api: OperationService,
              private toasterService: ToasterService
  ) {

  }

  get addedItems() {
    return this.items;
  }

  ngOnInit() {
    this.entityConfig = this.typedEntityConfigMap[this.entity];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["items"]) {
        this.clearItemSelectorComponent();
      if (!this.selectedRow && changes["items"].currentValue.length > 0) {
        this.items.forEach((item: any) => this.addedItemSet.add(item?.id));
        this.selectItem(this.items[0], 0);
      }
    }
  }

  handleSearch() {
    console.log("Searching for:", this.searchText);
  }

  clearItemSelectorComponent() {
    this.rightPaneContent?.clear();
    // this.items = [];
    this.selectedItemId = "";
    this.selectedRow = null;
  }

  onSuggestionSelected(selectedId: string) {
    this.selectedItemId = selectedId;
    console.log("Inside onSuggestionSlected: ", this.items.length, this.allowedItemSize);
    this.disableAddButton = (this.items.length === this.allowedItemSize);
    console.log("Inside onSuggestionSlected: ", this.disableAddButton);
  }

  addItem() {
    if (this.selectedItemId && !this.addedItemSet.has(this.selectedItemId)) {
      this.fetchItemByCallingAPI(this.selectedItemId, (result) => {
        if (result) {

          if (this.validateBeforeItemAddition) {
            this.onItemAdditionRequest.emit(result);
          } else {
            this.addValidatedItem(result);
            const index = this.items.length - 1;
            this.onItemAddition.emit({ item: result, index });
            // this.items.push(result);
            // this.addedItemSet.add(result[this.entityConfig["idField"]]);
            // const index = this.items.length - 1;
            // this.onItemAddition.emit({ item: result, index: index });

            if (!this.selectedRow) {
              this.selectItem(result, index);
              this.selectedRows.add(result?.id);
            }
          }
        }
      });
    }
    this.selectedItemId = "";
    this.searchText = "";
    this.disableAddButton = true;
  }

  addValidatedItem(item: any) {
    this.items.push(item);
    this.addedItemSet.add(item[this.entityConfig["idField"]]);
    if (this.validateBeforeItemAddition) {
      const index = this.items.length - 1;
      this.onItemAddition.emit({ item, index });
      if (!this.selectedRow) {
        this.selectItem(item, index);
        this.selectedRows.add(item?.id);
      }
    }
  }

  onDeleteItem(index: number): void {
    console.log("Inside onDeleteItem:", index);
    const deletedItem = this.items[index];
    this.items.splice(index, 1);
    this.selectedRows.delete(deletedItem.id);
    this.addedItemSet.delete(deletedItem.id);

    this.onItemDeletion.emit({ item: deletedItem, index });

    if (this.items.length == 0) {
      this.loadDefaultComponent();
      this.selectedRow = null;
    } else if (deletedItem.id === this.selectedRow?.id) {
      if (index === this.items.length) {
        this.selectItem(this.items[index], 0);
      } else {
        this.selectItem(this.items[index], index);
      }
    }
  }

  isRowSelected(item: any) {
    return this.selectedRow && this.selectedRow?.id === item?.id;
  }

  toggleCheckboxSelection(item: any, index: number) {
    if (this.selectedRows.has(item[this.entityConfig["idField"]])) {
      this.selectedRows.delete(item[this.entityConfig["idField"]]);
    } else {
      this.selectedRows.add(item[this.entityConfig["idField"]]);
    }

    this.selectItem(item, index);
    console.log("selected row count: ", this.selectedRows.size);
    this.onCheckBoxSelection.emit(this.selectedRows);
  }

  selectItem(item: any, index: number) {
    console.log("Inside selectItem:", index);
    if (!this.listReadOnly) { //only call api when list is not readonly
      const selectedId = item[this.entityConfig["idField"]];
      if (!this.addedItemSet.has(selectedId)) {
        console.log("Calling API to fetch an item");
        this.fetchItemByCallingAPI(selectedId, (result) => {
          if (result) {
            item = result;
            this.addedItemSet.add(result[this.entityConfig["idField"]]);
          }
        });
      }
    }
    this.selectedRow = item;
    this.onItemSelection.emit({ item, index });
  }

  fetchItemByCallingAPI(id: string, callback: (container: any | null) => void) {
    const modifiedAPI = this.entityConfig.fetchApi.replace("{id}", id);
    this.api.getData(modifiedAPI).subscribe({
      next: (res: any) => {
        callback(res.data);
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        callback(null);
      }
    });
  }

  isItemSelected(item: any) {
    return this.  selectedRow[this.entityConfig["idField"]] === item[this.entityConfig["idField"]]
  }

  loadDefaultComponent() {
    this.loadComponents([{ component: NoDataComponent, data: { msg: "No Item selected" } }]);
  }

  loadComponents(components: Array<{ component: Type<any>, data?: Partial<any> }>): Array<ComponentRef<any>> {
    const componentRefs: Array<ComponentRef<any>> = [];
    if (this.rightPaneContent) {
      this.rightPaneContent.clear();
      components.forEach(({ component, data }) => {
        const componentRef = this.rightPaneContent.createComponent(component);
        componentRef.location.nativeElement.style.width = "100%";
        if (componentRef.instance) {
          if (data) {
            Object.assign(componentRef.instance, data);
          }
        }
        componentRefs.push(componentRef);
      });
    } else {
      console.error("rightPaneContent is not available.");
    }
    return componentRefs;
  }
}
