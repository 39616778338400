import { Component, Input } from "@angular/core";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { Stats } from "../../models/stats.interface";
import { DatePipe, NgClass, NgStyle } from "@angular/common";

@Component({
  selector: 'app-stats',
  standalone: true,
  imports: [
    SvgIconComponent,
    DatePipe,
    NgClass,
    NgStyle
  ],
  templateUrl: './stats.component.html',
  styleUrl: './stats.component.scss'
})
export class StatsComponent {
  @Input() stats: Stats[] = [];
  @Input() title: string = ""
  @Input() headerIconUrl: string = ""
  @Input() headerIconBgColor: string = "rgba(245, 243, 255, 1)"
  @Input() headerIconColor: string = "rgba(159, 26, 177, 1)"

}
