import { Component, Input, EventEmitter, Output } from "@angular/core";
import { AutocompleteDropdownComponent } from "../../autocomplete-dropdown/autocomplete-dropdown.component";
import { FormGroup, ReactiveFormsModule, ValidationErrors } from "@angular/forms";
import { Control } from "../../../models/dynamic-form";
import { ToggleComponent } from "../../common/toggle/toggle.component";
import { SelectComponent } from "../../select/select.component";
import { ChipInputComponent } from "../../common/chip-input/chip-input.component";
import { group } from "@angular/animations";
import { NgClass, NgIf } from "@angular/common";
import { MultiSelectComponent } from "../../select/multi-select.component";

@Component({
  selector: 'app-dynamic-input',
  standalone: true,
  imports: [
    AutocompleteDropdownComponent,
    ReactiveFormsModule,
    ToggleComponent,
    SelectComponent,
    ChipInputComponent,
    MultiSelectComponent,
    NgClass,
    NgIf
  ],
  templateUrl: './dynamic-input.component.html',
  styleUrl: './dynamic-input.component.scss'
})
export class DynamicInputComponent {
  @Input() control!: Control;
  @Input() form!: FormGroup;

  @Output() onAutoCompleteSuggestionSelected = new EventEmitter<{controlName: string, result: any}>();
  @Output() searchTextCleared = new EventEmitter<any>();


  ngOnInit() {
  }

  onSearchSuggestionSelect(result: any) {
    this.onAutoCompleteSuggestionSelected.emit({controlName: this.control.label, result})
  }

  isControlDisabled(): boolean {
    return this.form.get(this.control.label)?.disabled ?? false;
  }

  onAutoCompleteSearchTextCleared() {
    this.searchTextCleared.emit(this.control.label);
  }
}
