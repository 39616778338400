export const StorageKeys = {
  MENU_ITEMS: 'menuItems',
  USER_PROFILE: 'userProfile'
} as const;


export const CycleType = {
  IMPORT: {value: "Import", key: "IMPORT"},
  EXPORT: {value: "Export", key: "EXPORT"},
  EMPTY: {value: "Empty", key: "EMPTY"},
  BOND: {value: "Bond", key: "BOND"}
} as const;
export type Cycle = typeof CycleType[keyof typeof CycleType];
