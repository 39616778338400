<div class="accordian-container">
  
    @if (!readOnly && searchConfig) {
      <div class="search-container">
          <app-autocomplete-dropdown
            id="search-bar"
            class="autocomplete-dropdown"
            [placeholder]="searchConfig?.searchPlaceholder"
            [apiUrl]="searchConfig?.apiUrl"
            [selectFields]="searchConfig?.selectFields"
            [whereFields]="searchConfig.whereFields"
            [limit]="10"
            [orderBy]="searchConfig.orderBy"
            [bindLabel]="searchConfig.bindLabel"
            [bindValue]="searchConfig.bindValue"
            [displayColumns]="searchConfig.displayColumns"
            [additionalFilters]="searchConfig?.additionalFilters"
            [clientSideSearch]="searchConfig?.clientSideSearch"
            [clientSearchData]="searchConfig?.clientSearchData"
            [(ngModel)]="searchText"
            (suggestionSelected)="onSuggestionSelected($event)"
        >
        </app-autocomplete-dropdown>
        <button type="button" class="add-button secondary_button" [disabled]="disableAddButton" (click)="addItem()">
            <app-svg-icon class="image-icon"
                        [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
            <span>Add</span>
        </button>
      </div>
  }

  <mat-accordion [multi]="false" class="table-like-accordion">
    <div class="table-header">
      <div class="cb_th">
        <input type="checkbox">
      </div>
      @for (header of tableHeaderAndData.headers; track $index) {
        <div class="table-header-cell">{{ header.displayName }}</div>
      }
      <div class="table-header-cell">
        <div class="icon_cover plus_icon">
          <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
        </div>
      </div>
       <div *ngIf = "!readOnly" class="delete-item-cell">
        <span></span>
      </div>
    </div>
    @for (data of tableHeaderAndData.data; track data.id) {
      <mat-expansion-panel hideToggle="true" class="table-row"
                           [ngClass]="{ 'active_row': expandedRowIndex === $index }"
                           (opened)="accordionOpened($index)"
                           (closed)="accordionClosed($index)">
        <mat-expansion-panel-header class="table-row-header">
          <div class="cb_td">
            <input type="checkbox" (click)="$event.stopPropagation()">
          </div>
          @for (header of tableHeaderAndData.headers; track $index) {
            <div class="table-cell">
              {{ header.dataType === 'dateTime' ? convertEpochToISTDateTime(data[header.fieldName]) : data[header.fieldName] }}
            </div>
          }
          <div class="table-cell">
              <span class="add_info_btn">
                <div class="icon_container">
                  <app-svg-icon
                    [path]="expandedRowIndex === $index
                      ? 'assets/icons/chevron_up.svg'
                      : 'assets/icons/chevron_down.svg'"
                    class="icon"
                  ></app-svg-icon>
                </div>
              </span>
          </div>
           <div *ngIf = "!readOnly" class="delete-item-cell">
            <app-svg-icon class="delete-icon" 
                    [path]="'assets/icons/delete_icon.svg'" 
                    (click)="deleteItem($index); $event.stopPropagation()"
                ></app-svg-icon>
          </div>
  
        </mat-expansion-panel-header>
        <ng-template #accordionContent matExpansionPanelContent></ng-template>
      </mat-expansion-panel>
    }
  </mat-accordion>  
</div>

