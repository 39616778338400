import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { ManageTally } from "../manage-tally.interface";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { EditableTableHeader } from "../../../../../models/editable-table-header.interface";
import { JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import { Stats } from "../../../../../models/stats.interface";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import SmtpStuffingTallyFromJson from "../config/SMTPStuffingTally.json";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { StatsComponent } from "../../../../stats/stats.component";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { EditableTableComponent } from "../../../../editable-table/editable-table.component";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";
import { StatsService } from "../../../../../services/stats.service";
import { Container } from "../../../../../models/container.interface";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { JobOrderInventory } from "../../../../../models/job-orders.interface";
import { ToasterService } from "../../../../../services/toaster.service";
import { Tally } from "../../../../../models/tally.interface";
import { Router } from "@angular/router";
import { getTallyTypeByJobOrderType } from "../../../../../constants/operations-contsants";
import { forkJoin, Subscription } from "rxjs";
import { ConfirmDialogV2Component } from "../../../../confirm-dialog/confirm-dialog-v2.component";

@Component({
  selector: "app-manage-smtp-stuffing-tally",
  standalone: true,
  imports: [
    ItemSelectorPanelComponent,
    StatsComponent
  ],
  templateUrl: "./manage-smtp-stuffing-tally.component.html",
  styleUrl: "./manage-smtp-stuffing-tally.component.scss"
})
export class ManageSmtpStuffingTallyComponent implements ManageTally {
  private subscription!: Subscription;
  dataLoaded: boolean = false;

  smtpStuffingTallyFormJson!: DynamicForm;
  smtpStuffingTallyForm!: FormGroup;
  editableConfig: EditableTableHeader[] = [];

  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;

  jobOrderInventoryList: JobOrderInventory[] = [];
  containerList: Container[] = [];
  cargoList: CargoItem[] = [];
  selectedItems: any[] = [];
  savedTallyData: any[] = [];
  containerAndCargoSelectedMap: Map<string, any[]> = new Map<string, any[]>();

  containerAndCargoMap = new Map<string, any[]>;  //maintains the mapping for containerId and cargoList, send the same to api
  originalRemainingNOPMap = new Map<string, number>(); //tracks remaining nop for each item
  deliveredNopMapByContainer = new Map<string, Map<string, number>>();

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;
  @Input() jobOrderStats!: Stats[];

  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;

  constructor(private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private statsService: StatsService,
              private changeDetectorRef: ChangeDetectorRef,
              private toasterService: ToasterService,
              private router: Router,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.smtpStuffingTallyFormJson = SmtpStuffingTallyFromJson.smtpStuffingTallyFormElements;
    this.editableConfig = SmtpStuffingTallyFromJson.editableTableConfig;
    this.smtpStuffingTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {
      this.loadingService.show();
      forkJoin([
        this.operationService.getJobOrderDetails(this.jobOrderInventoryDetails?.jobOrderId, true, true, true, true),
        this.operationService.listTalliesByJobOrder(encodeURIComponent(this.jobOrderInventoryDetails?.jobOrderNo))
      ]).subscribe({
        next: ([jobOrderDetailsResponse, talliesResponse]: [any, any]) => {
          this.handleJobOrderDetailsResponse(jobOrderDetailsResponse,talliesResponse);
          this.loadingService.hide();
        },
        error: (error: any) => {
          this.loadingService.hide();
          console.log(error);
        }
      });

    }
  }

  get itemFormArray() {
    return this.smtpStuffingTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: any; index: number }) {
    this.changeDetectorRef.detectChanges();
    this.addTallyDetailsFromArrayForContainers(event.item);

    const formGroup = this.itemFormArray.at(event.index) as FormGroup;
    this.patchSavedTallyData(event, formGroup);
    this.setRequiredValueForFromGroup(formGroup, event);

    const componentsToLoad = this.prepareComponentsToLoad(event, formGroup);
    this.checkAndAddVendors(event, componentsToLoad);
    const componentRefs = this.itemSelectorComponent?.loadComponents(componentsToLoad);

    const editableTableComponentRef = componentRefs?.find(ref => ref.instance instanceof EditableTableComponent) as ComponentRef<EditableTableComponent>;
    this.handleEditableTableUpdates(editableTableComponentRef, event);
    this.handleEditableTableSelectedRowsChanges(editableTableComponentRef, event);
  }


  save(): void {
    if (!this.filterAndValidateTallyForm()) return;
    console.log(this.smtpStuffingTallyForm.value);
    const tallyData = this.prepareTallyData();
    console.log(tallyData);
    this.saveTally(tallyData);
  }

  cancel(): void {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? ";
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  handleCheckBoxSelection(event: any) {
    this.selectedItems = Array.from(event);
  }

  private patchSavedTallyData(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    if (this.savedTallyData?.length === 0) return;

    const filteredTallyData = this.savedTallyData.filter((tally: {
      inventoryId: string;
    }) => tally.inventoryId === event.item.inventoryId);

    if (filteredTallyData?.length === 0) return;
    const filteredTallyDataMap = new Map(filteredTallyData.map((tally: any) => [tally.cargoId, tally]));

    formGroup.patchValue({
      ...filteredTallyData[0],
      startTime: this.formatDateTimeForInput(filteredTallyData[0]?.startTime),
      endTime: this.formatDateTimeForInput(filteredTallyData[0]?.endTime)
    });
    formGroup.disable();

    this.containerAndCargoMap.get(event.item.inventoryId)?.forEach((cargo: any) => {
      if(!filteredTallyDataMap.has(cargo.id)) return;
      const tally = filteredTallyDataMap.get(cargo.id);
      cargo.deliveryNOP = tally.itemPackagesLoaded;
      cargo.deliveryGrossWt = tally.itemWeightLoaded;
      cargo.deliveryGrossVolume = tally.itemVolumeLoaded;
      cargo.disabled = true;
    });

  }

  private handleJobOrderDetailsResponse(res: any,savedTallyResponse: any) {
    const data: JobOrderDetails = res?.data;
    this.jobOrderInventoryList = data.jobOrderInventoryList
      .filter((inventory: any) => inventory.inventoryType === "CONTAINER")
      .map((filteredInv: any) => {
      return {
        ...filteredInv,
        disableCheckBox: !!savedTallyResponse.data.find((tally: { inventoryId: string }) => tally.inventoryId === filteredInv.inventoryId)
      };
    });
    this.jobOrderVendorsGroupByInventoryId = data.vendorDetails;
    this.containerList = data.containerList;
    this.cargoList = data.cargoList;
    this.savedTallyData = savedTallyResponse?.data;
    this.populateContainerAndCargoMap(data);
    this.populateOriginalRemainingNOPMap(data);
    this.dataLoaded = true;
  }

  private handleEditableTableUpdates(editableTableComponentRef: ComponentRef<EditableTableComponent>, event: {
    item: any;
    index: number
  }) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = editableTableComponentRef.instance.dataUpdated.subscribe((data) => {
      const containerId = event.item.inventoryId;
      // console.log("Data Row",data.row);
      this.containerAndCargoMap.set(event.item.joInvId, data.value);
      this.updateCargoDetails(data, containerId);
    });
  }

  private handleEditableTableSelectedRowsChanges(editableTableComponentRef: ComponentRef<EditableTableComponent>, event: {
    item: any;
    index: number
  }) {
    editableTableComponentRef.instance.selectedRowsSubject.subscribe((selectedRows: any[]) => {
      this.containerAndCargoSelectedMap.set(event.item.inventoryId, selectedRows);
    });
  }

  private updateCargoDetails(editableTableRes: any, containerId: string) {
    const cargo = editableTableRes.rowInfo;
    editableTableRes.inputElement.setCustomValidity("");
    const deliveryNOP = Number(cargo.deliveryNOP) || 0;
    // const originalNOP = cargo.numberOfPackages || 0;

    if (!this.deliveredNopMapByContainer.has(cargo.igmItemNo)) {
      this.deliveredNopMapByContainer.set(cargo.igmItemNo, new Map());
    }

    const vehicleMap = this.deliveredNopMapByContainer.get(cargo.igmItemNo);
    vehicleMap?.set(containerId, deliveryNOP);

    const totalDeliveredNOP = Array.from(vehicleMap?.values() ?? []).reduce((acc, val) => acc + val, 0);

    const originalRemainingNop = this.originalRemainingNOPMap.get(cargo.igmItemNo) ?? 0;
    const remainingNOP: number | undefined = totalDeliveredNOP !== 0 ? originalRemainingNop - totalDeliveredNOP : originalRemainingNop;

    if (remainingNOP < 0) {
      editableTableRes.inputElement.setCustomValidity("NOP loaded cannot exceed the manifested NOP");
      editableTableRes.inputElement.reportValidity();
      return;
    }

    this.containerAndCargoMap.forEach((cargoList) => {
      cargoList.forEach((cargoItem: any) => {
        if (cargoItem.igmItemNo !== cargo.igmItemNo) return;

        cargoItem.remainingPackages = remainingNOP;
        cargoItem.disabled = remainingNOP <= 0 && (cargoItem.deliveryNOP === undefined || cargoItem.deliveryNOP === "");

      });
    });
  }

  private prepareComponentsToLoad(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    const componentsToLoad: any = [
      {
        component: StatsHorizontalComponent,
        data: { stats: this.statsService.createContainerStatsForTally(this.containerList.filter((container) => container.containerNo === event.item.inventoryNo)[0]) }
      },
      { component: DynamicFormComponent, data: { formConfig: this.smtpStuffingTallyFormJson, formGroup: formGroup } },
      {
        component: EditableTableComponent, data: {
          title: "Item information",
          tableHeaders: this.editableConfig,
          selectedRowsInput: this.containerAndCargoSelectedMap.get(event.item.inventoryId),
          tableData: this.containerAndCargoMap.get(event.item.inventoryId)
        }
      }
    ];
    return componentsToLoad;
  }

  private setRequiredValueForFromGroup(formGroup: FormGroup<any>, event: { item: any; index: number }) {
    formGroup.get("inventoryId")?.setValue(event.item.inventoryId);
    formGroup.get("inventoryNo")?.setValue(event.item.inventoryNo);
  }

  private checkAndAddVendors(event: { item: any; index: number }, componentsToLoad: any) {
    if (this.jobOrderVendorsGroupByInventoryId[event.item?.inventoryId]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.inventoryId] }
      });
    }
  }

  private addTallyDetailsFromArrayForContainers(associatedItems: Container | undefined) {
    this.dynamicFormService.addToFormArray(this.smtpStuffingTallyForm, "tallyDetails", this.smtpStuffingTallyFormJson);
  }

  private populateContainerAndCargoMap(data: JobOrderDetails) {
    this.jobOrderInventoryList.forEach((inventory: any) => {
      const filterCargoList = inventory.additionalDetails.selectedItems.map((item: any) => {
        return this.cargoList.filter(cargo => cargo.igmItemNo === item.igmItemNo);
      }).flat();
      this.containerAndCargoMap.set(inventory.inventoryId,
        JSON.parse(JSON.stringify(filterCargoList.map((cargo: CargoItem) => {
          // cargo.remainingNumberOfPackages = cargo.numberOfPackages;
          cargo.disabled = Number(cargo.remainingPackages) === 0;
          return cargo;
        })))
      );
    });
  }

  private filterAndValidateTallyForm(): boolean {
    const tallyDetailsFormGroup = this.smtpStuffingTallyForm.get("tallyDetails") as FormArray;
    if (tallyDetailsFormGroup.length === 0 || this.selectedItems.length === 0) {
      this.toasterService.error("Please select at least one container to save the tally");
      return false;
    }

    this.smtpStuffingTallyForm.markAllAsTouched();
    const invalidSelectedItems = this.itemFormArray.controls
      .filter((control) => this.selectedItems.includes(control.get("inventoryId")?.value))
      .some((control) => control.invalid);

    if (invalidSelectedItems) {
      //TODO: logic to go through all selectedItems and show the error notifications on the left panel
      this.toasterService.error("Please fill all required fields to save the tally");
      console.log("Form is invalid", this.smtpStuffingTallyForm);
      return false;
    }

    //check all selected items have deliveryNOP
    if (this.containerAndCargoSelectedMap.size === 0) {
      this.toasterService.error("Please select at least one item to save the tally");
      return false;
    }
    for (const selectedCargoList of this.containerAndCargoSelectedMap.values()) {
      for (const cargo of selectedCargoList) {
        if (cargo.deliveryNOP === "" || cargo.deliveryNOP === undefined) {
          this.toasterService.error("Delivery NOP is required for all selected items");
          return false;
        }
      }
    }

    return true;
  }

  private saveTally(tallyData: Tally) {
    this.loadingService.show();
    this.operationService.saveTally(tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide();
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  private prepareTallyData() {
    return {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      tallyType: getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType)?.code,
      tallyItems: this.smtpStuffingTallyForm.value.tallyDetails
        .filter((detail: any) => this.selectedItems.includes(detail.inventoryId))
        .map((detail: any) => {
          const cargoList = this.containerAndCargoMap.get(detail.inventoryId) || [];
          return cargoList.filter((cargo) => {
            return this.containerAndCargoSelectedMap.get(detail.inventoryId)?.map((selectedCargo: any) => selectedCargo.igmItemNo).includes(cargo.igmItemNo);
          }).map((cargo: any) => ({
            ...detail,
            cargoId: cargo.id,
            documentNo: cargo.igmItemNo,
            itemPackagesLoaded: Number(cargo.deliveryNOP),
            itemWeightLoaded: cargo.deliveryGrossWt,
            itemVolumeLoaded: cargo.deliveryGrossVolume,
            type: "stuffing_tally"
          }));
        }).flat()

    };
  }

  private populateOriginalRemainingNOPMap(data: JobOrderDetails) {
    data.cargoList.forEach((cargo: any) => { //TODO: Set cargo datatype to CargoItem
      this.originalRemainingNOPMap.set(cargo.igmItemNo, cargo.remainingPackages);
    });
  }

  private formatDateTimeForInput(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // This will give you YYYY-MM-DDThh:mm
  }
}
