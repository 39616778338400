export interface JobOrderVendor {
    id?:string,
    inventoryId?:string,
    vendorId?:string,
    serviceId?:string,
    equipmentId?:string
}

export interface JobOrderInventory {
    joInvId?:string,
    jobOrderNo?:string,
    inventoryId:string,
    inventoryNo:string,
    inventoryType?:string
    documentType?:string,
    additionalDetails?:any
}

export interface JobOrderItem {
    id?: string,
    igmItemNo?: string,
    consigneeId?:string,
    shippingLineId?:string,
    commodityId?:string,
    expectedExamination?:number,
    chaAadhaarNo?:string,
    chaContactNo?:string,
    chaBCHHAPassNo?:string,
    associatedItems?:string[],
    additionalDetails?:any
}


export const allowedJobOrderAdditionalInfoKeys = [
    'noOfVehicles',
    'vehicleNos',
    'destuffTo',
    'warehouseId',
    'warehouseName',
    'transporterId',
    'transporterName',
    'location',
    'expectedExamination'
];

export interface JobOrderInventoryInfo {
    requiresExamination?: boolean | false
    sealCutReason?: string
}