<div class="sidebar_expand">
  <div class="expanded_nav_items">

    <div class="container">
      <div class="expanded_nav_header">
        <div class="text">{{ selectingMenu ? selectingMenu?.displayLabel : selectedMenu.displayLabel }}</div>
      </div>
      <!-- @for (child of selectingMenu ? selectingMenu?.childResources : selectedMenu?.childResources; track child.id) {
        <div class="navigation">
          <a class="sub_nav" [class.active]="isActive(child.id)" [routerLink]="child.resourceProperties.url">

              <div class="content">
                <div class="text">{{ child.displayLabel }}</div>
              </div>

          </a>
        </div>
      } -->

      <ng-container *ngFor="let group of groupedChildResources">
        <div *ngIf="group.category !== 'noCategory'" class="category-header first-level-items">
          {{ group.category }}
        </div>

        <div *ngFor="let child of group.items" [class.active]="isActive(child.id)" class="navigation second-level-items">
          <a
            class="sub_nav"
            [routerLink]="child.resourceProperties.url"
          >
            <div class="content">
              <div class="text">{{ child.displayLabel }}</div>
            </div>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="itemsWithOutCategory.length">
        <div *ngFor="let child of itemsWithOutCategory" class="navigation first-level-items" [class.active]="isActive(child.id)">
            <a
              class="sub_nav"
              [routerLink]="child.resourceProperties.url"
            >
              <div class="content">
                <div class="text">{{ child.displayLabel }}</div>
              </div>
            </a>
        </div>
      </ng-container>
    </div>



    <!--    <div class="navigation">
          <div class="sub_nav">
            <div class="nav-item-base">
              <div class="content">
                <div class="dot">
                </div>
                <div class="arrow-narrow-left">
                </div>
                <div class="text">User Management</div>
              </div>
              <div class="badge">
              </div>
              <img class="chevron-up-icon" alt="" src="assets/icons/chevron_up.svg">
            </div>
            <div class="menu">
              <div class="nav-item-base1">
                <div class="content2">
                  <div class="text2">Users</div>
                </div>
              </div>
              <div class="nav-item-base2">
                <div class="content2">
                  <div class="text2">Roles & Permissions</div>
                </div>
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
            </div>
          </div>
        </div>
        <div class="navigation">
          <div class="sub_nav">
            <div class="sub_nav_item">
              <div class="content">
                <div class="dot">
                </div>
                <div class="arrow-narrow-left">
                </div>
                <div class="text">Other Nav Item</div>
              </div>
              <div class="badge">
              </div>
              <img class="chevron-up-icon1" alt="" src="assets/icons/chevron_down.svg">
            </div>
            <div class="menu1">
              <div class="nav-item-base1">
                <div class="content2">
                  <div class="text2">Users</div>
                </div>
              </div>
              <div class="nav-item-base2">
                <div class="content2">
                  <div class="text2">Roles & Permissions</div>
                </div>
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
              <div class="nav-item-base3">
              </div>
            </div>
          </div>
        </div>-->
  </div>
</div>


