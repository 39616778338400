<div class="container_poc">
  <div class="header">
    <div class="page_header">
      <div class="left">
        <div class="title">Dynamic Form POC</div>
        <div class="sub_heading">Fill in Item Details and add Container Information</div>
      </div>
      <div class="right">
        <button class="secondary_button">Cancel</button>
        <button class="primary_button" (click)="submitForm()">Submit</button>
      </div>
    </div>
  </div>

<app-dynamic-form
  [formConfig]="dynamicForm"
  [formGroup]="form">
>
</app-dynamic-form>

  <app-accordion-table
    [tableHeaderAndData]= "tableHeaderAndData"
    (onAccordionOpen)="loadAccordionContent($event)"
  ></app-accordion-table>
</div>


