<div class="container">
    <header class="header">
        <div class="header-content">
            <div class="header-text">
                <div class="title">Gate In - Truck</div>
                <div class="subtitle">Fill in truck information to gate in</div>
            </div>
            <div class="header-actions">
                <button class="btn secondary_button" (click)="resetForm()">Reset Form</button>
                <button class="btn primary_button" (click)="saveForm()">Save</button>
            </div>
        </div>
    </header>
    <div class="body">
        <div class="form-container">
            <form [formGroup]="gateInForm">
              <div class="form-row">
                  <div class="form-group">
                      <label for="cycle" class="form-label">
                      Cycle<span class="required">*</span>
                      </label>
                      <select id="cycle" formControlName="cycle" class="form-select" required (change)="onCycleChange($event)" >
                          <option value="" disabled>Select Cycle</option>
                          <option *ngFor="let option of cycleOptions" [value]="option.key">{{ option.value }}</option>
                      </select>
                      <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('cycle')?.invalid && gateInForm.get('cycle')?.touched}">
                          <small *ngIf="gateInForm.get('cycle')?.errors?.['required']">Please select the cycle</small>
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="gateInMode" class="form-label">
                        Gate-In Mode<span class="required">*</span>
                      </label>
                      <select id="gateInMode" formControlName="gateInMode" class="form-select" required>
                          <option value="">Select Mode</option>
                          <option *ngFor="let option of gateInOptions" [value]="option.key">{{ option.label }}</option>
                      </select>
                      <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('gateInMode')?.invalid && gateInForm.get('gateInMode')?.touched}">
                          <small *ngIf="gateInForm.get('gateInMode')?.errors?.['required']">Please select the mode</small>
                      </div>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label for="laneNo" class="form-label">
                    Lane No<span class="required">*</span>
                  </label>
                  <select id="laneNo" formControlName="laneNo" class="form-select" required>
                      <option value="" disabled>Select Lane</option>
                      <option *ngFor="let option of laneOptions" [value]="option.key">{{ option.value }}</option>
                  </select>
                  <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('laneNo')?.invalid && gateInForm.get('laneNo')?.touched}">
                      <small *ngIf="gateInForm.get('laneNo')?.errors?.['required']">Lane No is required</small>
                  </div>
                </div>
                <div class="form-group">
                    <label for="gateInTime" class="form-label">
                      Gate In Time<span class="required">*</span>
                    </label>
                    <input id="gateInTime" type="datetime-local" formControlName="gateInTime" class="form-input" [max]="maxDateTime" required/>
                    <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('gateInTime')?.invalid && gateInForm.get('gateInTime')?.touched }">
                        <small *ngIf="gateInForm.get('gateInTime')?.errors?.['required']">Lane No is required</small>
                        <small *ngIf="gateInForm.get('gateInTime')?.errors?.['invalidDateTime']">Gate In time cannot be in future</small>
                    </div>
                  </div>
              </div>
              <div class="checkbox-row">
                <div class="checkbox-group">
                    <app-checkbox
                        checkboxId="ownVehicle"
                        label="Own Vehicle"
                        formControlName="ownVehicle"
                        (checkedChange)="onOwnVehicleChange($event)">
                    </app-checkbox>
                    <app-checkbox
                        checkboxId="ownMovement"
                        label="Own Movement"
                        [isChecked]="isOwnMovement"
                        [isDisabled]="isOwnMovementDisabled"
                        formControlName="ownMovement"
                        (checkedChange)="onOwnMovementChange($event)"
                    >
                    </app-checkbox>
                </div>
              </div>
              
              <div class="form-row">
                  <div class="form-group">
                      <label for="truckNo" class="form-label">
                        Truck No<span class="required">*</span>
                      </label>
                      <input
                          *ngIf="!ownVehicle"
                          type="text"
                          id="truckNo"
                          formControlName="truckNo"
                          class="form-input"
                          [(ngModel)] = "selectedTruckNo"
                          required
                          placeholder="Enter Truck No."
                      />
                      
                      <app-autocomplete-dropdown
                      *ngIf="ownVehicle"
                          id="truckNo"
                          formControlName="truckNo"
                          class = "autocomplete-dropdown"
                          [placeholder]="'Search Truck'"
                          [apiUrl]="'v1/trailers/list'"
                          [selectFields]="['id', 'trailerNumber', 'vendorId.id as vendorId', 'vendorId.name as vendorName', 'vehicleTypeId.id as vehicleTypeId', 'vehicleTypeId.generalPurposeType as vehicleTypeValue']"
                          [whereFields]="['trailerNumber']"
                          [limit]="10"
                          [orderBy]="{ trailerNumber: 'asc' }"
                          [filterByKey]="'trailerNumber'"
                          [bindLabel]="'trailerNumber'"
                          [bindValue]="'id'"
                          [displayColumns]="[
                              { key: 'trailerNumber', header: 'Truck No' },
                              { key: 'vendorName', header: 'Transporter Name' },
                          ]"
                          (suggestionSelected) = "onTruckSelected($event)"
                          >
                          </app-autocomplete-dropdown>
                      <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('truckNo')?.invalid && gateInForm.get('truckNo')?.touched}">
                          <small *ngIf="gateInForm.get('truckNo')?.errors?.['required']">Truck No is required</small>
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="transporterId" class="form-label">
                        Transporter<span class="required">*</span>
                      </label>
                      <app-autocomplete-dropdown
                          id="transporterId"
                          class = "autocomplete-dropdown"
                          formControlName="transporterId"
                          [placeholder]="'Select Transporter'"
                          [apiUrl]="'v1/vendors/list'"
                          [selectFields]="['id', 'name']"
                          [whereFields]="['name']"
                          [limit]="10"
                          [orderBy]="{ name: 'asc' }"
                          [bindLabel]="'name'"
                          [bindValue]="'id'"
                          [displayColumns]="[
                              { key: 'name', header: 'Name' },
                          ]"
                          >
                      </app-autocomplete-dropdown>
                      <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('transporterId')?.invalid && gateInForm.get('transporterId')?.touched}">
                          <small *ngIf="gateInForm.get('transporterId')?.errors?.['required']">Transporter Name is required</small>
                      </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group">
                      <label for="truckTypeId" class="form-label">Truck Type<span class="required">*</span></label>
                      <app-autocomplete-dropdown
                        #truckTypeDropdown
                          id="truckTypeId"
                          name="truckTypeId"
                          formControlName="truckTypeId"
                          class = "autocomplete-dropdown"
                          [placeholder]="'Select Truck Type'"
                          [apiUrl]="'v1/vehicle-types/list'"
                          [selectFields]="['id', 'generalPurposeType',]"
                          [whereFields]="['generalPurposeType']"
                          [limit]="10"
                          [orderBy]="{ generalPurposeType: 'asc' }"
                          [filterByKey]="'generalPurposeType'"
                          [bindLabel]="'generalPurposeType'"
                          [bindValue]="'id'"
                          [disable]="gateInForm.disabled"
                          [displayColumns]="[
                              { key: 'generalPurposeType', header: 'Vehicle Type' },
                          ]"
                          >
                      </app-autocomplete-dropdown>
                      <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('truckTypeId')?.invalid && gateInForm.get('truckTypeId')?.touched}">
                        <small *ngIf="gateInForm.get('truckTypeId')?.errors?.['required']">Truck Type is required</small>
                    </div>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label for="driverName" class="form-label">
                    Driver Name<span class="required">*</span>
                  </label>
                  <input type="text" id="driverName" formControlName="driverName" class="form-input" value="Vessel" placeholder="Enter Driver Name" required 
                  [ngClass]="{
                      form_error:
                        gateInForm.get('driverName')?.invalid &&
                        gateInForm.get('driverName')?.touched &&
                        gateInForm.get('driverName')?.errors
                    }"
                  />
                  <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('driverName')?.invalid && gateInForm.get('driverName')?.touched}">
                      <small *ngIf="gateInForm.get('driverName')?.errors?.['required']">Driver Name is required</small>
                  </div>
              </div>
                <div class="form-group">
                  <label for="driverLicenseNo" class="form-label">
                    Driver License No<span class="required">*</span>
                  </label>
                  <input type="text" id="driverLicenseNo" formControlName="driverLicenseNo" class="form-input" value="Vessel" placeholder="Enter Driver License no" required 
                  [ngClass]="{
                      form_error:
                        gateInForm.get('driverLicenseNo')?.invalid &&
                        gateInForm.get('driverLicenseNo')?.touched &&
                        gateInForm.get('driverLicenseNo')?.errors
                    }"
                  />
                  <div class="form_validation_error" [ngClass]="{form_error: gateInForm.get('driverLicenseNo')?.invalid && gateInForm.get('driverLicenseNo')?.touched}">
                      <small *ngIf="gateInForm.get('driverLicenseNo')?.errors?.['required']">Driver License No is required</small>
                  </div>
              </div>
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label for="driverName" class="form-label">
                    Job Order No
                  </label>
                  <app-autocomplete-dropdown
                    id="jobOrderNo"
                    formControlName="jobOrderNo"
                    class = "autocomplete-dropdown"
                    [placeholder]="'Select Job Order'"
                    [apiUrl]="'v1/job-order-inventories/list?onlyOpenJobOrders=true'"
                    [selectFields]="['jobOrderNo',
                        'jobOrderNo.id as jobOrderId',
                        'jobOrderNo.expiryDate as expiryDate',
                        'jobOrderNo.createdDate as issueDate',
                        'inventoryId',
                        'inventoryNo',
                        'jobOrderNo.jobOrderType as jobOrderType']"
                    [whereFields]="['jobOrderNo','inventoryNo']"
                    [limit]="10"
                    [orderBy]="{ jobOrderNo: 'asc' }"
                    [filterByKey]="'jobOrderNo'"
                    [bindLabel]="'jobOrderNo'"
                    [bindValue]="'id'"
                    [displayColumns]="[
                                { key: 'jobOrderNo', header: 'Job Order Number' },
                                { key: 'inventoryNo', header: 'Inventory Number' },
                                { key: 'jobOrderType', header: 'Job Order Type' }]"
                    (suggestionSelected) = "onJobOrderSelection($event)"
                    (searchCleared) = "clearJobOrderSelection()"
                    >
                    </app-autocomplete-dropdown>

                    <div class="banner" *ngIf="selectedJobOrder">
                        <app-job-order-banner [issueDate]="selectedJobOrder?.issueDate" [expiryDate]="selectedJobOrder?.expiryDate">
                        </app-job-order-banner>
                    </div>
                </div>
              </div>
            </form>
          </div>
    </div>
</div>




