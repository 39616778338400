import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { StatsComponent } from "../../../../stats/stats.component";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { Container } from "../../../../../models/container.interface";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { Stats } from "../../../../../models/stats.interface";
import { JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { Tally } from "../../../../../models/tally.interface";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import DestuffingTallyFormConfig from "../config/DestuffingTallyFormConfig.json";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { Router } from "@angular/router";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";
import { ConfirmDialogV2Component } from "../../../../confirm-dialog/confirm-dialog-v2.component";
import { getTallyTypeByJobOrderType, TallyType } from "../../../../../constants/operations-contsants";
import { InventoryType } from "../../../../../constants/gate-ops-constants";
import { ManageTally } from "../manage-tally.interface";
import { StatsService } from "../../../../../services/stats.service";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { ToasterService } from "../../../../../services/toaster.service";
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-manage-tally-destuffing-fcl',
  standalone: true,
  imports: [
    ItemSelectorPanelComponent,
    StatsComponent
  ],
  templateUrl: './manage-tally-destuffing-fcl.component.html',
  styleUrl: './manage-tally-destuffing-fcl.component.scss'
})
export class ManageTallyDestuffingFclComponent implements ManageTally{
  cargoItem!: CargoItem;
  containerList: Array<Container> | undefined = []
  dataLoaded: boolean = false;
  destuffingTallyForm!: FormGroup;
  destuffingTallyFormConfig!: DynamicForm;
  inventoryDetailStats: Stats[] = [];
  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;
  tallyData!: Tally;
  savedTallyData: any[] = [];
  selectedItems: any[] = [];

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;
  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @Input() jobOrderStats!: Stats[];

  constructor(private router: Router,
              private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private viewContainerRef: ViewContainerRef,
              private statsService: StatsService,
              private toasterService: ToasterService,
              private changeDetectorRef: ChangeDetectorRef) {
  }



  ngOnInit() {
    this.loadingService.show()
    console.log("Destuffing FCL tally");
    this.destuffingTallyFormConfig = DestuffingTallyFormConfig.destuffingTallyFormElement;
    this.destuffingTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {
      this.destuffingTallyForm = this.fb.group({
        tallyDetails: this.fb.array([])
      });

      this.loadingService.show();
      forkJoin([
        this.operationService.getJobOrderDetails(this.jobOrderInventoryDetails?.jobOrderId, true, true, true),
        this.operationService.listTalliesByJobOrder(encodeURIComponent(this.jobOrderInventoryDetails?.jobOrderNo))
      ]).subscribe({
        next: ([jobOrderDetailsResponse, talliesResponse]: [any, any]) => {
          const data: JobOrderDetails = jobOrderDetailsResponse?.data;
          this.cargoItem = data?.cargoList[0];
          this.containerList = data.cargoList[0]?.associatedContainerList?.map((container) => {
            return {
              ...container,
              disableCheckBox: !!talliesResponse.data.find((tally: {
                inventoryId: string
              }) => tally.inventoryId === container.id)
            };
          });
          this.inventoryDetailStats = this.statsService.createInventoryDetailStatsForTally(this.cargoItem);

          this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;
          this.dataLoaded = true;
          this.loadingService.hide();

          // Handle talliesResponse if needed
          this.savedTallyData = talliesResponse.data;
        },
        error: (error: any) => {
          this.loadingService.hide();
          console.log(error);
        }
      });
    }
  }

  private addTallyDetailsFromArrayForAssociatedContainers(associatedContainers: Container | undefined) {
      this.dynamicFormService.addToFormArray(this.destuffingTallyForm, "tallyDetails", this.destuffingTallyFormConfig);
  }

  get itemFormArray() {
    return this.destuffingTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: Container, index: number }) {
    // this.createContainerStats(event.item);
    this.changeDetectorRef.detectChanges();
    this.addTallyDetailsFromArrayForAssociatedContainers(event.item);
    const formGroup = this.itemFormArray.at(event.index) as FormGroup
    this.patchSavedTallyData(event, formGroup);

    formGroup.get("inventoryId")?.setValue(event.item.id);
    formGroup.get("inventoryNo")?.setValue(event.item.containerNo);
    formGroup.get("cargoId")?.setValue(this.jobOrderInventoryDetails.inventoryId);
    formGroup.get("documentNo")?.setValue(this.jobOrderInventoryDetails.inventoryNo);
    const componentsToLoad: any = [
      { component: StatsHorizontalComponent, data: { stats: this.statsService.createContainerStatsForTally(event.item) } },
      { component: DynamicFormComponent, data: { formConfig: this.destuffingTallyFormConfig, formGroup} }
    ];

    if (this.jobOrderVendorsGroupByInventoryId[event.item?.id]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.id] }
      });
    }

    this.itemSelectorComponent?.loadComponents(componentsToLoad);
  }

  private patchSavedTallyData(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    if (this.savedTallyData?.length === 0) return;

    const filteredTallyData = this.savedTallyData.filter((tally: { inventoryId: string; }) => tally.inventoryId === event.item.id);

    if (filteredTallyData?.length === 0) return;

    formGroup.patchValue({
      ...filteredTallyData[0],
      startTime: this.formatDateTimeForInput(filteredTallyData[0]?.startTime),
      endTime: this.formatDateTimeForInput(filteredTallyData[0]?.endTime)
    });
    formGroup.disable();
  }

  private formatDateTimeForInput(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // This will give you YYYY-MM-DDThh:mm
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  cancel(): void {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? ";
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  save(): void {
    // if (!this.examinationTallyForm.valid) {
    //   console.log("Form is invalid");
    //   return;
    // }
    if(!this.filterAndValidateTallyForm()) return

    console.log(this.destuffingTallyForm.value)
    this.tallyData = {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      // tallyType: getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType).code,
      tallyType: "DOCK_DESTUFF_TALLY",
      tallyItems: this.destuffingTallyForm.value.tallyDetails
        .filter((detail: { inventoryId: any; }) => this.selectedItems.includes(detail.inventoryId))
        .map((detail: any) => ({
        ...detail,
        type: "destuffing_tally",
        inventoryType: InventoryType.CONTAINER
      }))
    };
    console.log(this.tallyData);
    this.saveTally();
  }

  handleCheckBoxSelection(event: any) {
    this.selectedItems = Array.from(event)
  }

  private filterAndValidateTallyForm(): boolean {
    const tallyDetailsFormGroup = this.destuffingTallyForm.get("tallyDetails") as FormArray;
    if (tallyDetailsFormGroup.length === 0 || this.selectedItems.length === 0) {
      this.toasterService.error("Please select at least one item to save the tally");
      return false;
    }

    this.destuffingTallyForm.markAllAsTouched();
    const invalidSelectedItems = this.itemFormArray.controls
      .filter((control) => this.selectedItems.includes(control.get("inventoryId")?.value))
      .some((control) => control.invalid);

    if (invalidSelectedItems) {
      //TODO: logic to go through all selectedItems and show the error notifications on the left panel
      this.toasterService.error("Please fill all required fields to save the tally");
      console.log("Form is invalid", this.destuffingTallyForm);
      return false;
    }

    return true;
  }

  private saveTally() {
    this.loadingService.show()
    this.operationService.saveTally(this.tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide()
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc)
        this.loadingService.hide()
      }
    });
  }
}
