import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators, FormArray } from "@angular/forms";
import { Control, DynamicForm, FormConditions, Rule } from "../models/dynamic-form";
import { group } from "@angular/animations";
import { AutocompleteDropdownComponentValidator } from '../validators/autoCompleteValidator';
import { selectValidator } from '../validators/select-validator';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  constructor(
    private fb: FormBuilder
  ) { }

  /*generateForm(config: DynamicForm): FormGroup {
    const generatedFormGroup = this.fb.group({});

    config.formRows
      .flatMap(row => row.formGroups)
      .forEach(group => {
        const control = group.control;
        const validators = this.getValidators(control.validators || []);
        generatedFormGroup.addControl(control.label, this.fb.control({
          value: control?.defaultValue !== undefined ? control.defaultValue : '',
          disabled: control.disabled || false
        }, validators));
      });

    return generatedFormGroup;
  }*/

  addControlsToForm(formGroup: FormGroup, config: DynamicForm, overrideExisting: boolean = false): FormGroup {
    config.formRows
        .flatMap(row => row.formGroups)
        .forEach(group => {
            const control = group.control;
            
            const isHidden = control.hidden ?? false;
            const isConditionalVisible = !(!!control.conditions?.visibility) || this.evaluateCondition(control.conditions.visibility, formGroup);
            const isRequired = isConditionalVisible && (this.isControlRequired(control) || (!!control.conditions?.visibility && this.evaluateCondition(control.conditions?.required, formGroup)));
            const isDisabled = (control?.disabled ?? false) || (!!control.conditions?.disabled && this.evaluateCondition(control.conditions?.disabled, formGroup));
            const validators = isConditionalVisible && !isHidden ? this.getValidators(control.validators || []).concat(isRequired ? Validators.required : []) : [];

            if (formGroup.contains(control.label)) {
              if (overrideExisting) {
                  formGroup.removeControl(control.label);
              }
          }
          formGroup.addControl(
            control.label,
            this.fb.control({
                value: control?.defaultValue !== undefined ? control.defaultValue : control?.fieldInfo?.dataType === 'multi-select' ? [] : null,
                disabled: isDisabled,
            }, validators)
          );
        });
        return formGroup;
  }

  isControlRequired(control: Control) {
    return control.validators?.some(validator => validator.type === 'required');
  }

  addToFormArray(formGroup: FormGroup, formArrayName: string, config: DynamicForm): void {
    const formArray = this.getOrCreateFormArray(formGroup, formArrayName);
    const sectionGroup = this.fb.group({});
    this.addControlsToForm(sectionGroup, config);
    formArray.push(sectionGroup);
  }

  addControlsToFormArray(formArray: FormArray, config: DynamicForm): void {
    const sectionGroup = this.fb.group({});
    this.addControlsToForm(sectionGroup, config);
    formArray.push(sectionGroup);
  }

  createEmptyFormGroup() {
    return this.fb.group({});
  }

  addControlToFormGroup(formGroup: FormGroup, formControlName: string, defaultValue: any, disabled: boolean = false, validators: ValidatorFn[] | undefined) {
    formGroup.addControl(
      formControlName,
      this.fb.control({
          value: defaultValue !== undefined ? defaultValue : '',
          disabled: disabled,
      }, validators || [])
  );
  }

  getOrCreateFormArray(formGroup: FormGroup, name: string): FormArray {
    if (!formGroup.get(name)) {
      formGroup.addControl(name, this.fb.array([]));
    }
    return formGroup.get(name) as FormArray;
  }

  getValidators(validators: { type: string; message: string }[]): ValidatorFn[] {
    const validatorFns: ValidatorFn[] = [];

    const validatorMap: { [key: string]: ValidatorFn } = {
      'required': Validators.required,
      'email': Validators.email,
      'minLength': Validators.minLength(3),
      'maxLength': Validators.maxLength(50),
      'autocomplete': AutocompleteDropdownComponentValidator(),
      'selectRequired': selectValidator(),
    };

    validators.forEach(validator => {
      const validatorFn = validatorMap[validator.type];
      if (validatorFn) {
        validatorFns.push(validatorFn);
      }
    });

    return validatorFns;
  }

  evaluateCondition(condition: FormConditions['visibility' | 'required' | 'disabled'], formGroup: FormGroup): boolean {
    if (!condition || !condition.rules || condition.rules.length === 0) {
      return true;
    }
  
    const results = condition.rules.map((rule: Rule) => {
      const dependentControl = formGroup.get(rule.field);
      if (!dependentControl) return false;
      const dependentValue = dependentControl.value;
      switch (rule.operator) {
        case 'EQUALS':
          return dependentValue === rule.value;
        case 'NOT_EQUALS':
          return dependentValue !== rule.value;
        case 'GREATER_THAN':
          return dependentValue > rule.value;
        case 'LESS_THAN':
          return dependentValue < rule.value;
        case 'IN':
          return Array.isArray(rule.value) && rule.value.includes(dependentValue);
        case 'NOT_IN':
          return Array.isArray(rule.value) && !rule.value.includes(dependentValue);
        default:
          return false;
      }
    });
  
    return condition.logic === 'AND' ? results.every(Boolean) : results.some(Boolean);
  }

}

