import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GateInRequest } from "../models/gate-in-request.interface";
import { TableListRequest } from "../models/table-list-request.interface";

const containerInventoryEndPoint = "v1/containers"
const vehicleInventoryEndPoint = "v1/inventory/trailers"

@Injectable({
  providedIn: "root"  // Ensures the service is available app-wide
})
export class InventoryService {
    private baseUrl: string = environment.apiBaseUrl;

    constructor(private http: HttpClient) {
    }

    getContainerList(tableListRequest: TableListRequest) {
        return this.http.post(`${this.baseUrl}/${containerInventoryEndPoint}/list`, tableListRequest);
    }

    getVehicleList(tableListRequest: TableListRequest) {
        return this.http.post(`${this.baseUrl}/${vehicleInventoryEndPoint}/list`, tableListRequest);
    }

}