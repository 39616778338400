<div class="container">
    <header class="header">
        <div class="header-content">
            <div class="header-text">
                <div class="title">Gate Out - Container</div>
            </div>
            <div class="header-actions">
                <button class="btn secondary_button" (click)="resetForm()">Reset Form</button>
                <button class="btn primary_button" (click)="saveForm()">Save</button>
            </div>
        </div>
    </header>
    <div class="body">
        <div class="form-container">
            <form [formGroup]="gateOutForm">
                <div class="form-row">
                    <div class="form-group" >
                        <label for="vehicleId" class="form-label">
                            Trailer No<span class="required">*</span>
                        </label>
                        <app-autocomplete-dropdown
                            id="vehicleId"
                            formControlName="vehicleId"
                            class = "autocomplete-dropdown"
                            [placeholder]="'Search Trailer'"
                            [apiUrl]="'v1/inventory/trailers/list'"
                            [selectFields]="['id', 'trailerNumber', 'lastActivity', 'processCycle']"
                            [whereFields]="['trailerNumber']"
                            [limit]="10"
                            [orderBy]="{ trailerNumber: 'asc' }"
                            [filterByKey]="'trailerNumber'"
                            [bindLabel]="'trailerNumber'"
                            [bindValue]="'id'"
                            [displayColumns]="[
                                { key: 'trailerNumber', header: 'Trailer No' },
                            ]"
                            (suggestionSelected) = "onTrailerSelected($event)"
            
                            >
                            </app-autocomplete-dropdown>
                        <div class="form_validation_error" [ngClass]="{ form_error: gateOutForm.get('vehicleId')?.invalid && gateOutForm.get('vehicleId')?.touched }">
                            <small *ngIf="gateOutForm.get('vehicleId')?.errors?.['required']">Trailer No is required</small>
                        </div>
                    </div>
                </div>
      
              <div class="form-row">
                <div class="form-group">
                    <label for="gateOutMode" class="form-label">
                      Gate-Out Mode<span class="required">*</span>
                    </label>
                    <select id="gateOutMode" formControlName="gateOutMode" class="form-select" required>
                        <option value="" disabled>Select Mode</option>
                        <option *ngFor="let option of supportedGateOutOptions" [value]="option.key">{{ option.label }}</option>
                    </select>
                    <div class="form_validation_error" [ngClass]="{ form_error: gateOutForm.get('gateOutMode')?.invalid && gateOutForm.get('gateOutMode')?.touched }">
                        <small *ngIf="gateOutForm.get('gateOutMode')?.errors?.['required']">Please select the mode</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="gateOutTime" class="form-label">
                      Gate Out Time<span class="required">*</span>
                    </label>
                    <input id="gateOutTime" type="datetime-local" formControlName="gateOutTime" class="form-input" [max]="maxDateTime" required/>
                    <div class="form_validation_error" [ngClass]="{ form_error: gateOutForm.get('gateOutTime')?.invalid && gateOutForm.get('gateOutTime')?.touched }">
                        <small *ngIf="gateOutForm.get('gateOutTime')?.errors?.['required']">Lane No is required</small>
                        <small *ngIf="gateOutForm.get('gateOutTime')?.errors?.['invalidDateTime']">Gate Out time cannot be in future</small>
                    </div>
                  </div>
              </div>
              
              <div class="form-row">
                <div class="form-group">
                    <label for="driverName" class="form-label">
                      Driver Name<span class="required">*</span>
                    </label>
                    <input type="text" id="driverName" formControlName="driverName" class="form-input" value="Vessel" placeholder="Enter Driver Name" required />
                    <div class="form_validation_error" [ngClass]="{ 'form_error': gateOutForm.get('driverName')?.invalid && gateOutForm.get('driverName')?.touched }">
                        <small *ngIf="gateOutForm.get('driverName')?.errors?.['required']">Driver License No is required</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="driverLicenseNo" class="form-label">
                        Driver License No<span class="required">*</span>
                    </label>
                    <input type="text" id="driverLicenseNo" formControlName="driverLicenseNo" class="form-input" value="Vessel" placeholder="Enter Driver License no" required />
                    <div class="form_validation_error" [ngClass]="{ 'form_error': gateOutForm.get('driverLicenseNo')?.invalid && gateOutForm.get('driverLicenseNo')?.touched }">
                        <small *ngIf="gateOutForm.get('driverLicenseNo')?.errors?.['required']">Driver License No is required</small>
                    </div>
                </div>
              </div>
            </form>
          </div>
          <div class="divider" ></div>
          <div class="container-details">
            <div class="section-title">Container Details</div>
              <table class="container-table">
                  <thead>
                    <tr>
                      <th><span>Sl No.</span></th>
                      <th *ngFor="let column of tableHeaders">{{ column.displayLabel }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let result of containerList; let i = index">
                      <td><span> {{ i+1 }} </span></td>
                      <td *ngFor="let column of tableHeaders">
                        <span> {{ result[column.fieldName] }} </span>
                      </td>
                    </tr>
                  </tbody>
              </table>
          </div>
    </div>
</div>
  
  
  
  
  