<form [formGroup]="formGroup">
    <div class="grid-container">
      @for (row of formConfig?.formRows; track row?.id || $index) {
        <div [class]="row?.class || ''" [id]="row?.id || ''" class="form-row" [ngStyle]="row?.style">
          @for (group of row?.formGroups; track group?.id || $index) {
            @if (isControlVisible(group.control)) {
              <div
                [id]="group?.id || ''"
                [class]="group?.class || ''"
                [ngStyle]="group?.style"
                class="form-group" #formField>
                @if (!(group.control?.skipLabel ?? false)) {
                  <label class="form-label" [for]="group.control.label" [ngClass]="{
                    'required_field_label': isControlRequired(group.control),
                    }">{{ group.control.displayLabel }} 
                </label>
                }
                <app-dynamic-input
                  [control]="group.control"
                  [form]="formGroup"
                  (onAutoCompleteSuggestionSelected) = "onSearchSuggestionSelect($event)"
                  (searchTextCleared) = "onAutoCompleteSearchTermCleared($event)"
                ></app-dynamic-input>
              </div>
            }
          }
        </div>
      }
    </div>
</form>



