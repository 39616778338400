import { Component,
  ElementRef,
  QueryList,
  ViewContainerRef,
  ViewChild,
  ViewChildren, } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormArray, FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OperationService } from '../../../../services/operations.service';
import { ConfirmDialogV2Component } from '../../../confirm-dialog/confirm-dialog-v2.component';
import { LoadingService } from '../../../../services/loading.service';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import {VehicleType} from "../../../../models/vehicle-type.interface"
import { InventoryType, Lane, getGateInOptions, getGateOutModes } from '../../../../constants/gate-ops-constants';
import { CycleType } from '../../../../constants/app-constants';
import { ToasterService } from '../../../../services/toaster.service';
import { AutocompleteDropdownComponentValidator } from "../../../../validators/autoCompleteValidator";
import { forkJoin } from 'rxjs';
import containerHeaders from "./containerHeaderInfo.json"
import { SidebarNewCollapsedComponent } from "../../../sidebar-new/sidebar-new-collapsed/sidebar-new-collapsed.component";
import { DateTimeValidator } from '../../../../validators/dateTimeValidator';
import { convertEpochToISTDateTime, getMaxDateTimeForDatePicker } from '../../../../utils/date-time-utils';

@Component({
  selector: 'app-trailer-gate-out',
  standalone: true,
  imports: [SharedModule,
    FormsModule,
    AutocompleteDropdownComponent, SidebarNewCollapsedComponent],
  templateUrl: './container-gate-out.component.html',
  styleUrl: './container-gate-out.component.scss'
})
export class ContainerGateOutComponent {
  gateOutForm: FormGroup;
  ownVehicle: boolean = false;
  isOwnMovement: boolean = false;
  isOwnMovementDisabled: boolean = false;
  inspectionTypeRequired: boolean = false;
  selectedTrailerId: string | null = null;
  selectedTrailerNo: string | null = null;
  jobOrderDetails: any = null;
  containerList: any[] = [];
  tableHeaders: any[] = [];
  maxDateTime: string = '';
  
  laneOptions = Object.values(Lane);
  cycleOptions = Object.values(CycleType);

  supportedGateOutOptions: Array<{ key: string; label: string;}> = [];
  gateOutOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
    public trailerTypeDataRes?: VehicleType;

  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
  ) {
    this.gateOutForm = this.fb.group({
      gateOutMode: ['', Validators.required],
      vehicleId: ['', AutocompleteDropdownComponentValidator()],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required],
      gateOutTime: ['', [Validators.required, DateTimeValidator()]],
    });
  }
  ngOnInit(): void {
    this.loadDropdownData();
    this.updateGateOutTime();
    this.tableHeaders = containerHeaders?.headers;
    console.log(this.tableHeaders);
  }

  updateGateOutTime() {
    this.maxDateTime = getMaxDateTimeForDatePicker();
    this.gateOutForm.get("gateOutTime")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  loadDropdownData(): void {
      this.supportedGateOutOptions = getGateOutModes(InventoryType.CONTAINER);
  }

  onTrailerSelected(selectedItem: any) {
    // TODO: Select gate-out mode based on last activity/jobOrderType
    
    forkJoin({
      jobOrderResult: this.api.fetchJobOrdersByInventoryIdOrNo(selectedItem.id, false, true),
      trailerResult: this.api.fetchTrailerById(selectedItem.id, true, false)
    }).subscribe({
      next: ({jobOrderResult, trailerResult}: any) => {
        const jobOrderDetails = jobOrderResult.data;
        const vehicleAndItemDetails = trailerResult.data;
        if (Array.isArray(jobOrderDetails)) {
          if (jobOrderDetails.length === 0) {
            console.log("Couldn't find any job order associated with the truck");
          } else {
            this.jobOrderDetails = jobOrderDetails[0];
          }
        } else {
          this.toasterService.warning("Failed to fetch job order details");
        }
        this.containerList = vehicleAndItemDetails?.containerList;
        if (this.containerList.length == 0) {
          this.toasterService.error("Couldn't find containers for the trailer");
        }
        this.gateOutForm.get('driverName')?.setValue(vehicleAndItemDetails?.driverName);
        this.gateOutForm.get('driverLicenseNo')?.setValue(vehicleAndItemDetails?.driverLicenseNo);
        this.loadingService.hide();
      },
      error: (err) => {
        console.error(err);
        this.loadingService.hide();
      },
    });
  }

  onGateOutModeChange() {

  }

  resetForm() {
    this.gateOutForm.reset({
      gateOutMode: '',
      vehicleId: ''
    });
    this.jobOrderDetails = null;
    this.updateGateOutTime();
  }

  saveForm() {
    this.gateOutForm.markAllAsTouched();
    this.printFormErrors(this.gateOutForm);
    if (this.gateOutForm.valid) {
      this.openConfirmDialog();
    }
  }

  openConfirmDialog() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);
    confirmDialogRef.instance.title = "Gate-Out Confirmation"
    confirmDialogRef.instance.message = "Do you want to confirm and save the changes?";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      const gateInRequest = this.constructRequest();
        this.api.submitGateOutRequest(gateInRequest).subscribe({
          next: (res) => {
            this.toasterService.success("Gate Out was successful");
            this.resetForm();
          },
          error: (error) => {
            this.toasterService.error(error.errorDesc);
          }
        });
        confirmDialogRef.destroy();
      });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  constructRequest(): any {
    const formData = this.gateOutForm.value;
    const gateOutRequest = {
        joNo: '',
        gateOutMode: formData.gateOutMode,
        vehicleId: formData.vehicleId?.key,
        driverName: formData.driverName,
        driverLicenseNo: formData.driverLicenseNo,
        containerIds: this.containerList.map((container: any) => container.id),
        vehicleType: InventoryType.CONTAINER
    };
    return gateOutRequest;
  }

  printFormErrors(formGroup: FormGroup | FormArray, path: string = ''): void {
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.get(key);
        const controlPath = path ? `${path}.${key}` : key;
    
        if (control instanceof FormGroup || control instanceof FormArray) {
          this.printFormErrors(control, controlPath);
        } else if (control && control.invalid) {
          console.log(`Control: ${controlPath}`, control.errors);
        }
      });
    }
}
