{
  "lclDeliveryTallyFormElements": {
    "formRows": [
      {
        "formGroups": [
          {
            "control": {
              "hidden": true,
              "label": "inventoryId",
              "displayLabel": "Inventory ID",
              "disabled": false,
              "placedHolder": "Enter Inventory Id",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryNo",
              "displayLabel": "Inventory No",
              "disabled": false,
              "placedHolder": "Enter Inventory No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryType",
              "displayLabel": "Inventory Type",
              "disabled": false,
              "placedHolder": "Enter Inventory Type",
              "defaultValue": "TRUCK",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentNo",
              "displayLabel": "Document No",
              "disabled": false,
              "placedHolder": "Enter Document No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentType",
              "displayLabel": "Document Type",
              "disabled": false,
              "placedHolder": "Enter Document Type",
              "defaultValue": "IGM_ITEM_NUMBER",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "cargoId",
              "displayLabel": "Cargo Id",
              "disabled": false,
              "placedHolder": "Enter Cargo No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "startTime",
              "displayLabel": "Activity Start Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Start Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "endTime",
              "displayLabel": "Activity End Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "End Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "condition",
              "displayLabel": "Condition",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "select",
                "options": [
                  {
                    "label": "Bad",
                    "value": "BAD"
                  },
                  {
                    "label": "Not Survey",
                    "value": "NOT_SURVEY"
                  },
                  {
                    "label": "Sound",
                    "value": "SOUND"
                  },
                  {
                    "label": "Damaged",
                    "value": "DAMAGED"
                  }
                ]
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Condition is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 6"
            },
            "control": {
              "label": "remarks",
              "placeHolder": "Enter Remarks",
              "displayLabel": "Remarks",
              "disabled": false,
              "fieldInfo": {
                "dataType": "textarea"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Remarks is required"
                }
              ]
            }
          }
        ]
      }
    ]
  },
  "editableTableConfig": [
    {
      "label": "igmItemNo",
      "displayLabel": "IGM/Item No",
      "editable": false,
      "isPinned": true
    },
    {
      "label": "itemNo",
      "displayLabel": "Item No",
      "editable": false
    },
    {
      "label": "accountHolderName",
      "displayLabel": "Account holder",
      "editable": false
    },
    {
      "label": "commodityValue",
      "displayLabel": "Commodity Value",
      "editable": false
    },    {
      "label": "blNo",
      "displayLabel": "BL Number",
      "editable": false
    },    {
      "label": "coocNo",
      "displayLabel": "Cooc Number",
      "editable": false
    },    {
      "label": "coocDate",
      "displayLabel": "Cooc Date",
      "editable": false
    },    {
      "label": "boeNo",
      "displayLabel": "BOE Number",
      "editable": false
    },    {
      "label": "boeDate",
      "displayLabel": "BOE Date",
      "editable": false
    },    {
      "label": "hazardous",
      "displayLabel": "Hazardous",
      "editable": false
    },    {
      "label": "goodsDescription",
      "displayLabel": "Goods Description",
      "editable": false
    },    {
      "label": "consigneeName",
      "displayLabel": "Consignee",
      "editable": false
    },    {
      "label": "lineAgentName",
      "displayLabel": "Line Agent",
      "editable": false
    },
    {
      "label": "numberOfPackages",
      "displayLabel": "Manifested NOP",
      "editable": false
    },    {
      "label": "remainingPackages",
      "displayLabel": "Remaining NOP",
      "editable": false
    },
    {
      "label": "grossWeight",
      "displayLabel": "Manifested Gross Weight",
      "editable": false
    },    {
      "label": "grossVolume",
      "displayLabel": "Manifested Gross Volume",
      "editable": false
    },
    {
      "label": "deliveryNOP",
      "displayLabel": "Delivery NOP",
      "placeholder": "Enter Delivery NOP",
      "editable": true,
      "fieldInfo": {
        "dataType": "number"
      }
    },
    {
      "label": "deliveryGrossWt",
      "displayLabel": "Delivery Gross WT",
      "placeholder": "Enter Delivery Gross WT",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    },
    {
      "label": "deliveryGrossVolume",
      "displayLabel": "Delivery Gross Volume",
      "placeholder": "Enter Delivery Gross Volume",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    }
  ],

  "editableTableConfigForSmtpTruckMovement": [
    {
      "label": "igmItemNo",
      "displayLabel": "IGM/Item No",
      "editable": false,
      "isPinned": true
    },
    {
      "label": "itemNo",
      "displayLabel": "Item No",
      "editable": false
    },
    {
      "label": "accountHolderName",
      "displayLabel": "Account holder",
      "editable": false
    },
    {
      "label": "commodityValue",
      "displayLabel": "Commodity Value",
      "editable": false
    },    {
      "label": "blNo",
      "displayLabel": "BL Number",
      "editable": false
    },    {
      "label": "coocNo",
      "displayLabel": "Cooc Number",
      "editable": false
    },    {
      "label": "coocDate",
      "displayLabel": "Cooc Date",
      "editable": false
    },    {
      "label": "boeNo",
      "displayLabel": "BOE Number",
      "editable": false
    },    {
      "label": "boeDate",
      "displayLabel": "BOE Date",
      "editable": false
    },    {
      "label": "hazardous",
      "displayLabel": "Hazardous",
      "editable": false
    },    {
      "label": "goodsDescription",
      "displayLabel": "Goods Description",
      "editable": false
    },    {
      "label": "consigneeName",
      "displayLabel": "Consignee",
      "editable": false
    },    {
      "label": "lineAgentName",
      "displayLabel": "Line Agent",
      "editable": false
    },
    {
      "label": "numberOfPackages",
      "displayLabel": "Manifested NOP",
      "editable": false
    },    {
      "label": "remainingPackages",
      "displayLabel": "Remaining NOP",
      "editable": false
    },
    {
      "label": "grossWeight",
      "displayLabel": "Manifested Gross Weight",
      "editable": false
    },    {
      "label": "grossVolume",
      "displayLabel": "Manifested Gross Volume",
      "editable": false
    },
    {
      "label": "smtpMovement",
      "displayLabel": "SMTP Movement",
      "editable": true,
      "fieldInfo": {
        "dataType": "boolean"
      }
    },
    {
      "label": "smtpNo",
      "displayLabel": "SMTP Number",
      "editable": true,
      "placeholder": "Enter SMTP Number",
      "fieldInfo": {
        "dataType": "text"
      }
    },
    {
      "label": "deliveryNOP",
      "displayLabel": "Delivery NOP",
      "placeholder": "Enter Delivery NOP",
      "editable": true,
      "fieldInfo": {
        "dataType": "number"
      }
    },
    {
      "label": "deliveryGrossWt",
      "displayLabel": "Delivery Gross WT",
      "placeholder": "Enter Delivery Gross WT",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    },
    {
      "label": "deliveryGrossVolume",
      "displayLabel": "Delivery Gross Volume",
      "placeholder": "Enter Delivery Gross Volume",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    }
  ]
}

