import { Component, ViewContainerRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OperationService } from '../../../../../services/operations.service';
import { LoadingService } from '../../../../../services/loading.service';
import { SvgIconComponent } from '../../../../svg-icon/svg-icon.component';

import { AutocompleteDropdownComponent } from '../../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { ConfirmDialogV2Component } from '../../../../confirm-dialog/confirm-dialog-v2.component';
import { CheckboxComponent } from '../../../../common/checkbox/checkbox.component';
import { ToggleComponent } from "../../../../common/toggle/toggle.component";
import { Container } from "../../../../../models/container.interface";
import { GateInTypes } from '../../../../../constants/gate-ops-constants';
import { formLayoutConfig, FieldProperties} from './form-layout-config';
import { AutocompleteDropdownComponentValidator } from '../../../../../validators/autoCompleteValidator';
import { ModalComponent } from '../../../../modal/modal.component';
import { ImageViewerComponent } from '../../../../document-viewer/image-viewer/image-viewer.component';

@Component({
  selector: 'app-container-form',
  standalone: true,
  imports: [AutocompleteDropdownComponent,
    CheckboxComponent,
    SvgIconComponent,
    ReactiveFormsModule,
    CommonModule, ToggleComponent],
  templateUrl: './container-form.component.html',
  styleUrl: './container-form.component.scss'
})
export class ContainerFormComponent {
  @Input() containerForm!: FormGroup;
  @Input() index!: number;
  @Input() gateInMode!: string;
  @Input() eirImageSource!: string

  @Output() remove = new EventEmitter<void>();
  @Output() isoCodeSelection = new EventEmitter<void>();

    ownVehicle: boolean = true;
    gateInOptions: any = [];
    laneOptions: any = [];
    cycleOptions: any = [];
    gridTemplateAreas: string = '';
    fieldNamesSet: Set<string> = new Set();
    containerNo: string= '';
    containerId: string= '';
    containerFilters: any = [];
    allowContainerSearch: boolean = true;
    containerSearchEndpoint: string = '';
    fieldInfoMap: any;


    scanTypeOptions = ['D', 'M', 'F', 'NR', 'NU'];
    scanStatusOptions = [
      {key: 'CLEARED', value: 'Cleared'}, 
      {key:'EXAMINATION', value: '100% Examination'}, 
      {key: 'SUSPICIOUS', value: 'Suspicious'},
      {key: 'NOT_SCANNED', value: 'Not Scanned'},
      {key: 'NOT_REQUIRED', value: 'Not Required'},
      {key: 'RESTRICTED', value: 'Restricted'}
    ];

    filteredScanStatusOptions: any = [...this.scanStatusOptions];
    conditionOptions = [
      { value: 'Sound', key: 'SOUND' },
      { value: 'Damaged', key: 'DAMAGED' },
      { value: 'Bad', key: 'BAD' }
    ];

    constructor(
      private fb: FormBuilder,
      private api: OperationService,
      private loadingService: LoadingService,
      private viewContainerRef: ViewContainerRef,
    ) {
    }

    ngOnInit() {
      this.updateGridTemplateAreas();
      this.updateFieldStates();
    }

    private updateGridTemplateAreas() {
      const modeConfig = formLayoutConfig[this.gateInMode] || formLayoutConfig["DEFAULT"];
      this.gridTemplateAreas = modeConfig.gridTemplateAreas.join(' ');
      this.fieldNamesSet = new Set(
        modeConfig.gridTemplateAreas
          .join(' ')
          .split(' ')
          .map(field => field.replace(/^['"]|['"]$/g, ''))
          .filter(field => field !== '.' && field)
      );
      this.fieldInfoMap = modeConfig.fieldInfoMap;
      this.allowContainerSearch = modeConfig.containerSearchable ?? false;
      this.containerSearchEndpoint = modeConfig.containerEndpoint ?? '';
      this.containerFilters = modeConfig.containerFilters || [];
      console.log("containerFilters: ", this.containerFilters)
    }

    private updateFieldStates() {
      if (this.fieldInfoMap && typeof this.fieldInfoMap === 'object') {
        Object.entries(this.fieldInfoMap).forEach(([fieldName, config]) => {
          const control = this.containerForm.get(fieldName);
          if (control) {
            const fieldInfo = config as FieldProperties;
            if (fieldInfo.required && fieldInfo?.type === 'autocomplete-dropdown') {
              control.setValidators([AutocompleteDropdownComponentValidator()]);
            } else if (fieldInfo.required) {
              control.setValidators([Validators.required]);
            } else {
              control.clearValidators();
            }
            if (fieldName === 'containerNo' && this.allowContainerSearch) {
              control.setValidators([AutocompleteDropdownComponentValidator()]);
            }
            control.updateValueAndValidity();
          }
        });
      }
      console.log("Container No Validators: ", this.containerForm.get("containerNo"));
    }

    isFieldInGrid(fieldName: string): boolean {
      return this.fieldNamesSet.has(fieldName);
    }

    isFieldRequired(fieldName: string): boolean {
      return fieldName in this.fieldInfoMap && this.fieldInfoMap[fieldName]?.required;
    }

    setFieldRequiredStatus(fieldName: string, status: boolean): void {
      if (this.fieldInfoMap[fieldName]) {
        this.fieldInfoMap[fieldName].required = status;
      } else {
        this.fieldInfoMap[fieldName] = { required: status };
      }
    }

    removeForm() {
      this.remove.emit();
    }

    onReeferPluginChange(isActive: boolean) {
      console.log("Inside #onReeferPluginChange:", isActive);
      this.containerForm.get("reeferPlugin")?.setValue(isActive);
      // if (isActive) {
      //   this.containerForm.get("setTemperature")?.enable();
      //   this.containerForm.get("temperature")?.enable();
      //   this.setFieldRequiredStatus("setTemperature", true);
      //   this.setFieldRequiredStatus("temperature", true);
      // } else {
      //   this.containerForm.get("setTemperature")?.disable();
      //   this.containerForm.get("temperature")?.disable();
      //   this.containerForm.get("setTemperature")?.setValue('');
      //   this.containerForm.get("temperature")?.setValue('');
      //   this.setFieldRequiredStatus("setTemperature", false);
      //   this.setFieldRequiredStatus("temperature", false);
      // }
    }

    updateScanStatusOptions(): void {
      const selectedScanType = this.containerForm.get("scanType")?.value;
      if (!selectedScanType) return;
      this.containerForm.get("scanStatus")?.setValue('');
      if (['D', 'M', 'F'].includes(selectedScanType)) {
        this.filteredScanStatusOptions = this.scanStatusOptions.filter((option) =>
          ['CLEARED', 'EXAMINATION', 'SUSPICIOUS'].includes(option.key)
        );
      } else if (selectedScanType === 'NR') {
        this.filteredScanStatusOptions = this.scanStatusOptions.filter(
          (option) => option.key === 'NOT_REQUIRED'
        );
        this.containerForm.get("scanStatus")?.setValue("NOT_REQUIRED");
      } else if (selectedScanType === 'NU') {
        this.filteredScanStatusOptions = this.scanStatusOptions.filter(
          (option) => option.key === 'NOT_SCANNED'
        );
        this.containerForm.get("scanStatus")?.setValue("NOT_SCANNED");
      }
    }

    onHazChange(isActive: boolean) {
      this.containerForm.get("hazardous")?.setValue(isActive);
    }

    onOdcChange(isActive: boolean) {
      this.containerForm.get("odc")?.setValue(isActive);
    }

    onIsoCodeSelection(selectedSizeType: any) {
      if (selectedSizeType) {
        this.containerForm.get("containerSize")?.setValue(selectedSizeType.size);
        this.containerForm.get("containerType")?.setValue(selectedSizeType.type);

        if (selectedSizeType.type === 'RF') {
          this.containerForm.get("setTemperature")?.enable();
          this.containerForm.get("temperature")?.enable();
          this.setFieldRequiredStatus("setTemperature", true);
          this.setFieldRequiredStatus("temperature", true);
          this.onReeferPluginChange(true);
        } else {
          this.containerForm.get("setTemperature")?.disable();
          this.containerForm.get("temperature")?.disable();
          this.containerForm.get("setTemperature")?.setValue('');
          this.containerForm.get("temperature")?.setValue('');
          this.setFieldRequiredStatus("setTemperature", false);
          this.setFieldRequiredStatus("temperature", false);
          this.onReeferPluginChange(false);
        }
        this.isoCodeSelection.emit();
      }
    }

    onContainerSelection(selectedContainer: any) {
      if (!selectedContainer) return;
      console.log("Selected container: ", selectedContainer);
      this.containerNo = selectedContainer.containerNo;
      this.containerId = selectedContainer.id;
      this.fetchContainerData(selectedContainer.id, (container) => {
        this.patchContainerData(container);
      });
    }

    patchContainerData(container: any) {
      if (container) {
        this.containerForm.patchValue({
          ...container,
          containerId: container?.id,
          isoCode: container?.isoCode ?  { key: container.isoCode, value: container.isoCode } : null,
          vesselId: container?.vesselId && container?.vesselName ? { key: container.vesselId, value: container.vesselName } : null,
          accountHolderId: container?.accountHolderId && container?.accountHolderName ? { key: container.accountHolderId, value: container.accountHolderName } : null,
          shippingLineId: container?.shippingLineId && container?.shippingLineName ? { key: container.shippingLineId, value: container.shippingLineName } : null,
          fromLocationId: container?.fromLocationId && container?.fromLocationName ? { key: container.fromLocationId, value: container.fromLocationName } : null,
        });
        this.containerNo = this.containerNo || container.containerNo;
        this.onIsoCodeSelection({size: container.containerSize, type: container.containerType});
        this.updateScanStatusOptions();
      }
    }

    onEnteringContainerNo(containerNo: string) {
      this.containerNo = containerNo;
      this.containerForm.get('containerNo')?.setValue(containerNo);
    }

    fetchContainerData(containerId: any, callback: (container: any | null) => void): void {
      this.api.fetchContainerById(containerId, false).subscribe({
        next: (res: any) => {
          callback(res.data);
        },
        error: (err) => {
          console.log(err);
          callback(null);
        },
      });
    }

    loadEIRImage() {
      if (this.eirImageSource) {
        const modalRef = this.viewContainerRef.createComponent(ModalComponent);
        modalRef.instance.title = "EIR (" + this.containerId + ")" ;
        const filterDialogRef = modalRef.instance.loadComponent(
          ImageViewerComponent
        );
        filterDialogRef.setInput("imageSrc", this.eirImageSource);
        filterDialogRef.setInput("imageWidth", "421px");
        filterDialogRef.setInput("imageHeight", "578px");
        modalRef.instance.close.subscribe((res: any) => {
          modalRef.destroy();
        });
      }
    }
}
