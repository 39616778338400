<div class="vendor-form-container">

  <div class="table-container" *ngIf="showItemTable">
      <table class="item-table resuable-table">
        <thead>
          <tr>
            <th><span>Sl No</span></th>
            <th *ngFor="let column of tableHeaderAndData.headers">{{ column.displayName }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of tableHeaderAndData.data; let i = index">
            <td><span> {{ i+1 }} </span></td>
            <td *ngFor="let column of tableHeaderAndData.headers">
              <span> {{ result[column.fieldName] }} </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="divider"></div> -->
    </div>

  <div class = "form" *ngIf = "(dynamicForm)">
    <app-dynamic-form
      [formGroup] = "formGroup"
      [formConfig] = "dynamicForm"
    >
    </app-dynamic-form>
    <!-- <div class="divider"></div> -->
  </div>
  <div class = "vendor-selection">
    <div class = "form-item">
      <label for="serviceId" class="search-label required_field_label">Service</label>
      <app-autocomplete-dropdown
          id="serviceId"
          class = "autocomplete-dropdown"
          [(ngModel)] = "serviceSearchQuery"
          [placeholder]="'Select service'"
          [apiUrl]="'v1/services/list'"
          [selectFields]="['id', 'name']"
          [whereFields]="['name']"
          [limit]="10"
          [orderBy]="{ name: 'asc' }"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [displayColumns]="[
              { key: 'name', header: 'Name' },
          ]"
          (suggestionSelected) = "onServiceSelection($event)"
          (searchCleared)="serviceSearchCleared()"
          >
      </app-autocomplete-dropdown>
    </div>

    <div class = "link">
      <app-svg-icon [path]="'assets/icons/link_icon.svg'" class="link-icon">
      </app-svg-icon>
    </div>

    <!-- <div class = "form-item">
      <label for="vendorId">Vendor<span class="required">*</span></label>
      <app-autocomplete-dropdown
          id="vendorId"
          class = "autocomplete-dropdown"
          [(ngModel)] = "vendorSearchQuery"
          [placeholder]="'Select vendor'"
          [bindLabel]="'vendorName'"
          [bindValue]="'vendorId'"
          [whereFields]="['vendorName']"
          [displayColumns]="[
              { key: 'vendorName', header: 'Name' },
          ]"
          [clientSideSearch]=true
          [clientSearchData]="serviceVendorList"
          [disable]="!(this.serviceVendorList.length > 0)"
          (suggestionSelected) = "onVendorSelection($event)"
          >
      </app-autocomplete-dropdown>
    </div> -->

    <div class = "form-item">
      <label for="vendorId" class="required_field_label">Vendor</label>
      <app-multi-select
      #vendorDropdown
          id="vendorId"
          [options]="serviceVendorList"
          [displayKey]="'vendorName'"
          [valueKey]="'vendorId'"
          [isMultiSelect]="false"
          [placeholder]="'Select Vendor'"
          [selectedItems]="selectedVendors"
          [disable]="serviceVendorList.length < 1"
          (onOptionSelection)="handleVendorSelection($event)"
        ></app-multi-select>
      </div>

    <div class = "link">
      <app-svg-icon [path]="'assets/icons/link_icon.svg'" class="link-icon">
      </app-svg-icon>
    </div>

    <!-- <div class = "form-item">
      <label for="equipmentId" class="search-label">Equipment<span class="required">*</span></label>
      <app-autocomplete-dropdown
          id="equipmentId"
          class = "autocomplete-dropdown"
          [(ngModel)] = "equipmentSearchQuery"
          [placeholder]="'Select'"
          [whereFields]="['equipmentName']"
          [bindLabel]="'equipmentName'"
          [bindValue]="'equipmentId'"
          [displayColumns]="[
              { key: 'equipmentName', header: 'Name' },
          ]"
          [disable]="!(this.serviceEquipmentList.length > 0)"
          [clientSideSearch]=true
          [clientSearchData]="serviceEquipmentList"
          (suggestionSelected) = "onEquipmentSelection($event)"
          >
      </app-autocomplete-dropdown>
    </div> -->

    <div class = "form-item">
      <label for="equipmentId" class="required_field_label">Equipment</label>
      <app-multi-select
      #equipmentDropdown
          id="equipmentId"
          [options]="serviceEquipmentList"
          [selectedItems]="selectedEquipments"
          [displayKey]="'equipmentName'"
          [valueKey]="'equipmentId'"
          [isMultiSelect]="true"
          [placeholder]="'Select Equipments'"
          [disable]="serviceEquipmentList.length < 1"
          [showChips]="false"
        ></app-multi-select>
      </div>

    <div class="button-action">
      <button class="add-button primary_button" [disabled]="!canAddVendors" (click)="addVendorToList()">
        Add
      </button>
    </div>
  </div>

  <div class="table-container">
    <table class="vendors-table table-view-data" *ngIf="vendorList.length > 0">
      <thead>
        <tr>
          <th><span>Sl No</span></th>
          <th *ngFor="let column of headerMap">{{ column.label }}</th>
          <th *ngIf = "!readOnly"><span></span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of vendorList; let i = index">
          <td><span> {{ i+1 }} </span></td>
          <td *ngFor="let column of headerMap">
            <!-- @if (column.label === 'Equipments') {
              <ng-container *ngFor="let item of result[column.value]">
                <div>{{ item.equipmentName }}</div>
              </ng-container>
            } @else {
              <span>
                {{ result[column.value] }}
              </span>
            } -->
            <span>
              {{ result[column.value] }}
            </span>
          </td>
          <td *ngIf = "!readOnly">
            <app-svg-icon class="delete-icon" 
                  [path]="'assets/icons/delete_icon.svg'" 
                  (click)="onDeleteRow(i)"
              ></app-svg-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
