<div class="stats">
  <div class="stats_content_dynamic">
    @for (stat of stats; track $index) {
      <div class="stats_details">
        <div class="stats_title">{{stat.displayLabel}}</div>
        <div class="stats_desc">
          <div class="icon_container">
            <app-svg-icon [path]="stat?.iconUrl" class="icon"></app-svg-icon>
          </div>
          @if (stat?.dateType === "date") {
            {{ stat.value | date: 'dd/MM/yyyy' }}
          } @else {
            {{ stat.value || "-" }}
          }
        </div>
      </div>
    }
  </div>
</div>

