import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { StatsComponent } from "../../../../stats/stats.component";
import { ManageTally } from "../manage-tally.interface";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { EditableTableHeader } from "../../../../../models/editable-table-header.interface";
import { JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { forkJoin, Subscription } from "rxjs";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import { Stats } from "../../../../../models/stats.interface";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import { ToasterService } from "../../../../../services/toaster.service";
import { Router } from "@angular/router";
import LCLDeliveryTallyFromJson from "../config/LCLDeliveryTally.json";
import { EditableTableComponent } from "../../../../editable-table/editable-table.component";
import { ConfirmDialogV2Component } from "../../../../confirm-dialog/confirm-dialog-v2.component";
import { getTallyTypeByJobOrderType } from "../../../../../constants/operations-contsants";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { Tally } from "../../../../../models/tally.interface";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";

@Component({
  selector: "app-manage-tally-smtp-truck-movement",
  standalone: true,
  imports: [
    ItemSelectorPanelComponent,
    StatsComponent
  ],
  templateUrl: "./manage-tally-smtp-truck-movement.component.html",
  styleUrl: "./manage-tally-smtp-truck-movement.component.scss"
})
export class ManageTallySmtpTruckMovementComponent implements ManageTally {
  subscription!: Subscription;
  groupedTrailerTruckByTrailerNumber: [] = [];
  dataLoaded: boolean = false;
  truckTrailerDetails: any[] = [];
  lclDeliveryTallyFormJson!: DynamicForm;
  lclDeliveryTallyForm!: FormGroup;
  editableConfig: EditableTableHeader[] = [];
  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;
  deliveredNopMapByTruck = new Map<string, Map<string, number>>();
  originalRemainingNOPMap = new Map<string, number>();
  truckAndCargoMap = new Map<string, any[]>;
  truckAndCargoSelectedMap: Map<string, any[]> = new Map<string, any[]>();
  selectedItems: any[] = [];
  savedTallyData: any[] = [];

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;
  @Input() jobOrderStats!: Stats[];

  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;

  constructor(private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private toasterService: ToasterService,
              private router: Router,
              private viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.lclDeliveryTallyFormJson = LCLDeliveryTallyFromJson.lclDeliveryTallyFormElements;
    this.editableConfig = LCLDeliveryTallyFromJson.editableTableConfigForSmtpTruckMovement;
    this.lclDeliveryTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {
      this.loadingService.show();
      forkJoin([
        this.operationService.getJobOrderDetails(this.jobOrderInventoryDetails?.jobOrderId, true, true, true, true),
        this.operationService.listTalliesByJobOrder(encodeURIComponent(this.jobOrderInventoryDetails?.jobOrderNo))
      ]).subscribe({
        next: ([jobOrderDetailsResponse, talliesResponse]: [any, any]) => {
          this.handleJobOrderDetailsResponse(jobOrderDetailsResponse,talliesResponse);
          this.loadingService.hide();
        },
        error: (error: any) => {
          this.loadingService.hide();
          console.log(error);
        }
      });
    }
  }

  get itemFormArray() {
    return this.lclDeliveryTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: any; index: number }) {
    this.changeDetectorRef.detectChanges();
    this.addTallyDetailsFromArrayForAssociatedTrucks(event.item);
    const formGroup = this.itemFormArray.at(event.index) as FormGroup;
    this.patchSavedTallyData(event, formGroup);

    this.setRequiredValueForFromGroup(formGroup, event);

    const componentsToLoad = this.prepareComponentsToLoad(event, formGroup);
    this.checkAndAddVendors(event, componentsToLoad);

    const componentRefs = this.itemSelectorComponent?.loadComponents(componentsToLoad);

    const editableTableComponentRef = componentRefs?.find(ref => ref.instance instanceof EditableTableComponent) as ComponentRef<EditableTableComponent>;
    this.handleEditableTableUpdates(editableTableComponentRef, event);
    this.handleEditableTableSelectedRowsChanges(editableTableComponentRef, event);
  }

  cancel(): void {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? ";
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  save(): void {
    if (!this.filterAndValidateTallyForm()) return;
    console.log(this.lclDeliveryTallyForm.value);

    const tallyData = this.prepareTallyData();
    console.log(tallyData);
    this.saveTally(tallyData);
  }

  handleCheckBoxSelection(event: any) {
    this.selectedItems = Array.from(event);
  }

  private filterAndValidateTallyForm(): boolean {
    const tallyDetailsFormGroup = this.lclDeliveryTallyForm.get("tallyDetails") as FormArray;
    if (tallyDetailsFormGroup.length === 0 || this.selectedItems.length === 0) {
      this.toasterService.error("Please select at least one truck to save the tally");
      return false;
    }

    this.lclDeliveryTallyForm.markAllAsTouched();
    const invalidSelectedItems = this.itemFormArray.controls
      .filter((control) => this.selectedItems.includes(control.get("inventoryId")?.value))
      .some((control) => control.invalid);

    if (invalidSelectedItems) {
      //TODO: logic to go through all selectedItems and show the error notifications on the left panel
      this.toasterService.error("Please fill all required fields to save the tally");
      console.log("Form is invalid", this.lclDeliveryTallyForm);
      return false;
    }

    //check all selected items have deliveryNOP
    if (this.truckAndCargoSelectedMap.size === 0) {
      this.toasterService.error("Please select at least one item to save the tally");
      return false;
    }
    for (const selectedCargoList of this.truckAndCargoSelectedMap.values()) {
      for (const cargo of selectedCargoList) {
        if (cargo.deliveryNOP === "" || cargo.deliveryNOP === undefined) {
          this.toasterService.error("Delivery NOP is required for all selected items");
          return false;
        }
      }
    }

    return true;
  }

  private prepareTallyData() {
    return {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      tallyType: getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType)?.code,
      tallyItems: this.lclDeliveryTallyForm.value.tallyDetails
        .filter((detail: { inventoryId: any; }) => this.selectedItems.includes(detail.inventoryId))
        .map((detail: any) => {
          const cargoList = this.truckAndCargoMap.get(detail.inventoryId) || [];
          return cargoList
            .filter(cargo => this.truckAndCargoSelectedMap.get(detail.inventoryId)?.map((selectedCargo: any) => selectedCargo.igmItemNo).includes(cargo.igmItemNo))
            .map((cargo: any) => ({
              ...detail,
              cargoId: cargo.id,
              documentNo: cargo.igmItemNo,
              itemPackagesLoaded: cargo.deliveryNOP,
              itemWeightLoaded: cargo.deliveryGrossWt,
              itemVolumeLoaded: cargo.deliveryGrossVolume,
              type: "stuffing_tally"
            }));
        }).flat()

    };
  }

  private handleJobOrderDetailsResponse(res: any,tallyResponse: any) {
    const data: JobOrderDetails = res?.data;
    this.truckTrailerDetails = data?.trailerTruckList?.map((truckTrailer: any) => {
      return {
        ...truckTrailer,
        disableCheckBox: !!tallyResponse.data.find((tally: {
          inventoryId: string
        }) => tally.inventoryId === truckTrailer.id)
      };
    });
    // this.initializeOriginalDeliveryNopMap(data);
    this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;
    this.groupedTrailerTruckByTrailerNumber = this.groupTruckTrailerDetailsByTrailerNo(this.truckTrailerDetails);
    this.savedTallyData = tallyResponse.data;
    this.populateTruckAndCargoMap(data);
    this.populateOriginalRemainingNOPMap(data);

    this.loadingService.hide();
    this.dataLoaded = true;
  }

  private populateTruckAndCargoMap(data: JobOrderDetails) {
    this.truckTrailerDetails.forEach(truckTrailer => {
      this.truckAndCargoMap.set(truckTrailer.id, JSON.parse(JSON.stringify(data.cargoList.map((cargo: CargoItem) => {
        // cargo.remainingNumberOfPackages = cargo.numberOfPackages;
        cargo.disabled = false;
        return cargo;
      }))));
    });
  }

  private saveTally(tallyData: Tally) {
    this.loadingService.show();
    this.operationService.saveTally(tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide();
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  private addTallyDetailsFromArrayForAssociatedTrucks(associatedTrucks: any | undefined) {
    this.dynamicFormService.addToFormArray(this.lclDeliveryTallyForm, "tallyDetails", this.lclDeliveryTallyFormJson);
  }

  private groupTruckTrailerDetailsByTrailerNo(truckTrailerDetails: any) {
    if (truckTrailerDetails.length === 0) {
      return;
    }

    return truckTrailerDetails.reduce((acc: any, detail: any) => {
      const trailerNo = detail?.vehicleNo;
      if (!acc[trailerNo]) {
        acc[trailerNo] = [];
      }
      acc[trailerNo].push(detail);
      return acc;
    }, {});
  }

  private getTruckTrailerStatsFor(trailerNo: any): any {
    const truckDetails: any = this.groupedTrailerTruckByTrailerNumber[trailerNo][0];
    return [{
      displayLabel: "Truck No.",
      value: truckDetails?.vehicleNo
    },
      {
        displayLabel: "Transporter Name",
        value: truckDetails?.transporterName
      },
      {
        displayLabel: "Vehicle Type",
        value: truckDetails?.vehicleTypeId
      },
      {
        displayLabel: "Driver Name",
        value: truckDetails?.driverName
      },
      {
        displayLabel: "Driver License No",
        value: truckDetails?.driverLicenseNo
      }
    ];
  }

  private handleEditableTableUpdates(editableTableComponentRef: ComponentRef<EditableTableComponent>, event: {
    item: any;
    index: number
  }) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = editableTableComponentRef.instance.dataUpdated.subscribe((data) => {
      const vehicleId = event.item.id;
      this.truckAndCargoMap.set(vehicleId, data.value);
      data.inputElement.setCustomValidity("");
      this.updateCargoDetails(data, vehicleId);
    });
  }

  private updateCargoDetails(data: any, vehicleId: string) {
    // data.value.forEach((cargo: any) => {
    const cargo: any = data.rowInfo;
    const deliveryNOP = Number(cargo.deliveryNOP) || 0;
    // const originalNOP = cargo.numberOfPackages || 0;

    if (!this.deliveredNopMapByTruck.has(cargo.igmItemNo)) {
      this.deliveredNopMapByTruck.set(cargo.igmItemNo, new Map());
    }

    const vehicleMap = this.deliveredNopMapByTruck.get(cargo.igmItemNo);
    vehicleMap?.set(vehicleId, deliveryNOP);

    const totalDeliveredNOP = Array.from(vehicleMap?.values() ?? []).reduce((acc, val) => acc + val, 0);

    const originalRemainingNop = this.originalRemainingNOPMap.get(cargo.igmItemNo) ?? 0;
    const remainingNOP: number | undefined = totalDeliveredNOP !== 0 ? originalRemainingNop - totalDeliveredNOP : originalRemainingNop;

    if (remainingNOP < 0) {
      data.inputElement.setCustomValidity("NOP loaded cannot exceed the manifested NOP");
      data.inputElement.reportValidity();
      return;
    }

    this.truckAndCargoMap.forEach((cargoList) => {
      cargoList.forEach((cargoItem: any) => {
        if (cargoItem.igmItemNo !== cargo.igmItemNo) return;

        cargoItem.remainingPackages = remainingNOP;
        cargoItem.disabled = remainingNOP <= 0 && (cargoItem.deliveryNOP === undefined || cargoItem.deliveryNOP === "");
      });
    });
    // });
  }

  private prepareComponentsToLoad(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    const componentsToLoad: any = [
      { component: StatsHorizontalComponent, data: { stats: this.getTruckTrailerStatsFor(event?.item?.vehicleNo) } },
      { component: DynamicFormComponent, data: { formConfig: this.lclDeliveryTallyFormJson, formGroup: formGroup } },
      {
        component: EditableTableComponent, data: {
          title: "Item information",
          tableHeaders: this.editableConfig,
          uniqueId: "id",
          selectedRowsInput: this.truckAndCargoSelectedMap.get(event.item.id),
          tableData: this.truckAndCargoMap.get(event.item.id)
        }
      }
    ];
    return componentsToLoad;
  }

  private setRequiredValueForFromGroup(formGroup: FormGroup<any>, event: { item: any; index: number }) {
    formGroup.get("inventoryId")?.setValue(event.item.id);
    formGroup.get("inventoryNo")?.setValue(event.item.vehicleNo);
  }

  private checkAndAddVendors(event: { item: any; index: number }, componentsToLoad: any) {
    if (this.jobOrderVendorsGroupByInventoryId[event.item?.id]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.id] }
      });
    }
  }

  private populateOriginalRemainingNOPMap(data: JobOrderDetails) {
    data.cargoList.forEach((cargo: CargoItem) => { //TODO: Set cargo datatype to CargoItem
      this.originalRemainingNOPMap.set(cargo.igmItemNo, cargo.remainingPackages ?? 0);
    });
  }

  private handleEditableTableSelectedRowsChanges(editableTableComponentRef: ComponentRef<EditableTableComponent>, event: {
    item: any;
    index: number
  }) {
    editableTableComponentRef.instance.selectedRowsSubject.subscribe((selectedRows: any[]) => {
      this.truckAndCargoSelectedMap.set(event.item.id, selectedRows);
    });
  }

  private patchSavedTallyData(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    if (this.savedTallyData?.length === 0) return;

    const filteredTallyData = this.savedTallyData.filter((tally: {
      inventoryId: string;
    }) => tally.inventoryId === event.item.id);

    if (filteredTallyData?.length === 0) return;
    const filteredTallyDataMap = new Map(filteredTallyData.map((tally: any) => [tally.cargoId, tally]));

    formGroup.patchValue({
      ...filteredTallyData[0],
      startTime: this.formatDateTimeForInput(filteredTallyData[0]?.startTime),
      endTime: this.formatDateTimeForInput(filteredTallyData[0]?.endTime)
    });
    formGroup.disable();

    this.truckAndCargoMap.get(event.item.id)?.forEach((cargo: any) => {
      if(!filteredTallyDataMap.has(cargo.id)) return;
      const tally = filteredTallyDataMap.get(cargo.id);
      cargo.deliveryNOP = tally.itemPackagesLoaded;
      cargo.deliveryGrossWt = tally.itemWeightLoaded;
      cargo.deliveryGrossVolume = tally.itemVolumeLoaded;
      cargo.disabled = true;
    });

  }

  private formatDateTimeForInput(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // This will give you YYYY-MM-DDThh:mm
  }
}
