<div class="container">
    <header class="header page-header">
        <div class="header-content">
            <div class="header-text">
                <div class="section-title text">Gate In - Container</div>
                <div class="section-subtitle text">Fill in trailer and container information to gate in</div>
            </div>
            <div class="header-actions">
                <button class="btn secondary_button" (click)="resetForm()">Reset Form</button>
                <button class="btn primary_button" (click)="saveForm()">Save</button>
            </div>
        </div>
        <div class="divider"></div>
    </header>
    <div class="scrollable-section container">
        <section class="mode-selection card-layout">
            <form [formGroup]="gateInForm">
                <div class="form-row">
                    <div class="form-group">
                        <label for="cycle" class="form-label">
                        Cycle<span class="required">*</span>
                        </label>
                        <select id="cycle" [ngClass]="{'read-only': showTrailerForm}" formControlName="cycle" class="form-select" required (change)="onCycleChange($event)" >
                            <option value="" disabled>Select Cycle</option>
                            <option *ngFor="let option of cycleOptions" [value]="option.key">{{ option.value }}</option>
                        </select>
                        <div class="form_validation_error" [ngClass]="{form_error : gateInForm.get('cycle')?.invalid && gateInForm.get('cycle')?.touched}">
                            <small *ngIf="gateInForm.get('cycle')?.errors?.['required']">Please select the cycle</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="gateInMode" class="form-label">
                          Gate-In Mode<span class="required">*</span>
                        </label>
                        <select id="gateInMode" [ngClass]="{'read-only': showTrailerForm}" formControlName="gateInMode" class="form-select" required>
                            <option value="" disabled>Select Mode</option>
                            <option *ngFor="let option of gateInOptions" [value]="option.key">{{ option.label }}</option>
                        </select>
                        <div class="form_validation_error" [ngClass]="{form_error : gateInForm.get('gateInMode')?.invalid && gateInForm.get('gateInMode')?.touched}">
                            <small *ngIf="gateInForm.get('gateInMode')?.errors?.['required']">Please select the mode</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="gateInMode" class="form-label">
                          Lane<span class="required">*</span>
                        </label>
                        <select id="laneNo" [ngClass]="{'read-only': showTrailerForm}" formControlName="laneNo" class="form-select" required>
                            <option value="" disabled>Select Lane</option>
                            <option *ngFor="let option of laneOptions" [value]="option.key">{{ option.value }}</option>
                        </select>
                        <div class="form_validation_error" [ngClass]="{form_error : gateInForm.get('laneNo')?.invalid && gateInForm.get('laneNo')?.touched}">
                            <small *ngIf="gateInForm.get('laneNo')?.errors?.['required']">Please select the mode</small>
                        </div>
                    </div>
                </div>
            </form>
        </section>

        <section class="refresh-container" *ngIf="showTrailerForm && isUserAllowed">
            <div class="horizontal-divider"></div>
            <p class="refresh-message">
                Click on refresh button to load recent data
            </p>
            <button class="refresh-button secondary_button"
            (click)="loadRecentData()">
                <app-svg-icon
                    [path]="'assets/icons/refresh_icon.svg'"
                ></app-svg-icon>
                <span>Load Recent Data</span>
            </button>
            <div class="horizontal-divider"></div>
        </section>

        <section class="trailer-section section" *ngIf="showTrailerForm">
            <header class="header section-header">
                <div class="header-content">
                    <div class="header-text">
                        <div class="section-title">Trailer Details</div>
                        <div class="section-subtitle">Fill in Customer Information to Begin Freight Management</div>
                    </div>
                    <div class="header-actions">
                        <button class="btn survey-btn text" (click)="openSurveyImageViewer()" [disabled]="canLoadRecentData">
                            <app-svg-icon class="image-icon"
                                [path]="'assets/icons/image_icon.svg'"
                            ></app-svg-icon>
                            Survey Images
                        </button>
                    </div>
                </div>
            </header>

            <div class = "content-section card-layout">
                <form [formGroup]="gateInForm">
                    <div class="form-row">
                        <div class="checkbox-group">
                            <app-checkbox
                                checkboxId="ownVehicle"
                                label="Own Vehicle"
                                formControlName="ownVehicle"
                                (checkedChange)="onOwnVehicleChange($event)">
                            </app-checkbox>
                            <app-checkbox
                                checkboxId="ownMovement"
                                label="Own Movement"
                                [isChecked]="isOwnMovement"
                                [isDisabled]="isOwnMovementDisabled"
                                formControlName="ownMovement"
                                (checkedChange)="onOwnMovementChange($event)"
                            >
                            </app-checkbox>
                        </div>

                        <div class="form-group">
                            <label for="gateInTime" class="form-label">
                              Gate In Time<span class="required">*</span>
                            </label>
                            <input id="gateInTime" type="datetime-local" formControlName="gateInTime" class="form-input" [max]="maxDateTime" required/>
                            <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('gateInTime')?.invalid && gateInForm.get('gateInTime')?.touched }">
                                <small *ngIf="gateInForm.get('gateInTime')?.errors?.['required']">Lane No is required</small>
                                <small *ngIf="gateInForm.get('gateInTime')?.errors?.['invalidDateTime']">Gate In time cannot be in future</small>
                            </div>
                          </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="trailerNo" class="form-label">
                                Trailer No<span class="required">*</span>
                            </label>
                            <input
                                *ngIf="!trailerSearchableFinal"
                                type="text"
                                id="trailerNo"
                                formControlName="trailerNo"
                                class="form-input"
                                required
                                placeholder="Enter Trailer No."
                                [(ngModel)]="selectedTrailerNo"
                            />

                            <app-autocomplete-dropdown
                            *ngIf="trailerSearchableFinal"
                                id="trailerNo"
                                formControlName="trailerNo"
                                class = "autocomplete-dropdown"
                                [placeholder]="'Search Trailer'"
                                [apiUrl]="trailerSearchEndpoint"
                                [selectFields]="trailerSearchSelectFields"
                                [whereFields]="['trailerNumber']"
                                [limit]="10"
                                [orderBy]="{ trailerNumber: 'asc' }"
                                [filterByKey]="'trailerNumber'"
                                [bindLabel]="'trailerNumber'"
                                [bindValue]="'id'"
                                [displayColumns]="[
                                    { key: 'trailerNumber', header: 'Trailer No' },
                                    { key: 'transporterName', header: 'Transporter Name' },
                                ]"
                                [additionalFilters]="trailerAdditionalFilters"
                                (suggestionSelected) = "onTrailerSelected($event)"
                                [(ngModel)]="selectedTrailerId"
                                >
                                </app-autocomplete-dropdown>
                            <div class="form_validation_error" [ngClass]="{ form_error: (gateInForm.get('trailerNo')?.invalid && gateInForm.get('trailerNo')?.touched)}">
                                <small *ngIf="gateInForm.get('trailerNo')?.errors?.['required']">Trailer No is required</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="transporterId" class="form-label">
                                Transporter<span class="required">*</span>
                            </label>
                            <app-autocomplete-dropdown
                                id="transporterId"
                                class = "autocomplete-dropdown"
                                formControlName="transporterId"
                                [placeholder]="'Select Transporter'"
                                [apiUrl]="'v1/vendors/list'"
                                [selectFields]="['id', 'name']"
                                [whereFields]="['name']"
                                [limit]="10"
                                [orderBy]="{ name: 'asc' }"
                                [bindLabel]="'name'"
                                [bindValue]="'id'"
                                [displayColumns]="[
                                    { key: 'name', header: 'Name' },
                                ]"
                                >
                            </app-autocomplete-dropdown>
                            <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('transporterId')?.invalid && gateInForm.get('transporterId')?.touched }">
                                <small *ngIf="gateInForm.get('transporterId')?.errors?.['required']">Transporter is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="trailerTypeId" class="form-label">Trailer Type<span class="required">*</span></label>
                            <app-autocomplete-dropdown
                            #trailerTypeDropdown
                                id="trailerTypeId"
                                name="trailerTypeId"
                                formControlName="trailerTypeId"
                                class = "autocomplete-dropdown"
                                [placeholder]="'Select Trailer Type'"
                                [apiUrl]="'v1/vehicle-types/list'"
                                [selectFields]="['id', 'generalPurposeType',]"
                                [whereFields]="['generalPurposeType']"
                                [limit]="10"
                                [orderBy]="{ generalPurposeType: 'asc' }"
                                [filterByKey]="'generalPurposeType'"
                                [bindLabel]="'generalPurposeType'"
                                [bindValue]="'id'"
                                [disable]="gateInForm.disabled"
                                [displayColumns]="[
                                    { key: 'generalPurposeType', header: 'Vehicle Type' },
                                ]"
                                [additionalFilter]='{
                                        "name": "vehicleType",
                                        "alias": "vehicleType",
                                        "operator": "EQ",
                                        "value": [
                                            "TRAILER"
                                        ]
                                }'
                                >
                            </app-autocomplete-dropdown>
                            <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('trailerTypeId')?.invalid && gateInForm.get('trailerTypeId')?.touched }">
                                <small *ngIf="gateInForm.get('trailerTypeId')?.errors?.['required']">Please select the trailer type</small>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label for="inspectionType" class="form-label">
                                Inspection Type<span class="required">*</span>
                            </label>
                            <select id="inspectionType" formControlName="inspectionType" class="form-select" required >
                                <option value="" disabled>Select</option>
                                <option *ngFor="let option of inspectionTypeOptions" [value]="option.key">{{ option.value }}</option>
                            </select>
                            <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('inspectionType')?.invalid && gateInForm.get('inspectionType')?.touched }">
                                <small *ngIf="gateInForm.get('inspectionType')?.errors?.['required']">Please select the inspection type</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group" >
                        <label for="driverName" class="form-label">
                            Driver Name<span class="required">*</span>
                        </label>
                        <input type="text" id="driverName" formControlName="driverName" class="form-input" value="Vessel" placeholder="Enter Driver Name" required
                        />
                        <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('driverName')?.invalid && gateInForm.get('driverName')?.touched }">
                            <small *ngIf="gateInForm.get('driverName')?.errors?.['required']">Driver Name is required</small>
                        </div>
                    </div>
                        <div class="form-group">
                            <label for="driverLicenseNo" class="form-label">
                            Driver License No<span class="required">*</span>
                            </label>
                            <input type="text" id="driverLicenseNo" formControlName="driverLicenseNo" class="form-input" value="Vessel" placeholder="Enter Driver License no" required
                            />
                            <div class="form_validation_error" [ngClass]="{ form_error: gateInForm.get('driverLicenseNo')?.invalid && gateInForm.get('driverLicenseNo')?.touched }">
                                <small *ngIf="gateInForm.get('driverLicenseNo')?.errors?.['required']">Driver License No is required</small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>

        <!--<section class="container-section container">
            <header class="header section-header">
                <div class="header-content">
                    <div class="header-text">
                        <div class="section-title">Container Details</div>
                        <div class="section-subtitle">Fill in Container(s) information to gate-in</div>
                    </div>
                </div>
            </header>

            <div class = "content-section card-layout">
                <div class="header container-header">
                    <div class="header-content">
                        <div class="header-text">
                            <div class="section-title">Container 1</div>
                        </div>
                        <button class="btn fetch-eir-btn text">
                            <app-svg-icon class="image-icon"
                                [path]="'assets/icons/download_cloud_icon.svg'"
                            ></app-svg-icon>
                            Fetch EIR
                        </button>
                    </div>
                    <div class="divider"></div>
                </div>
                <div class="container-form">
                    <form [formGroup]="gateInForm">
                      <div class="form-grid">
                        <div class="container-form-column">
                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="containerNo" class="form-label">Container No<span class="required">*</span></label>
                                    <app-autocomplete-dropdown
                                        id="containerNo"
                                        name="containerNo"
                                        formControlName="containerNo"
                                        class = "autocomplete-dropdown"
                                        [placeholder]="'Select'"
                                        [apiUrl]="'v1/igm-containers/list'"
                                        [selectFields]="['id', 'containerNo',]"
                                        [whereFields]="['containerNo']"
                                        [limit]="10"
                                        [orderBy]="{ containerNo: 'asc' }"
                                        [filterByKey]="'containerNo'"
                                        [bindLabel]="'containerNo'"
                                        [bindValue]="'id'"
                                        [disable]="gateInForm.disabled"
                                        [displayColumns]="[
                                            { key: 'containerNo', header: 'Container No' },
                                        ]"
                                        >
                                    </app-autocomplete-dropdown>
                                </div>
                                <div class="container-form-group">
                                    <label for="accountHolderId" class="form-label">Account Holder<span class="required">*</span></label>
                                    <app-autocomplete-dropdown
                                        id="accountHolderId"
                                        name="accountHolderId"
                                        formControlName="accountHolderId"
                                        class = "autocomplete-dropdown"
                                        [placeholder]="'Select'"
                                        [apiUrl]="'v1/igm-containers/list'"
                                        [selectFields]="['id', 'containerNo',]"
                                        [whereFields]="['containerNo']"
                                        [limit]="10"
                                        [orderBy]="{ containerNo: 'asc' }"
                                        [filterByKey]="'containerNo'"
                                        [bindLabel]="'containerNo'"
                                        [bindValue]="'id'"
                                        [disable]="gateInForm.disabled"
                                        [displayColumns]="[
                                            { key: 'containerNo', header: 'Container No' },
                                        ]"
                                        >
                                    </app-autocomplete-dropdown>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="shippingLineId" class="form-label">Line Agent<span class="required">*</span></label>
                                    <app-autocomplete-dropdown
                                        id="shippingLineId"
                                        name="shippingLineId"
                                        formControlName="shippingLineId"
                                        class = "autocomplete-dropdown"
                                        [placeholder]="'Select'"
                                        [apiUrl]="'v1/customers/list'"
                                        [selectFields]="['id', 'name', 'customerType']"
                                        [whereFields]="['name']"
                                        [limit]="10"
                                        [orderBy]="{ name: 'asc' }"
                                        [filterByKey]="'name'"
                                        [bindLabel]="'name'"
                                        [bindValue]="'id'"
                                        [disable]="gateInForm.disabled"
                                        [displayColumns]="[
                                            { key: 'name', header: 'Name' },
                                        ]"
                                        >
                                    </app-autocomplete-dropdown>
                                </div>
                                <div class="container-form-group">
                                    <label for="vesselId" class="form-label">Vessel Name<span class="required">*</span></label>
                                    <app-autocomplete-dropdown
                                        id="vesselId"
                                        name="vesselId"
                                        formControlName="vesselId"
                                        class = "autocomplete-dropdown"
                                        [placeholder]="'Select'"
                                        [apiUrl]="'v1/vessel-port-trail-details/list'"
                                        [selectFields]="['id', 'containerNo',]"
                                        [whereFields]="['containerNo']"
                                        [limit]="10"
                                        [orderBy]="{ containerNo: 'asc' }"
                                        [filterByKey]="'containerNo'"
                                        [bindLabel]="'containerNo'"
                                        [bindValue]="'id'"
                                        [disable]="gateInForm.disabled"
                                        [displayColumns]="[
                                            { key: 'containerNo', header: 'Container No' },
                                        ]"
                                        >
                                    </app-autocomplete-dropdown>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-column">
                                    <div class="container-form-group">
                                        <label for="isoCode" class="form-label">ISO Code<span class="required">*</span></label>
                                       <!-- <input type="text" id="containerSize" formControlName="containerSize" class="form-input" required />
                                        <app-autocomplete-dropdown
                                            id="isoCode"
                                            name="isoCode"
                                            formControlName="isoCode"
                                            class = "autocomplete-dropdown"
                                            [placeholder]="'Select'"
                                            [apiUrl]="'v1/size-type-alias/list'"
                                            [selectFields]="['id', 'name', 'customerType']"
                                            [whereFields]="['name']"
                                            [limit]="10"
                                            [orderBy]="{ name: 'asc' }"
                                            [filterByKey]="'name'"
                                            [bindLabel]="'name'"
                                            [bindValue]="'id'"
                                            [disable]="gateInForm.disabled"
                                            [displayColumns]="[
                                                { key: 'name', header: 'Name' },
                                            ]"
                                            >
                                        </app-autocomplete-dropdown>
                                    </div>
                                </div>
                                <div class="container-form-column">
                                    <div class="container-form-row">
                                        <div class="container-form-group">
                                            <label for="containerSize" class="form-label">
                                                Size<span class="required">*</span>
                                            </label>
                                            <input type="text" id="containerSize" formControlName="containerSize" class="form-input" required />
                                        </div>
                                        <div class="container-form-group">
                                            <label for="containerType" class="form-label">
                                                Type<span class="required">*</span>
                                            </label>
                                            <input type="text" id="containerType" formControlName="containerType" class="form-input" required />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                <label for="lineSealNo" class="form-label">
                                    Line Seal<span class="required">*</span>
                                </label>
                                <input type="text" id="lineSealNo" formControlName="lineSealNo" class="form-input" placeholder="Enter line seal no" required
                                />
                                <div class="form_validation_error" *ngIf="gateInForm.get('lineSealNo')?.invalid && gateInForm.get('lineSealNo')?.touched">
                                    <small *ngIf="gateInForm.get('lineSealNo')?.errors?.['required']">Driver Name is required</small>
                                </div>
                            </div>
                                <div class="container-form-group">
                                    <label for="physicalSealNo" class="form-label">
                                    Physical Seal<span class="required">*</span>
                                    </label>
                                    <input type="text" id="physicalSealNo" formControlName="physicalSealNo" class="form-input" value="Vessel" placeholder="Enter pysical seal no" required
                                    />
                                    <div class="form_validation_error" *ngIf="gateInForm.get('physicalSealNo')?.invalid && gateInForm.get('physicalSealNo')?.touched">
                                        <small *ngIf="gateInForm.get('physicalSealNo')?.errors?.['required']">Driver License No is required</small>
                                    </div>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                <label for="tareWeight" class="form-label">
                                    Tare Weight<span class="required">*</span>
                                </label>
                                <input type="text" id="tareWeight" formControlName="tareWeight" class="form-input" placeholder="Enter tare weight" required
                                />
                                <div class="form_validation_error" *ngIf="gateInForm.get('tareWeight')?.invalid && gateInForm.get('tareWeight')?.touched">
                                    <small *ngIf="gateInForm.get('tareWeight')?.errors?.['required']">Driver Name is required</small>
                                </div>
                            </div>
                                <div class="container-form-group">
                                    <label for="grossWeight" class="form-label">
                                    Gross Weight<span class="required">*</span>
                                    </label>
                                    <input type="text" id="grossWeight" formControlName="grossWeight" class="form-input" value="Vessel" placeholder="Enter gross weight" required
                                    />
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="eirNo" class="form-label">EIR No<span class="required">*</span></label>
                                    <input type="text" id="eirNo" formControlName="eirNo" class="form-input" required />
                                </div>
                                <div class="container-form-group">
                                    <label for="eirDate" class="form-label">
                                        EIR Date<span class="required">*</span>
                                    </label>
                                    <input type="datetime-local" id="eirDate" formControlName="eirDate" class="form-input" required />
                                </div>
                            </div>
                        </div>

                        <div class="container-form-column">
                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="scanType" class="form-label">
                                        Scan Type<span class="required">*</span>
                                    </label>
                                    <select id="scanType" formControlName="scanType" class="form-select" required>
                                        <option value="" disabled>Select</option>
                                        <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                                    </select>
                                </div>
                                <div class="container-form-group">
                                    <label for="scanTypeActual" class="form-label">
                                        Scan Type Actual<span class="required">*</span>
                                    </label>
                                    <select id="scanTypeActual" formControlName="scanType" class="form-select" required>
                                        <option value="" disabled>Select</option>
                                        <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                                    </select>
                                </div>
                                <div class="container-form-group">
                                    <label for="scanStatus" class="form-label">Scan Status<span class="required">*</span></label>
                                    <select id="scanStatus" formControlName="scanStatus" class="form-select" required>
                                        <option value="" disabled>Select</option>
                                        <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <app-toggle toggleId="hazardous"
                                    label="Hazardous"
                                    formControlName="hazardous"></app-toggle>
                                </div>
                                <div class="container-form-group">
                                    <app-toggle toggleId="odc"
                                    label="ODC"
                                    formControlName="odc"></app-toggle>
                                </div>
                                <div class="container-form-group">
                                    <app-toggle toggleId="reeferPlugin"
                                        label="Reefer Plugin"
                                        formControlName="reeferPlugin">
                                    </app-toggle>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="setTemp" class="form-label">
                                        Set Temperature<span class="required">*</span>
                                    </label>
                                    <input type="text" id="setTemp" formControlName="setTemp" class="form-input" required />
                                </div>
                                <div class="container-form-group">
                                    <label for="temp" class="form-label">
                                        Temperature<span class="required">*</span>
                                    </label>
                                    <input type="text" id="temp" formControlName="temp" class="form-input" required />
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="fromLocationId" class="form-label">
                                        From Location<span class="required">*</span>
                                    </label>
                                    <app-autocomplete-dropdown
                                        id="fromLocationId"
                                        name="fromLocationId"
                                        formControlName="fromLocationId"
                                        class = "autocomplete-dropdown"
                                        [placeholder]="'Select'"
                                        [apiUrl]="'v1/size-type-alias/list'"
                                        [selectFields]="['id', 'name', 'customerType']"
                                        [whereFields]="['name']"
                                        [limit]="10"
                                        [orderBy]="{ name: 'asc' }"
                                        [filterByKey]="'name'"
                                        [bindLabel]="'name'"
                                        [bindValue]="'id'"
                                        [disable]="gateInForm.disabled"
                                        [displayColumns]="[
                                            { key: 'name', header: 'Name' },
                                        ]"
                                        >
                                    </app-autocomplete-dropdown>
                                </div>
                                <div class="container-form-group">
                                    <label for="condition" class="form-label">
                                        Condition<span class="required">*</span>
                                    </label>
                                    <select id="condition" formControlName="condition" class="form-select" required>
                                        <option value="" disabled>Select</option>
                                        <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="container-form-row">
                                <div class="container-form-group">
                                    <label for="remarks" class="form-label">Remarks<span class="required">*</span></label>
                                    <textarea class="form-text-area" id="remarks" name="remarks" placeholder="Enter remarks" required></textarea>
                                </div>
                            </div>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
        </section>-->

        <section class="container-section section" *ngIf="showContainerForm">
            <header class="header section-header">
                <div class="header-content">
                    <div class="header-text">
                        <div class="section-title">Container Details</div>
                        <div class="section-subtitle">Fill in Container(s) information to gate-in</div>
                    </div>
                </div>
            </header>
            <div *ngFor="let form of containerFormList.controls; let i = index">
                <app-container-form
                #containerForms
                [containerForm]="form"
                [gateInMode]="getGateInMode()"
                [index]="i"
                [eirImageSource]="getEirImage(i)"
                (isoCodeSelection)="evaluateContainerRules()"
                (remove)="removeContainerForm(i)"
                >
                </app-container-form>
            </div>
        </section>

    </div>
    <button class="btn add-container-btn" *ngIf="canAddMoreContainers" (click)="addItem()">
        <app-svg-icon class="image-icon"
            [path]="'assets/icons/add_plus_icon.svg'"
        ></app-svg-icon>
        <span>Add Container</span>
    </button>
</div>
