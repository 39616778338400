import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { StatsComponent } from "../../../../stats/stats.component";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { Container } from "../../../../../models/container.interface";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { Stats } from "../../../../../models/stats.interface";
import { JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { Tally } from "../../../../../models/tally.interface";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import DestuffingTallyFormConfig from "../config/DestuffingTallyFormConfig.json";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { Router } from "@angular/router";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";
import { ConfirmDialogV2Component } from "../../../../confirm-dialog/confirm-dialog-v2.component";
import { getTallyTypeByJobOrderType, TallyType } from "../../../../../constants/operations-contsants";
import { InventoryType } from "../../../../../constants/gate-ops-constants";
import { ManageTally } from "../manage-tally.interface";
import { ToasterService } from "../../../../../services/toaster.service";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { forkJoin } from "rxjs";
import { UserListDataResponse } from "../../../../../models/user-list-data-response";

@Component({
  selector: 'app-manage-tally-destuffing-lcl',
  standalone: true,
  imports: [
    ItemSelectorPanelComponent,
    StatsComponent
  ],
  templateUrl: './manage-tally-destuffing-lcl.component.html',
  styleUrl: './manage-tally-destuffing-lcl.component.scss'
})
export class ManageTallyDestuffingLclComponent implements ManageTally{
  container!: Container;
  itemList: any[] | undefined = []
  dataLoaded: boolean = false;
  destuffingTallyForm!: FormGroup;
  destuffingTallyConfig!: DynamicForm;
  inventoryDetailStats: Stats[] = [];
  containerStats: Stats[] = [];
  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;
  tallyData!: Tally;
  selectedItems: any[] = [];
  savedTallyData: any[] = [];

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;

  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @Input() jobOrderStats!: Stats[];

  constructor(private router: Router,
              private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private viewContainerRef: ViewContainerRef,
              private toasterService: ToasterService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadingService.show()
    this.destuffingTallyConfig = DestuffingTallyFormConfig.destuffingTallyFormElement;
    this.destuffingTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {
      // this.itemSelectorComponent?.clearLeftAndRightPane()
      this.destuffingTallyForm = this.fb.group({
        tallyDetails: this.fb.array([])
      });

      forkJoin([
        this.operationService.getJobOrderDetails(this.jobOrderInventoryDetails?.jobOrderId, true, true, true),
        this.operationService.listTalliesByJobOrder(this.jobOrderInventoryDetails?.jobOrderNo)
      ]).subscribe({
        next: ([jobOrderDetailsResponse, talliesResponse]: [any,any]) => {
          const data: JobOrderDetails = jobOrderDetailsResponse?.data;
          this.container = data.containerList[0];
          this.itemList = data.containerList[0]?.importItemList?.map((item) => {
            return {
              ...item,
              disableCheckBox: !!talliesResponse.data.find((tally: { inventoryId: string }) => tally.inventoryId === item.id)
            };
          });
          this.createContainerStats(this.container);
          // this.addTallyDetailsFromArrayForAssociatedContainers(this.itemList);

          this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;
          this.dataLoaded = true;
          this.loadingService.hide();

          this.savedTallyData = talliesResponse?.data;
        },
        error: (error: any) => {
          console.log(error);
          this.loadingService.hide();
        }
      });
    }
  }

  private addTallyDetailsFromArrayForAssociatedContainers(items: CargoItem | undefined) {
      this.dynamicFormService.addToFormArray(this.destuffingTallyForm, "tallyDetails", this.destuffingTallyConfig);
  }

  get itemFormArray() {
    return this.destuffingTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: CargoItem, index: number }) {
    this.changeDetectorRef.detectChanges()
    this.addTallyDetailsFromArrayForAssociatedContainers(event.item);
    this.createStatsForInventoryDetails(event.item);

    const formGroup = this.itemFormArray.at(event.index) as FormGroup
    this.patchSavedTallyData(event, formGroup);
    formGroup.get("cargoId")?.setValue(event.item.id);
    formGroup.get("inventoryNo")?.setValue(this.jobOrderInventoryDetails.inventoryNo);
    formGroup.get("inventoryId")?.setValue(this.jobOrderInventoryDetails.inventoryId);
    formGroup.get("documentNo")?.setValue(event.item.igmNo);

    const componentsToLoad: any = [
      { component: StatsHorizontalComponent, data: { stats: this.inventoryDetailStats } },
      { component: DynamicFormComponent, data: { formConfig: this.destuffingTallyConfig, formGroup} }
    ];

    if (this.jobOrderVendorsGroupByInventoryId[event.item?.id]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.id] }
      });
    }

    this.itemSelectorComponent?.loadComponents(componentsToLoad);
  }

  private patchSavedTallyData(event: { item: any; index: number }, formGroup: FormGroup<any>) {
    if (this.savedTallyData?.length === 0) return;

    const filteredTallyData = this.savedTallyData.find((tally: { inventoryId: string }) => tally.inventoryId === event.item.id);
    if (filteredTallyData?.length === 0) return;

    formGroup.patchValue({
      ...filteredTallyData,
      startTime: this.formatDateTimeForInput(filteredTallyData.startTime),
      endTime: this.formatDateTimeForInput(filteredTallyData.endTime)
    });
    formGroup.disable();

  }

  private formatDateTimeForInput(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date.toISOString().slice(0, 16); // This will give you YYYY-MM-DDThh:mm
  }

  private filterAndValidateTallyForm(): boolean {
    const tallyDetailsFormGroup = this.destuffingTallyForm.get("tallyDetails") as FormArray;
    if (tallyDetailsFormGroup.length === 0 || this.selectedItems.length === 0) {
      this.toasterService.error("Please select at least one item to save the tally");
      return false;
    }

    this.destuffingTallyForm.markAllAsTouched();
    const invalidSelectedItems = this.itemFormArray.controls
      .filter((control) => this.selectedItems.includes(control.get("inventoryId")?.value))
      .some((control) => control.invalid);

    if (invalidSelectedItems) {
      //TODO: logic to go through all selectedItems and show the error notifications on the left panel
      this.toasterService.error("Please fill all required fields to save the tally");
      console.log("Form is invalid", this.destuffingTallyForm);
      return false;
    }

    return true;
  }

  private createStatsForInventoryDetails(cargoItem: CargoItem) {
    this.inventoryDetailStats = [{
      displayLabel: "Item No.",
      value: cargoItem?.itemNo?.toString(),
      iconUrl: "assets/icons/package_icon.svg"
    }, {
      displayLabel: "Sub Item No.",
      value: cargoItem?.subItemNo?.toString()
    }, {
      displayLabel: "HBL No.",
      value: cargoItem?.hblNo?.toString()
    }, {
      displayLabel: "Importer",
      value: cargoItem?.importerFromIgm
    },
      {
        displayLabel: "Consignee",
        value: cargoItem?.consignee
      }, {
        displayLabel: "Package Manifested",
        value: cargoItem?.numberOfPackages?.toString()
      },
      {
        displayLabel: "Weight Manifested",
        value: cargoItem?.grossWeight?.toString()
      }

    ];
  }



  private createContainerStats(container: Container) {
    this.containerStats = [{
      displayLabel: "Container No.",
      value: container?.containerNo?.toString()
    },
      {
        displayLabel: "Size",
        value: container.containerSize
      },
      {
        displayLabel: "Type",
        value: container?.containerType
      },
      {
        displayLabel: "ISO Code",
        value: container?.isoCode
      },
      {
        displayLabel: "Gross Wt",
        value: container?.grossWeight?.toString()
      },
      {
        displayLabel: "Gross Volume",
        value: ""
      },
      {
        displayLabel: "NOP",
        value: ""
      },
      {
        displayLabel: "Line Seal No",
        value: container.lineSealNo
      }
    ];
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  cancel(): void {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? ";
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  save(): void {
    if(!this.filterAndValidateTallyForm()) return;
    console.log(this.destuffingTallyForm.value)
    this.tallyData = {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      tallyType: getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType).code,
      tallyItems: this.destuffingTallyForm.value.tallyDetails
        .filter((detail: { cargoId: any; }) => this.selectedItems.includes(detail.cargoId))
        .map((detail: any) => ({
        ...detail,
        type: "destuffing_tally",
        inventoryType: InventoryType.CONTAINER
      }))
    };
    console.log(this.tallyData);
    this.saveTally();
  }

  private saveTally() {
    this.loadingService.show()
    this.operationService.saveTally(this.tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide()
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc)
        this.loadingService.hide()
      }
    });
  }

  handleCheckBoxSelection(event: any) {
    this.selectedItems = Array.from(event)
  }
}
