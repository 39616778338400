import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../modules/shared/shared.module';

@Component({
  selector: 'app-survey-image-viewer',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './survey-image-viewer.component.html',
  styleUrl: './survey-image-viewer.component.scss'
})
export class SurveyImageViewerComponent {

  @Input() images!: any[];

  tabs: any[] = [
    { id: 'FRONT-CAM', label: 'Front Cam', isActive: true },
    { id: 'REAR-CAM', label: 'Rear Cam', isActive: false },
    { id: 'TOP-CAM', label: 'Top Cam', isActive: false },
    { id: 'LEFT-CAM', label: 'Left Cam', isActive: false },
    { id: 'RIGHT-CAM', label: 'Right Cam', isActive: false }
  ];

  activeImages: string[] = [];

  ngOnInit(): void {
    console.log("Images: ", this.images);
    this.updateActiveImages('FRONT-CAM');
  }

  private updateActiveImages(camId: string): void {
    const selectedCam = this.images.find(image => image.camId.includes(camId));
    this.activeImages = selectedCam ? selectedCam.s3Path : [];
  }

  selectTab(tabId: string): void {
    this.tabs = this.tabs.map(tab => ({
      ...tab,
      isActive: tab.id === tabId
    }));
    this.updateActiveImages(tabId);
  }
}
