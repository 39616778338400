{
"formElements": {
    "formRows": [
        {
            "formGroups": [
            {
                "style": {
                "gridColumn": "span 6"
                },
                "control": {
                    "label": "code",
                    "displayLabel": "Service Code",
                    "disabled": false,
                    "placeHolder": "Enter Service Code",
                    "fieldInfo": {
                        "dataType": "text"
                    },
                    "validators": [
                        {
                        "type": "required",
                        "message": "Service Code is required"
                        }
                    ]
                }
            },
            {
                "style": {
                    "gridColumn": "span 6"
                },
                "control": {
                    "label": "name",
                    "displayLabel": "Service Name",
                    "disabled": false,
                    "placeHolder": "Enter Service Name",
                    "fieldInfo": {
                        "dataType": "text"
                    },
                    "validators": [
                        {
                        "type": "required",
                        "message": "Expiry Date is required"
                        }
                    ]
                }
            }
            ]
        },
        {
            "formGroups": [
            {
                "style": {
                    "gridColumn": "span 6"
                },
                "control": {
                    "label": "serviceType",
                    "displayLabel": "Service Type",
                    "disabled": false,
                    "placeHolder": "Select Service Type",
                    "fieldInfo": {
                        "dataType": "select",
                        "options": [
                            {
                                "label": "General Service",
                                "value": "GENERAL_SERVICE"
                            },
                            {
                                "label": "SSR Service",
                                "value": "SSR_SERVICE"
                            }
                        ]
                    },
                    "validators": [
                        {
                        "type": "required",
                        "message": "Service Type is required"
                        }
                    ]
                }
            },
            {
                "style": {
                "gridColumn": "span 6"
                },
                "control": {
                    "label": "entityType",
                    "displayLabel": "Entity Type",
                    "disabled": false,
                    "placeHolder": "Select Entity Type",
                    "fieldInfo": {
                        "dataType": "select",
                        "options": [
                            {
                                "label": "Cargo",
                                "value": "CARGO"
                            },
                            {
                                "label": "Container",
                                "value": "CONTAINER"
                            }
                        ]
                    },
                    "validators": [
                        {
                        "type": "required",
                        "message": "Entity Type is required"
                        }
                    ]
                }
            }
            ]
        },
        {
            "formGroups": [
                {
                    "style": {
                    "gridColumn": "span 6"
                    },
                    "control": {
                        "label": "chargeId",
                        "displayLabel": "Charge",
                        "disabled": false,
                        "placeHolder": "Select Charge",
                        "fieldInfo": {
                            "dataType": "picklist",
                            "lookupDetails": {
                            "apiUrl": "v1/charges/list",
                            "selectFields": ["id", "name", "code"],
                            "whereFields": ["name", "code"],
                            "limit": 10,
                            "orderBy": { "name": "asc" },
                            "filterByKey": "name",
                            "bindLabel": "name",
                            "bindValue": "id",
                            "displayColumns": [
                                { "key": "code", "header": "Charge Code" },
                                { "key": "name", "header": "Charge Name" }
                            ]
                            }
                        }
                    }
                }
            ]
        },
        {
            "formGroups":[
                {
                    "style": {
                    "gridColumn": "span 6"
                    },
                    "control": {
                        "label": "active",
                        "skipLabel": true,
                        "displayLabel": "Active",
                        "defaultValue": true, 
                        "disabled": false,
                        "fieldInfo": {
                            "dataType": "boolean"
                        }
                    }
                }
            ]
        }
    ]
  },
  "tableHeaders": [
      {
        "fieldName": "equipmentName",
        "displayName": "Equipment Name"
      },
      {
        "fieldName": "active",
        "displayName": "Active"
      }
    ]
}