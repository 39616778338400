<div class="stats">
  <div class="stats_header">{{ title }}</div>
  <div class="stats_content">

    @for (stat of stats; track $index) {
      <div class="stats_details" [ngClass]="{
        'stats_details_first': $index === 0,
      }">
        @if ($index === 0) {
          <div class="header_icon" [ngStyle]="{'backgroundColor': headerIconBgColor}">
            <app-svg-icon [path]="headerIconUrl" class="icon" [ngStyle]="{'backgroundColor': headerIconColor}"></app-svg-icon>
          </div>
          <div class='stats_title_and_desc_first'>
            <div class="stats_title">{{ stat.displayLabel }}</div>
            <div class="stats_desc">
              <div class="icon_container">
                <app-svg-icon [path]="stat?.iconUrl" class="icon"></app-svg-icon>
              </div>
              @if (stat?.dateType === "date") {
                {{ stat.value | date: 'dd/MM/yyyy' }}
              } @else {
                {{ stat.value || "-" }}
              }
            </div>
          </div>
        } @else {
          <div class="stats_title">{{ stat.displayLabel }}</div>
          <div class="stats_desc">
            <div class="icon_container">
              <app-svg-icon [path]="stat?.iconUrl" class="icon"></app-svg-icon>
            </div>
            @if (stat?.dateType === "date") {
              {{ stat.value | date: 'dd/MM/yyyy' }}
            } @else {
              {{ stat.value || "-" }}
            }
          </div>
        }

      </div>
    }
  </div>
</div>

