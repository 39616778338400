import { Component, Input } from "@angular/core";
import { JobOrderVendor, JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";

@Component({
  selector: 'app-vendor-details',
  standalone: true,
  imports: [],
  templateUrl: './vendor-details.component.html',
  styleUrl: './vendor-details.component.scss'
})
export class VendorDetailsComponent {
  @Input() jobOrderVendors: JobOrderVendor[] = []

}
