{
  "destuffingTallyFormElement":{
    "formRows": [
      {
        "formGroups": [
          {
            "control": {
              "hidden": true,
              "label": "inventoryId",
              "displayLabel": "Inventory ID",
              "disabled": false,
              "placedHolder": "Enter Inventory Id",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryNo",
              "displayLabel": "Inventory No",
              "disabled": false,
              "placedHolder": "Enter Inventory No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentNo",
              "displayLabel": "Document No",
              "disabled": false,
              "placedHolder": "Enter Document No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "targetInventoryType",
              "disabled": false,
              "defaultValue": "WAREHOUSE",
              "fieldInfo": {
                "dataType": "string"
              }
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "cargoId",
              "displayLabel": "Cargo Id",
              "disabled": false,
              "placedHolder": "Enter Cargo No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "startTime",
              "displayLabel": "Activity Start Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Start Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "endTime",
              "displayLabel": "Activity End Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "End Time is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "sealCuteDate",
              "displayLabel": "Seal Cute Date",
              "placeHolder": "Enter Seal Cut Date",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "date"
              },
              "validators": [
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "condition",
              "displayLabel": "Condition",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "select",
                "options": [
                  {
                    "label": "Bad",
                    "value": "BAD"
                  },
                  {
                    "label": "Not Survey",
                    "value": "NOT_SURVEY"
                  },
                  {
                    "label": "Sound",
                    "value": "SOUND"
                  },
                  {
                    "label": "Damaged",
                    "value": "DAMAGED"
                  }
                ]
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Condition is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "cargoType",
              "displayLabel": "Cargo Type",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "select",
                "options": [
                  {
                    "label": "Hazardous",
                    "value": "HAZARDOUS"
                  },
                  {
                    "label": "Non-hazardous",
                    "value": "NON_HAZARDOUS"
                  }
                ]
              },
              "validators": [

              ]
            }
          }

        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "reserved",
              "displayLabel": "Reserved",
              "placeHolder": "Enter Reserved",
              "disabled": false,
              "fieldInfo": {
                "dataType": "boolean"
              }
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "receivedPackages",
              "displayLabel": "Package Received",
              "placeHolder": "Enter Package Received",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Package Received is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "receivedWeight",
              "displayLabel": "Weight Received",
              "placeHolder": "Enter Weight Received",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "targetInventoryNo",
              "displayLabel": "Warehouse",
              "disabled": false,
              "placeHolder": "Enter Warehouse",
              "fieldInfo": {
                "dataType": "text"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Warehouse is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "gridLocation",
              "displayLabel": "Grid Location",
              "disabled": false,
              "placeHolder": "Enter Grid Location",
              "fieldInfo": {
                "dataType": "text"
              },
              "validators": [
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "areaOccupied",
              "displayLabel": "Area (sq mt)",
              "placeHolder": "Enter Area (sq mt)",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Warehouse is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "containerCBM",
              "displayLabel": "Container CBM",
              "placeHolder": "Enter Container CBM",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "shortExcess",
              "displayLabel": "Short/Excess",
              "disabled": false,
              "placeHolder": "Enter Short/Excess",
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "goodsDescription",
              "displayLabel": "Goods Description",
              "placeHolder": "Enter Goods Description",
              "disabled": false,
              "fieldInfo": {
                "dataType": "text"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 9"
            },
            "control": {
              "label": "remarks",
              "placeHolder": "Enter Remarks",
              "displayLabel": "Remarks",
              "disabled": false,
              "fieldInfo": {
                "dataType": "textarea"
              },
              "validators": [
              ]
            }
          }
        ]
      }
    ]
  },
  "destuffingTallyTruckFromElement":  {
    "formRows": [
      {
        "formGroups": [
          {
            "control": {
              "hidden": true,
              "label": "inventoryId",
              "displayLabel": "Inventory ID",
              "disabled": false,
              "placedHolder": "Enter Inventory Id",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryNo",
              "displayLabel": "Inventory No",
              "disabled": false,
              "placedHolder": "Enter Inventory No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryType",
              "displayLabel": "Inventory Type",
              "disabled": false,
              "placedHolder": "Enter Inventory Type",
              "defaultValue": "TRUCK",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentNo",
              "displayLabel": "Document No",
              "disabled": false,
              "placedHolder": "Enter Document No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentType",
              "displayLabel": "Document Type",
              "disabled": false,
              "placedHolder": "Enter Document Type",
              "defaultValue": "IGM_ITEM_NUMBER",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "cargoId",
              "displayLabel": "Cargo Id",
              "disabled": false,
              "placedHolder": "Enter Cargo No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "startTime",
              "displayLabel": "Activity Start Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Start Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "endTime",
              "displayLabel": "Activity End Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "End Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "condition",
              "displayLabel": "Condition",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "select",
                "options": [
                  {
                    "label": "Bad",
                    "value": "BAD"
                  },
                  {
                    "label": "Not Survey",
                    "value": "NOT_SURVEY"
                  },
                  {
                    "label": "Sound",
                    "value": "SOUND"
                  },
                  {
                    "label": "Damaged",
                    "value": "DAMAGED"
                  }
                ]
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Condition is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 6"
            },
            "control": {
              "label": "remarks",
              "placeHolder": "Enter Remarks",
              "displayLabel": "Remarks",
              "disabled": false,
              "fieldInfo": {
                "dataType": "textarea"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Remarks is required"
                }
              ]
            }
          }
        ]
      }
    ]
  },
  "destufffingTallyTableConfig": [
    {
      "label": "containerNo",
      "displayLabel": "Container",
      "editable": false,
      "isPinned": true
    },
    {
      "label": "containerSize",
      "displayLabel": "Size",
      "editable": false
    },
    {
      "label": "containerType",
      "displayLabel": "Type",
      "editable": false
    },
    {
      "label": "arrivalDate",
      "displayLabel": "Arrival Date",
      "editable": false
    },
    {
      "label": "hazardous",
      "displayLabel": "hazardous",
      "editable": false,
      "fieldInfo": {
        "dataType": "boolean"
      }
    },
    {
      "label": "deliveryMode",
      "displayLabel": "Delivery Mode",
      "placeholder": "Enter Delivery Mode",
      "editable": false,
      "fieldInfo": {
        "dataType": "select",
        "options": [
          {
            "value": "FACTORY",
            "label": "Factory Delivery"
          },
          {
            "value": "DOCK",
            "label": "Dock Delivery"
          }
        ]
      }
    },
    {
      "label": "deliveryModeFromDO",
      "displayLabel": "Delivery Mode From DO",
      "placeholder": "Enter Delivery Mode From DO",
      "editable": false,
      "fieldInfo": {
        "dataType": "select",
        "options": [
          {
            "value": "FACTORY",
            "label": "Factory Delivery"
          },
          {
            "value": "DOCK",
            "label": "Dock Delivery"
          }
        ]
      }
    },
    {
      "label": "doNo",
      "displayLabel": "Delivery Order No",
      "placeholder": "Enter Delivery Order No",
      "editable": false,
      "fieldInfo": {
        "dataType": "text"
      }
    },
    {
      "label": "doDate",
      "displayLabel": "DO Date",
      "placeholder": "Enter Delivery Order Date",
      "editable": false,
      "fieldInfo": {
        "dataType": "date"
      }
    },
    {
      "label": "doValidityDate",
      "displayLabel": "DO Validity Date",
      "editable": false,
      "fieldInfo": {
        "dataType": "date"
      }
    },
    {
      "label": "toLocationId",
      "displayLabel": "Empty Drop Yard",
      "placeholder": "Enter Empty Drop Yard",
      "id": "toLocationId",
      "hasLookup": true,
      "fieldInfo": {
        "dataType": "picklist",
        "lookupDetails": {
          "apiUrl": "v1/locations/list",
          "selectFields": ["id", "name", "panNumber"],
          "whereFields": ["name", "panNumber"],
          "limit": 10,
          "orderBy": { "name": "asc" },
          "filterByKey": "name",
          "bindLabel": "name",
          "bindValue": "id",
          "responseBindValue": "toLocationId",
          "responseBindLabel": "toLocationName",
          "displayColumns": [
            { "key": "name", "header": "Name" },
            { "key": "panNumber", "header": "PAN Number" }
          ]
        }
      },
      "editable": false
    },
    {
      "label": "totalPackages",
      "displayLabel": "Total Packages",
      "editable": false
    },
    {
      "label": "cargoWeight",
      "displayLabel": "Cargo wt",
      "editable": false
    },
    {
      "label": "tareWeight",
      "displayLabel": "Tare wt",
      "editable": false
    },
    {
      "label": "grossWeight",
      "displayLabel": "Gross wt",
      "editable": false
    },
    {
      "label": "deliveryNOP",
      "displayLabel": "Delivery NOP",
      "placeholder": "Enter Delivery NOP",
      "editable": true,
      "fieldInfo": {
        "dataType": "number"
      }
    },
    {
      "label": "deliveryGrossWt",
      "displayLabel": "Delivery Gross WT",
      "placeholder": "Enter Delivery Gross WT",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    },
    {
      "label": "deliveryGrossVolume",
      "displayLabel": "Delivery Gross Volume",
      "placeholder": "Enter Delivery Gross Volume",
      "editable": true,
      "fieldInfo": {
        "dataType": "text"
      }
    }
  ]
}
