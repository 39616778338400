<div class="container">
  <!-- Header -->
  <div class="header">
    
    <!-- <div class="header-title">Create New Charge</div> -->

    <span class="header-title">
      @if(editingEnabled) {Edit} @else {Create New} Charge
    </span>
    
    <!-- <span>
      @if (readOnlyMode) { View } @else if(masterEditingEnabled) {  {Create New } @else
      {Create New} Bank
    </span> -->

    <div class="header-actions">
      <button class="btn secondary_button" (click)="cancel()">Cancel</button>
      <button class="btn primary_button" (click)="save()">Save</button>
    </div>
  </div>

  <!-- Content -->
  <div class="content">
    <app-dynamic-form [formConfig]="dynamicForm" [formGroup]="form"></app-dynamic-form>
  </div>
</div>