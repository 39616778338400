import { Component,
  ElementRef,
  Input,
  QueryList,
  ViewContainerRef,
  ViewChild,
  ViewChildren, } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OperationService } from '../../../../services/operations.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ConfirmDialogV2Component } from '../../../confirm-dialog/confirm-dialog-v2.component';
import { LoadingService } from '../../../../services/loading.service';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { CheckboxComponent } from '../../../common/checkbox/checkbox.component';
import {VehicleType} from "../../../../models/vehicle-type.interface"
import { InventoryType, Lane, getGateInOptions } from '../../../../constants/gate-ops-constants';
import { GateInRequest } from '../../../../models/gate-in-request.interface';
import { ToasterService } from '../../../../services/toaster.service';
import { AutocompleteDropdownComponentValidator } from "../../../../validators/autoCompleteValidator";
import { CycleType } from '../../../../constants/app-constants';
import { convertEpochToISTDateTime, getMaxDateTimeForDatePicker } from '../../../../utils/date-time-utils';
import { DateTimeValidator } from '../../../../validators/dateTimeValidator';
import { JobOrderBannerComponent } from '../../../common/job-order-banner/job-order-banner.component';
import { DateUtils } from '../../../../utils/date-utils';

@Component({
  selector: 'app-trailer-gate-in',
  standalone: true,
  imports: [
    SharedModule,
    FormsModule,
    AutocompleteDropdownComponent,
    CheckboxComponent,
    JobOrderBannerComponent
  ],
  templateUrl: './trailer-gate-in.component.html',
  styleUrl: './trailer-gate-in.component.scss'
})
export class TrailerGateInComponent {
  gateInForm: FormGroup;
  ownVehicle: boolean = false;
  isOwnMovement: boolean = false;
  isOwnMovementDisabled: boolean = false;
  inspectionTypeRequired: boolean = false;
  selectedTrailerId: string | null = null;
  selectedTrailerNo: string | null = null;
  maxDateTime: string = '';
  selectedJobOrder: any = {};
  
  laneOptions = Object.values(Lane);
  cycleOptions = Object.values(CycleType);
  inspectionTypeOptions = [
    { value: 'On Wheel', key: 'ON_WHEEL' },
    { value: 'On Ground', key: 'ON_GROUND' },
  ];

  supportedGateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];
  gateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
    public trailerTypeDataRes?: VehicleType;

  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    private toasterService: ToasterService,
  ) {
    this.gateInForm = this.fb.group({
      cycle: ['', Validators.required],
      gateInMode: ['', Validators.required],
      laneNo: ['', Validators.required],
      ownVehicle: [false],
      ownMovement: [this.isOwnMovement],
      trailerId: [''],
      trailerMasterId: [''],
      trailerNo: ['', Validators.required],
      transporterId: ['', AutocompleteDropdownComponentValidator()],
      trailerTypeId: [{key: '', value: ''}, [AutocompleteDropdownComponentValidator()]],
      inspectionType: [''],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required],
      gateInTime: ['', [Validators.required, DateTimeValidator()]],
      jobOrderNo: ['']
    });
  }
  ngOnInit(): void {
    this.loadDropdownData();
    this.updateGateInTime();
  }

  loadDropdownData(): void {
      this.supportedGateInOptions = getGateInOptions(InventoryType.TRAILER);
      console.log('Gate In Options:', this.gateInOptions);
      this.gateInForm.get('gateInMode')?.disable();
      this.updateGateInOptions(this.gateInForm.get('cycle')?.value);
  }

  onCycleChange(event: Event): void {
    const selectedCycle = (event.target as HTMLSelectElement).value;
    this.updateGateInOptions(selectedCycle);
  }

  updateGateInOptions(selectedCycle: string) {
    if (!selectedCycle) return;
    this.gateInOptions = this.supportedGateInOptions.filter(option =>
      !option.cycle || option.cycle.key === selectedCycle
    );
    this.gateInForm.get('gateInMode')?.setValue('');
    this.gateInForm.get('gateInMode')?.enable();
    this.inspectionTypeRequired = false;
  }

  onGateInModeChange(event: Event): void {
    const selectedMode = (event.target as HTMLSelectElement).value;
    this.inspectionTypeRequired = false;
    if (selectedMode.includes('BREAK_BULK')) this.inspectionTypeRequired = true;
  }
 
  onOwnVehicleChange(ownVehicleChecked: boolean): void {
    this.gateInForm.get('ownVehicle')?.setValue(ownVehicleChecked);
    this.ownVehicle = ownVehicleChecked;
    if (ownVehicleChecked) {
      this.isOwnMovementDisabled = true;
      this.isOwnMovement = true;
      this.gateInForm.get('ownMovement')?.setValue(true);
      this.gateInForm.get('ownMovement')?.disable();
      this.selectedTrailerNo = null;
      this.selectedTrailerId = null;
    } else {
      this.isOwnMovementDisabled = false;
      this.selectedTrailerId = null;
      this.gateInForm.get('ownMovement')?.enable();
    }

    this.gateInForm.get('trailerNo')?.setValidators([
      Validators.required,
      this.ownVehicle ? AutocompleteDropdownComponentValidator() : Validators.nullValidator
    ]);
    this.gateInForm.get('trailerNo')?.updateValueAndValidity();
  }

  onOwnMovementChange(ownMovementChecked: boolean): void {
    this.gateInForm.get('ownMovement')?.setValue(ownMovementChecked);
  }

  onTrailerSelected(selectedItem: any) {
    this.gateInForm.get('transporterId')?.setValue({key: selectedItem.vendorId, value: selectedItem.vendorName});
    this.gateInForm.get('trailerTypeId')?.setValue({ key : selectedItem.vehicleTypeId, value: selectedItem.vehicleTypeValue });
    this.selectedTrailerId = selectedItem.id;
    this.selectedTrailerNo = selectedItem.trailerNumber;
  }
  
  onJobOrderSelection(selectedJO: any) {
    this.selectedJobOrder = selectedJO;
    this.selectedJobOrder.issueDate = DateUtils.formatDate(selectedJO.issueDate);
    this.selectedJobOrder.expiryDate = DateUtils.formatDate(selectedJO.expiryDate);
    console.log(this.selectedJobOrder);
  }

  clearJobOrderSelection() {
    this.selectedJobOrder = null;
  }

  resetForm() {
    this.gateInForm.reset({
      ownVehicle: false,
      ownMovement: false,
      cycle: 'IMPORT',
      gateInMode: '',
      laneNo: this.gateInForm.get('laneNo')?.value,
      inspectionType:'',
      trailerTypeId: {key: '', value: ''},
    });
    this.updateGateInOptions("IMPORT");
    this.ownVehicle = false;
    this.isOwnMovement = false;
    this.isOwnMovementDisabled = false;
    this.inspectionTypeRequired = false;
    this.clearJobOrderSelection();
  }

  saveForm() {
    this.gateInForm.markAllAsTouched();
    if (this.gateInForm.valid) {
      this.openConfirmDialog();
    }
  }

  updateGateInTime() {
    this.maxDateTime = getMaxDateTimeForDatePicker();
    this.gateInForm.get("gateInTime")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  openConfirmDialog() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);
    // Set the message in the modal
    confirmDialogRef.instance.title = "Gate-In Confirmation"
    confirmDialogRef.instance.message = "Do you want to confirm and save the changes?";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    // Subscribe to modal confirm action
    confirmDialogRef.instance.confirm.subscribe(() => {
      const gateInRequest = this.constructRequest();
      console.log("GateInRequest => ", gateInRequest);
        this.api.submitGateInRequest(gateInRequest).subscribe((response) => {
          this.toasterService.success("Gate-in was successful");
          this.resetForm();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
      confirmDialogRef.destroy();
    });

    // Subscribe to modal cancel action
    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  constructRequest(): any {
    const formData = this.gateInForm.value;
    const gateInRequest = {
        joNo: formData.jobOrderNo?.value,
        gateInType: formData.gateInMode,
        gateInVehicleType: InventoryType.TRAILER,
        laneNo: formData.laneNo,
        gateInTime: new Date(formData.gateInTime).getTime(),
        vehicle: {
          trailerMasterId: this.selectedTrailerId,
          vehicleNo: this.selectedTrailerNo,
          vehicleTypeId: formData.trailerTypeId?.key,
          inspectionType: formData.inspectionType ? formData.inspectionType : null,
          transporterId: formData.transporterId.key,
          ownVehicle: formData.ownVehicle,
          ownMovement: formData.ownMovement,
          driverName: formData.driverName,
          driverLicenseNo: formData.driverLicenseNo,
          truck: false
        },
    };
    return gateInRequest;
  }
}
