<div class="job-order-container">
  
  <div class="horizontal_tabs">
    <div class="tabs">
      <div class="tab" (click)="selectTab('basicDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'basicDetailsSection'">JO Details</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'basicDetailsSection'"></div>
      </div>

      <div class="tab" (click)="selectTab('itemDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'itemDetailsSection'">Item Details</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'itemDetailsSection'"></div>
      </div>

      <div class="tab" *ngIf="containerList.length > 0" (click)="selectTab('vendorDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'vendorDetailsSection'">Container Details</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'vendorDetailsSection'"></div>
      </div>
    </div>
    <div class="divider"></div>
  </div>

  <div class="section jo-details-section card-layout" [hidden]="selectedTab !== 'basicDetailsSection'">
    <app-dynamic-form
    [formConfig]="dynamicForm"
    [formGroup]="jobOrderForm">
    >
    </app-dynamic-form>
  </div>

  <div class="section" [hidden]="selectedTab !== 'itemDetailsSection'">
    <app-item-selector-panel
    #itemSelectorComponent
    [entity]="'CARGO'"
    [items]="itemList"
    [listReadOnly]="readOnlyMode || editMode"
    [allowedItemSize]="1"
    [validateBeforeItemAddition] = "true"
    (onItemSelection)="handleItemSelection($event)"
    (onItemDeletion)="handleItemDeletion($event)"
    (onItemAddition)="handleItemAddition($event)"
    (onItemAdditionRequest)="validateItemAddition($event)"
    [searchConfig]="itemSearchConfig"
    >
    </app-item-selector-panel>
  </div>

  <div class="section" [hidden]="selectedTab !== 'vendorDetailsSection'">
    <app-accordion-table
    #accordianTableComponent
    [searchConfig]="containerSearchConfig"
    [readOnly]="readOnlyMode"
    [tableHeaderAndData]= "containerTableHeaderAndData"
    (onAccordionOpen)="loadAccordionContent($event)"
    (onItemDeletion)="handleContainerDeletion($event)"
    (onItemAddition)="handleContainerAddition($event)"
    ></app-accordion-table>
  </div>

</div>
