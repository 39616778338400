import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  constructor(private http: HttpClient) {}

  loadJobOrderConfig(configName: string): Observable<any> {
    return this.http.get(`assets/configs/job-order/${configName}.config.json`);
  }
}
