<div class="job-order-container">
    <div class="page_header">
        <div class="left">
            <div class="title">{{ jobOrderLabel }} Job Order</div>
            <div class="sub_heading"></div>
        </div>
        <div class="right">
            @if (viewMode) {
                <button class="btn secondary_button" (click)="goBack()">
                    Go Back
                </button>
            } @else {
                <button class="btn secondary_button" (click)="cancel()">Cancel</button>
                <button class="btn primary_button" (click)="saveForm()">Save</button>
            }
        </div>
    </div>
    <ng-container #dynamicContainer></ng-container>
</div>

