<div class="container">

  <div class="page_header">
    @if (readOnlyMode) {
      <app-back-button></app-back-button>
    }
    <div class="left">
        <div class="title">
          <span>
            @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
            {Create New} Vendor
          </span>
        </div>
    </div>
    <div class="right">
        @if (!readOnlyMode) {
            <button class="btn secondary_button" (click)="goBack()">Cancel</button>
            <button class="btn primary_button" (click)="submitMasterForm()">Save</button>
        }
    </div>
  </div>

  <div class="horizontal_tabs">
    <div class="tabs">
      <div class="tab" (click)="selectTab('vendorDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'vendorDetailsSection'">Vendor Details</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'vendorDetailsSection'"></div>
      </div>

      <div class="tab" (click)="selectTab('manageServiceSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'manageServiceSection'">Manage Services</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'manageServiceSection'"></div>
      </div>
    </div>
    <div class="divider"></div>
  </div>

  <div class="vendor-content card-layout" [hidden]="selectedTab !== 'vendorDetailsSection'">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="grid-container">
            <div class="form-group">
              <label for="name" class="required_field_label" #formField
              >Vendor Name</label
              >
              <input
                id="name"
                name="name"
                formControlName="name"
                type="text"
                placeholder="Enter name"
                required
                autocomplete="name"
                [ngClass]="{
                  form_error:
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched &&
                    masterForm.get('name')?.errors
                }"
              />
              <div
                class="form_validation_error"
                [ngClass]="{form_error:
                  masterForm.get('name')?.invalid &&
                  masterForm.get('name')?.touched
                }"
              >
                <small *ngIf="masterForm.get('name')?.errors?.['required']"
                >Vendor Name is required</small
                >
              </div>
            </div>

            <div class="form-group">
              <label for="code" class="required_field_label" #formField
              >Vendor Code</label
              >
              <input
                id="code"
                name="code"
                formControlName="code"
                type="text"
                placeholder="Enter code"
                required
                autocomplete="code"
              />
              <div
                class="form_validation_error"
                [ngClass]="{
                  form_error:
                  masterForm.get('code')?.invalid &&
                  masterForm.get('code')?.touched
                }"
              >
                <small *ngIf="masterForm.get('code')?.errors?.['required']"
                >Code is required</small
                >
              </div>
            </div>

            <div class="form-group">
              <label for="serviceTaxNumber">Service Tax No.</label>
              <input
                id="serviceTaxNumber"
                name="serviceTaxNumber"
                formControlName="serviceTaxNumber"
                type="text"
                placeholder="Enter Service Tax Number"
              />
            </div>

            <div class="form-group">
              <label for="incomeTaxNumber">Income Tax No.</label>
              <input
                id="incomeTaxNumber"
                name="incomeTaxNumber"
                formControlName="incomeTaxNumber"
                type="text"
                placeholder="Enter Income Tax Number"
              />
            </div>

            <div class="form-group">
              <label> TDS Applicable </label>
              <select id="tdsApplicable" formControlName="tdsApplicable">
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>

            <div class="form-group">
              <label for="tdsNumber">TDS Exemption No.</label>
              <input
                id="tdsNumber"
                name="tdsNumber"
                formControlName="tdsNumber"
                type="text"
                placeholder="Enter TDS Number"
              />
            </div>

            <div class="form-group">
              <label for="tdsPercentage">TDS Percentage</label>
              <input
                id="tdsPercentage"
                name="tdsPercentage"
                formControlName="tdsPercentage"
                type="text"
                placeholder="Enter TDS Percentage"
              />
            </div>

            <div class="form-group">
              <label for="pdaBalance">PDA Balance</label>
              <input
                id="pdaBalance"
                name="pdaBalance"
                formControlName="pdaBalance"
                type="number"
                placeholder="Enter PDA Balance"
                min="0"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="head tabs">
          <div class="tab">Contact 1</div>
          <!-- <div class="tab">Contact 2</div> -->
        </div>

        <div class="fields">
          <div formArrayName="contactInformation">
            <div
              *ngFor="let contact of contactInformation.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="form-group text-area-form">
                <label for="address">Full Address</label>
                <textarea
                  id="address"
                  name="address"
                  formControlName="address"
                  type="text"
                  placeholder="Enter full address"
                  rows="4"
                ></textarea>
              </div>

              <div class="form-group text-area-form">
                <label for="billingAddress">Billing Address</label>
                <textarea
                  id="billingAddress"
                  name="billingAddress"
                  formControlName="billingAddress"
                  type="text"
                  placeholder="Enter Billing Address"
                  rows="4"
                ></textarea>
              </div>

              <div class="grid-container">
                <div class="form-group">
                  <label for="cityId">City</label>
                  <input
                    id="cityId"
                    name="cityId"
                    formControlName="cityId"
                    type="text"
                    placeholder="Enter City"
                  />
                </div>

                <div class="form-group">
                  <label for="countryId">Country</label>
                  <input
                    id="countryId"
                    name="countryId"
                    formControlName="countryId"
                    type="text"
                    placeholder="Enter Country"
                  />
                </div>

                <div class="form-group">
                  <label for="stateId">State</label>
                  <input
                    id="stateId"
                    name="stateId"
                    formControlName="stateId"
                    type="text"
                    placeholder="Enter State"
                  />
                </div>

                <div class="form-group">
                  <label for="pinCode">Postal Code</label>
                  <input
                    id="pinCode"
                    name="pinCode"
                    formControlName="pinCode"
                    type="text"
                    placeholder="Enter Postal Code"
                  />
                </div>

                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    formControlName="email"
                    type="text"
                    placeholder="Enter Email"
                  />
                </div>

                <div class="form-group">
                  <label for="mobileNumber1">Mobile Numer</label>
                  <input
                    id="mobileNumber1"
                    name="mobileNumber1"
                    formControlName="mobileNumber1"
                    type="text"
                    placeholder="Enter Mobile Number"
                  />
                </div>

                <div class="form-group">
                  <label for="mobileNumber2">Alternative Mobile Numer</label>
                  <input
                    id="mobileNumber2"
                    name="mobileNumber2"
                    formControlName="mobileNumber2"
                    type="text"
                    placeholder="Enter Alternative Mobile Number"
                  />
                </div>

                <div class="form-group">
                  <label for="defaultContact">Default Contact</label>
                  <select id="defaultContact" formControlName="defaultContact">
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="communicationPreference"
                  >Communication Preference</label
                  >
                  <select
                    id="communicationPreference"
                    formControlName="communicationPreference"
                    value="mobile"
                  >
                    <option value="mobile">Mobile Phone</option>
                    <option value="email">Email</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="panNumber">PAN Number</label>
                  <input
                    id="panNumber"
                    name="panNumber"
                    formControlName="panNumber"
                    type="text"
                    placeholder="Enter PAN Number"
                  />
                </div>

                <div class="form-group">
                  <label for="directLine">Direct Line</label>
                  <input
                    id="directLine"
                    name="directLine"
                    formControlName="directLine"
                    type="text"
                    placeholder="Enter Direct Line"
                  />
                </div>

                <div class="form-group">
                  <label for="boardLine">Board Line</label>
                  <input
                    id="boardLine"
                    name="boardLine"
                    formControlName="boardLine"
                    type="text"
                    placeholder="Enter Board Line"
                  />
                </div>

                <div class="form-group">
                  <label for="faxNumber">Fax</label>
                  <input
                    id="faxNumber"
                    name="faxNumber"
                    formControlName="faxNumber"
                    type="text"
                    placeholder="Enter Fax Number"
                  />
                </div>

                <div class="form-group">
                  <label for="gstNumber">GST No.</label>
                  <input
                    id="gstNumber"
                    name="gstNumber"
                    formControlName="gstNumber"
                    type="text"
                    placeholder="Enter GST Number"
                  />
                </div>

                <div class="form-group">
                  <label for="gstValidTill">GST Valid Till</label>
                  <input
                    id="gstValidTill"
                    name="gstValidTill"
                    formControlName="gstValidTill"
                    placeholder="GST Validity"
                    type="datetime-local"
                  />
                </div>

                <div class="form-group">
                  <label for="sez">Sez</label>
                  <select id="sez" formControlName="sez">
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="lut">LUT</label>
                  <select id="lut" formControlName="lut">
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>
              </div>

              <!-- <h4>Contact {{ i + 1 }}</h4> -->

              <!-- <label for="address">Address</label>
              <input id="address" type="text" formControlName="address" />

              <label for="mobileNumber1">Mobile Number 1</label>
              <input
                id="mobileNumber1"
                type="text"
                formControlName="mobileNumber1"
              /> -->

              <!-- Other contact fields can be added here similarly -->

              <!-- <button type="button" (click)="removeContact(i)">
                Remove Contact
              </button> -->
            </div>
          </div>

          <!-- <button type="button" (click)="addContact()">Add Contact</button> -->
        </div>
      </div>
    </form>
  </div>

  <div class="service-container card-layout" [hidden]="selectedTab !== 'manageServiceSection'">
    <div class="section-title">
      @if (readOnlyMode && serviceList.length > 0) {
      <span>Selected Service(s)</span>
      } @else if (readOnlyMode) {
      <span>No Service(s) selected</span>
      } @else {
      <span>Add Service(s)</span>
      }
    </div>
    <div *ngIf="!readOnlyMode" class="search-container">
      <app-autocomplete-dropdown
        id="search-bar"
        class="autocomplete-dropdown"
        [placeholder]="'Search and add Service'"
        [apiUrl]="'v1/services/list'"
        [selectFields]="['id', 'name', 'code', 'active']"
        [whereFields]="['name', 'code']"
        [limit]="10"
        [orderBy]="{'name': 'asc'}"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [displayColumns]="[
          {key: 'name', header: 'Service Name'},
          {key: 'code', header: 'Service Code' }
          ]"
        [additionalFilters]="[
                {
                  'name': 'active',
                  'alias': 'active',
                  'operator': 'EQ',
                  'value': ['true']
                }
              ]"
        [(ngModel)]="serviceSearchText"
        (suggestionSelected)="onServiceSelected($event)"
      >
      </app-autocomplete-dropdown>
      <button type="button" class="add-button secondary_button" [disabled]="disableAddButton" (click)="addSelectedService()">
        <app-svg-icon class="image-icon" [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
        <span>Add</span>
      </button>
    </div>

    <div class="service-table-container" *ngIf="serviceList.length > 0">
      <table class="table-view-data">
        <thead>
        <tr>
          <th><span>Sl No</span></th>
          <th><span>Service Name</span></th>
          <th><span>Active</span></th>
          <th><span>Make this Vendor default?</span></th>
          <th class="delete_column" *ngIf = "!readOnlyMode"><span></span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let result of serviceList; let i = index">
          <td><span>{{ i+1 }}</span></td>
          <td class="primary_column"><span>{{result.serviceName}}</span></td>
          <td><span>{{result.serviceActive ? 'Y' : 'N'}}</span></td>
          <td class="checkbox_column">
            <input type="checkbox" (click)="selectVendorAsDefault(i)" [readOnly]="readOnlyMode"  [checked]="result.isDefault ?? false" >
          </td>
          <td *ngIf = "!readOnlyMode" class="delete_column">
            <div class="icon-container">
              <app-svg-icon class="delete-icon"
                          [path]="'assets/icons/delete_icon.svg'"
                          (click)="removeService(i)"
            ></app-svg-icon>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
