import { Component, ViewChild, ViewChildren, Input, ViewContainerRef, ElementRef, QueryList } from '@angular/core';
import { DynamicFormService } from '../../../../../services/dynamic-form.service';
import { DynamicForm } from '../../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../../dynamic-form/dynamic-form.component';
import { ItemSelectorPanelComponent } from '../../../../item-selector-panel/item-selector-panel.component';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderAndData } from '../../../../../models/table-header-and-data';
import { JobOrderVendorComponent } from '../../vendor-form/job-order-vendor.component';
import { NoDataComponent } from '../../../../no-data/no-data.component';
import { AccordionTableComponent } from '../../../../accordion-table/accordion-table.component';
import { allowedJobOrderAdditionalInfoKeys, JobOrderInventory, JobOrderItem } from '../../../../../models/job-orders.interface';
import { OperationService } from '../../../../../services/operations.service';
import { LoadingService } from '../../../../../services/loading.service';
import { ToasterService } from '../../../../../services/toaster.service';
import { ConfirmDialogV2Component } from '../../../../confirm-dialog/confirm-dialog-v2.component';
import { DatePipe } from '@angular/common'
import { JobOrderFormActions } from '../host/manage-jo-renderer.component';
import { JobOrderType } from '../../../../../constants/operations-contsants';
import { JobOrdeTabViewComponent } from '../tab-view-component/job-order-tab-view.component';
import { convertEpochToISTDateTime } from '../../../../../utils/date-time-utils';

@Component({
  selector: 'app-container-delivery',
  standalone: true,
  imports: [DynamicFormComponent, ItemSelectorPanelComponent, CommonModule, AccordionTableComponent, ConfirmDialogV2Component],
  templateUrl: './container-delivery.component.html',
  styleUrl: './container-delivery.component.scss',
  providers:[DatePipe]
})
export class ContainerDeliveryComponent implements JobOrderFormActions {
  @Input() jobOrderType!: string;
  @Input() jobOrderId!: string;
  @Input() jobOrderConfig!: any;

  jobOrderForm!: FormGroup;
  dynamicForm!: DynamicForm;
  containerFormConfig!: DynamicForm;
  containerSearchConfig!: any;
  containerList: any = [];
  readOnlyMode:boolean = false;
  editMode: boolean = false;
  vendorMap: Map<string, any[]> =  new Map();
  selectedCHA: string = '';
  jobOrderInventoryList: JobOrderInventory[] = [];
  private initialVendorIds: Set<string> = new Set();
  private initialInventoryMap: Map<string, string> = new Map();
  private sectionMap: Map<string, any> = new Map();
  loadedAccordianIndexSet: Set<number> = new Set();
  protected readonly JobOrderVendorComponent = JobOrderVendorComponent;

  selectedTab: string = "basicDetailsSection";

  @ViewChild("basicDetailsSection") basicDetailsSection: ElementRef | undefined;
  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("vendorDetailsSection", { read: ElementRef }) vendorDetailsSection: ElementRef | undefined;
  @ViewChild('itemSelectorComponent', { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  @ViewChild(AccordionTableComponent) accordionTableComponent!: AccordionTableComponent;


  constructor (private dynamicFormService: DynamicFormService,
    private fb: FormBuilder,
    private api: OperationService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private datePipe: DatePipe
  ) {
    this.jobOrderForm = fb.group({
      containerDetails : this.fb.array([]),
    });
  }

  ngOnInit() {
    console.log(this.jobOrderConfig);
    this.dynamicForm = this.jobOrderConfig?.jobOrderDetailsFormElements;
    this.containerFormConfig = this.jobOrderConfig?.containerFormElements;
    this.containerSearchConfig = this.jobOrderConfig?.containerSearchConfig;
    this.dynamicFormService.addControlsToForm(this.jobOrderForm, this.dynamicForm);

    if (this.jobOrderId === "") {
      console.log("Job Order Id not found");
      this.readOnlyMode = false;
      this.editMode = false;
      this.setDefaultValues()
      return;
    }

    if (this.route.snapshot.url[0].path === "edit") {
      this.editMode = true;
      this.readOnlyMode = false;
    } else {
      this.editMode = false;
      this.readOnlyMode = true;
    }

    this.loadingService.show();
    this.loadJobOrderData(this.jobOrderId);
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  ngAfterViewInit() {
    if (this.basicDetailsSection) this.sectionMap.set("basicDetailsSection", this.basicDetailsSection.nativeElement);
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
  }


  get containerFormArray(): FormArray {
    return this.jobOrderForm.get('containerDetails') as FormArray;
  }

  handleItemSelection(event: { item: any, index: number }) {
    const selectedContainer = event.item;
    const containerFormGroup = this.containerFormArray.at(event.index) as FormGroup;

    const tableHeaderAndData: TableHeaderAndData = {
      headers: this.jobOrderConfig?.itemColumnMeta,
      data: selectedContainer?.importItemList
    }

    if (!this.vendorMap.has(selectedContainer.id)) {
      this.vendorMap.set(selectedContainer.id, []);
    }
    console.log("Inside selected item: ", this.vendorMap);
    const vendorComponentInput = {
      dynamicForm: this.containerFormConfig,
      formGroup: containerFormGroup,
      index: event.index,
      itemDetails: selectedContainer,
      vendorList: this.vendorMap.get(selectedContainer.id),
      readOnly: this.readOnlyMode,
      tableHeaderAndData: tableHeaderAndData
    };
    const componentsToLoad: any = [
      { component: JobOrdeTabViewComponent, data: vendorComponentInput }
    ];
    this.itemSelectorComponent?.loadComponents(componentsToLoad);
  }

  validateItemAddition(itemToBeAdded: any) {
    if (itemToBeAdded?.importItemList?.length < 1) {
      this.toasterService.error("No items associated with the selected container");
      return;
    }
    if (this.containerList.length < 1) {
      const missingCHAItem = itemToBeAdded?.importItemList?.find((item: any) => !item.chaId);
      if (missingCHAItem) {
        this.toasterService.error(`CHA is missing for the item: ${missingCHAItem.igmItemNo}`);
        return;
      }
    }
    if (!this.isValidItem(itemToBeAdded)) {
      this.toasterService.error("CHA should be same for the items");
      return;
    }
    this.itemSelectorComponent?.addValidatedItem(itemToBeAdded);
  }

  isValidItem(itemToBeAdded: any) {
    if (this.containerList.length < 1) {
      return true;
    }
    return itemToBeAdded?.importItemList.every((item: any) => item.chaId !== this.selectedCHA);
  }

  handleItemAddition(event: { item: any, index: number }) {
    this.dynamicFormService.addToFormArray(this.jobOrderForm, "containerDetails", this.containerFormConfig);
    const containerFormGroup = this.containerFormArray.at(event.index) as FormGroup;
    containerFormGroup.patchValue({
      ...event.item,
      gateInDate: convertEpochToISTDateTime(event.item?.gateInDate),
    });
    if (!this.vendorMap.has(event.item.id)) {
      this.vendorMap.set(event.item.id, []);
    }
    if (!this.selectedCHA) {
      this.selectedCHA = event.item?.importItemList[0].chaId;
    }
  }

  handleItemDeletion(event: {item: any, index: number}) {
    this.containerFormArray.removeAt(event.index);
    this.vendorMap.delete(event.item.id);
  }

  cancel() {
    this.confirmConcellation();
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/job-orders");
  }

  saveForm() {
    this.jobOrderForm.markAllAsTouched();

    const jobOrderRequest = this.mapJobOrderFormToRequest();
    console.log("Inside Save", jobOrderRequest);
    if (this.jobOrderForm.invalid) {
      this.scrollToFirstInvalidControl();
      this.printFormErrors(this.jobOrderForm);
      return;
    }

    if (this.editMode) {
        this.api.updateJobOrder(this.jobOrderId, jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    } else {
      this.api.saveJobOrder(jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    }

  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  // modifyChaFilter(result: any) {
  //   console.log("Inside onSearchSuggestionSelect: ", event);
  //   this.selectedCHA = result.chaId;
  //   if (!this.selectedCHA) {
  //     this.toasterService.error("Item doesn't contain CHA information")
  //   }
  //   const additionalFilters: any[] = this.containerSearchConfig?.additionalFilters ?? [];
  //   const existingFilterIndex = additionalFilters.findIndex(filter => filter.name === 'chaId');

  //   if (existingFilterIndex !== -1) {
  //     additionalFilters[existingFilterIndex].value = [this.selectedCHA];
  //   } else {
  //     additionalFilters.push({
  //       name: 'chaId',
  //       alias: 'chaId',
  //       operator: 'EQ',
  //       value: [this.selectedCHA]
  //     });
  //   }
  //   this.containerSearchConfig = {
  //     ...this.containerSearchConfig,
  //     additionalFilters: additionalFilters
  //   };
  //   console.log(this.containerSearchConfig);
  //   console.log(this.jobOrderConfig?.containerSearchConfig);
  // }

  mapJobOrderFormToRequest(): any {
    const jobOrderFormValue = this.jobOrderForm.value;
    const jobOrderAdditionalInfo = Object.keys(jobOrderFormValue).reduce((acc: any, key) => {
      if (allowedJobOrderAdditionalInfoKeys.includes(key)) {
        acc[key] = jobOrderFormValue[key];
      }
      return acc;
    }, {});
    jobOrderAdditionalInfo.transporterId = this.jobOrderForm.get('transporterId')?.value?.key ?? null;
    jobOrderAdditionalInfo.transporterName = this.jobOrderForm.get('transporterId')?.value?.value ?? null;

    const isJobOrderAdditionalInfoEmpty = Object.values(jobOrderAdditionalInfo).every(
      (value) => value === undefined || value === null || value === ''
    );
    const jobOrderInventoryList: JobOrderInventory[] = [];
    this.containerList.forEach((container: any) => {
      const containerData: JobOrderInventory = {
        joInvId: this.initialInventoryMap.get(container.id),
        inventoryId: container.id,
        inventoryNo: container.containerNo,
        inventoryType: 'CONTAINER'
      };
      jobOrderInventoryList.push(containerData);
    });
    const jobOrderVendorMap = Object.fromEntries(this.vendorMap);
    const deletedJobOrderInventoryIds = this.getDeletedInventoryIds(jobOrderInventoryList);
    return {
      jobOrderType: this.jobOrderType,
      expiryDate: new Date(this.jobOrderForm.get('expiryDate')?.value).getTime(),
      jobOrderAdditionalInfo : isJobOrderAdditionalInfoEmpty ? null : jobOrderAdditionalInfo,
      jobOrderInventoryList,
      jobOrderVendorMap,
      deletedJobOrderInventoryIds,
      deletedJobOrderVendorIds: this.getDeletedVendorIds(),
    };
  }

  confirmConcellation() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? "
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }


  loadJobOrderData(jobOrderId: string) {
    let data = null;
    this.loadingService.show();
    this.api.getJobOrderById(jobOrderId, true, true, true, false).subscribe({
      next: (response: any) => {
        data = response?.data;
        this.hydrateForm(data);
        this.loadingService.hide();
      },
      error: (error) => {
        console.error(error);
        this.toasterService.error("Failed to get job order data");
        this.loadingService.hide();
      }
    });
  }

  hydrateForm(jobOrderData: any) {
    this.containerList = jobOrderData?.containerList;
    if (jobOrderData?.vendorDetails) {
      this.vendorMap = new Map(Object.entries(jobOrderData.vendorDetails));
      this.vendorMap.forEach((vendors) => {
        vendors.forEach((vendor: any) => {
          if (vendor.jovId) {
            this.initialVendorIds.add(vendor.jovId);
          }
        });
      });
    }
    jobOrderData?.jobOrderInventoryList.forEach((joInv: JobOrderInventory) => {
      if (joInv.inventoryId && joInv.joInvId) {
        this.initialInventoryMap.set(joInv.inventoryId, joInv.joInvId);
      }
    });
    this.containerList.forEach((container: any, index: number) => this.handleItemAddition({item: container, index}));
    console.log(this.vendorMap);
    this.jobOrderForm.patchValue({
      ...jobOrderData,
      ...jobOrderData?.additionalDetails,
      expiryDate: convertEpochToISTDateTime(jobOrderData?.expiryDate),
      issueDate: convertEpochToISTDateTime(jobOrderData?.issueDate),
      transporterId: {key: jobOrderData?.additionalDetails?.transporterId, value: jobOrderData?.additionalDetails?.transporterName}
    });
    this.selectedCHA = this.containerList[0]?.importItemList[0].chaId;
    if (this.readOnlyMode) {
      this.jobOrderForm.disable();
    }
  }

  getDeletedVendorIds(): string[] {
    const currentVendorIds = new Set<string>();
    this.vendorMap.forEach((vendors) => {
      vendors.forEach((vendor: any) => {
        if (vendor.jovId) {
          currentVendorIds.add(vendor.jovId);
        }
      });
    });
    const deletedVendorIds: string[] = [];
    this.initialVendorIds.forEach((jovId) => {
      if (!currentVendorIds.has(jovId)) {
        deletedVendorIds.push(jovId);
      }
    });
    return deletedVendorIds;
  }

  getDeletedInventoryIds(finalJoInvList: any[]): string[] {
    const deletedInventoryIds: string[] = [];
    const currentInvIds = new Set<string>(
      finalJoInvList
        .filter((joInv: JobOrderInventory) => joInv?.joInvId)
        .map((joInv: JobOrderInventory) => joInv.joInvId!)
    );

    this.initialInventoryMap.forEach((value, key) => {
      if (!currentInvIds.has(value)) {
        deletedInventoryIds.push(value);
      }
    });
    return deletedInventoryIds;
  }

  setDefaultValues() {
    this.jobOrderForm.get("issueDate")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  printFormErrors(formGroup: FormGroup | FormArray, path: string = ''): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = path ? `${path}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.printFormErrors(control, controlPath);
      } else if (control && control.invalid) {
        console.log(`Control: ${controlPath}`, control.errors);
      }
    });
  }
}
