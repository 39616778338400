import { Injectable } from '@angular/core';
import { CargoItem } from "../models/cargo-item.interface";
import { Stats } from "../models/stats.interface";
import { Container } from "../models/container.interface";

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor() { }

  createJobOrderStatsForTallyEditMode(selectedJobOrder: any) {
    console.log(selectedJobOrder)
    return [{
      displayLabel: "Job Order No.",
      value: selectedJobOrder?.jobOrderNo
    },
      {
        displayLabel: "Tally Type",
        value: selectedJobOrder?.tallyType
      },

    ];
  }

  createInventoryDetailStatsForTally(cargoItem: CargoItem): Stats[] {
    return [
      {
        displayLabel: "Item No.",
        value: cargoItem?.itemNo?.toString(),
        iconUrl: "assets/icons/package_icon.svg"
      },
      {
        displayLabel: "Sub Item No.",
        value: cargoItem?.subItemNo?.toString()
      },
      {
        displayLabel: "HBL No.",
        value: cargoItem?.hblNo?.toString()
      },
      {
        displayLabel: "Importer",
        value: cargoItem?.importerFromIgm
      },
      {
        displayLabel: "Consignee",
        value: cargoItem?.consignee
      },
      {
        displayLabel: "Package Manifested",
        value: cargoItem?.numberOfPackages?.toString()
      },
      {
        displayLabel: "Weight Manifested",
        value: cargoItem?.grossWeight?.toString()
      }
    ];
  }

  createContainerStatsForTally(container: Container): Stats[] {
    return [
      {
        displayLabel: "Container No.",
        value: container?.containerNo?.toString()
      },
      {
        displayLabel: "Size",
        value: container.containerSize
      },
      {
        displayLabel: "Type",
        value: container?.containerType
      },
      {
        displayLabel: "ISO Code",
        value: container?.isoCode
      },
      {
        displayLabel: "Gross Wt",
        value: container?.grossWeight?.toString()
      },
      {
        displayLabel: "Gross Volume",
        value: ""
      },
      {
        displayLabel: "NOP",
        value: ""
      },
      {
        displayLabel: "Line Seal No",
        value: container.lineSealNo
      }
    ];
  }
}
