{
  "examinationTallyFormElements": {
    "formRows": [

      {
        "formGroups": [
          {
            "control": {
              "hidden": true,
              "label": "inventoryId",
              "displayLabel": "Inventory ID",
              "disabled": false,
              "placedHolder": "Enter Inventory Id",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "inventoryNo",
              "displayLabel": "Inventory No",
              "disabled": false,
              "placedHolder": "Enter Inventory No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "documentNo",
              "displayLabel": "Document No",
              "disabled": false,
              "placedHolder": "Enter Document No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          },
          {
            "control": {
              "hidden": true,
              "label": "cargoId",
              "displayLabel": "Cargo Id",
              "disabled": false,
              "placedHolder": "Enter Cargo No",
              "fieldInfo": {
                "dataType": "string"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "sealCutDate",
              "displayLabel": "Seal Cutting Date",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "date"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "startTime",
              "displayLabel": "Start Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Start Time is required"
                }
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "endTime",
              "displayLabel": "End Time",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "datetime-local"
              },
              "validators": [
                {
                  "type": "required",
                  "message": "End Time is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "actualExaminationPercentage",
              "placeHolder": "Enter Examination %",
              "displayLabel": "Examination %",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [

          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "examinedPackages",
              "displayLabel": "NOP Examined %",
              "placeHolder": "Enter NOP Examined %",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          },
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "examinedWeight",
              "displayLabel": "Weight Examined %",
              "placeHolder": "Enter Weight Examined %",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": [
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "examinedVolume",
              "displayLabel": "Volume Examined %",
              "placeHolder": "Enter Volume Examined %",
              "disabled": false,
              "fieldInfo": {
                "dataType": "number"
              },
              "validators": []
            }
          },
          {
            "style": {
              "gridColumn": "span 4"
            },
            "control": {
              "label": "condition",
              "displayLabel": "Condition",
              "disabled": false,
              "placedHolder": "Select",
              "fieldInfo": {
                "dataType": "select",
                "options": [
                  {
                    "label": "Bad",
                    "value": "BAD"
                  },
                  {
                    "label": "Not Survey",
                    "value": "NOT_SURVEY"
                  },
                  {
                    "label": "Sound",
                    "value": "SOUND"
                  },
                  {
                    "label": "Damaged",
                    "value": "DAMAGED"
                  }
                ]
              },
              "validators": [
                {
                  "type": "required",
                  "message": "Condition is required"
                }
              ]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": {
              "gridColumn": "span 8"
            },
            "control": {
              "label": "remarks",
              "displayLabel": "Remarks",
              "disabled": false,
              "placedHolder": "Enter remarks",
              "fieldInfo": {
                "dataType": "textarea"
              },
              "validators": []
            }
          }
        ]
      }
    ]
  }
}

