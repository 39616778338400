import { ChangeDetectorRef, Component, ComponentRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { ItemSelectorPanelComponent } from "../../../../item-selector-panel/item-selector-panel.component";
import { StatsComponent } from "../../../../stats/stats.component";
import { DynamicForm } from "../../../../../models/dynamic-form";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { EditableTableHeader } from "../../../../../models/editable-table-header.interface";
import { JobOrderVendorsGroupByInventoryId } from "../../../../../models/job-order-vendor.interface";
import { JobOrderInventoryDetails } from "../../../../../models/job-order-inventory-details.interface";
import { Stats } from "../../../../../models/stats.interface";
import { OperationService } from "../../../../../services/operations.service";
import { LoadingService } from "../../../../../services/loading.service";
import { DynamicFormService } from "../../../../../services/dynamic-form.service";
import { ToasterService } from "../../../../../services/toaster.service";
import { Router } from "@angular/router";
import LCLDeliveryTallyFromJson from "../config/LCLDeliveryTally.json";
import { JobOrderDetails } from "../../../../../models/job-order-details.interface";
import { CargoItem } from "../../../../../models/cargo-item.interface";
import { StatsHorizontalComponent } from "../../../../stats-horizontal/stats-horizontal.component";
import { DynamicFormComponent } from "../../../../dynamic-form/dynamic-form.component";
import { EditableTableComponent } from "../../../../editable-table/editable-table.component";
import { VendorDetailsComponent } from "../vendor-details/vendor-details.component";
import { getTallyTypeByJobOrderType } from "../../../../../constants/operations-contsants";
import { Tally } from "../../../../../models/tally.interface";
import { ManageTally } from "../manage-tally.interface";

@Component({
  selector: 'app-manage-tally-smtp-container-delivery',
  standalone: true,
  imports: [
    ItemSelectorPanelComponent,
    StatsComponent
  ],
  templateUrl: './manage-tally-smtp-container-delivery.component.html',
  styleUrl: './manage-tally-smtp-container-delivery.component.scss'
})
export class ManageTallySmtpContainerDeliveryComponent implements ManageTally{
  private groupedTrailerTruckByTrailerNumber: [] = [];
  dataLoaded: boolean = false;
  inventoryDetails: any[] = [];
  truckTrailerDetails: any[] = [];
  lclDeliveryTallyFormJson!: DynamicForm;
  lclDeliveryTallyForm!: FormGroup;
  editableConfig: EditableTableHeader[] = [];
  jobOrderVendorsGroupByInventoryId!: JobOrderVendorsGroupByInventoryId;
  originalDeliveryNopMap: Map<string, number> = new Map<string, number>();
  deliveredNopMapByTruck = new Map<string, Map<string, number>>();
  truckAndCargoMap = new Map<string, any[]>;

  @Input() jobOrderInventoryDetails!: JobOrderInventoryDetails;
  @Input() jobOrderStats!: Stats[];

  @ViewChild("itemSelectorComponent", { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;

  constructor(private operationService: OperationService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private dynamicFormService: DynamicFormService,
              private toasterService: ToasterService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadingService.show();
    this.lclDeliveryTallyFormJson = LCLDeliveryTallyFromJson.lclDeliveryTallyFormElements;
    this.editableConfig = LCLDeliveryTallyFromJson.editableTableConfig;

    this.lclDeliveryTallyForm = this.fb.group({
      tallyDetails: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["jobOrderInventoryDetails"] && changes["jobOrderInventoryDetails"].currentValue) {

      this.operationService.getJobOrderDetails(this.jobOrderInventoryDetails?.jobOrderId, true, true, true, true).subscribe({
        next: (res: any) => {
          const data: JobOrderDetails = res?.data;
          this.truckTrailerDetails = data?.trailerTruckList;
          // this.inventoryDetails = [];

          data.cargoList.forEach((cargo: CargoItem) => {
            this.originalDeliveryNopMap.set(cargo.igmItemNo, cargo.numberOfPackages);
          });

          this.truckTrailerDetails.forEach(truckTrailer => {
            // this.inventoryDetails.push([...data?.cargoList]);
            this.truckAndCargoMap.set(truckTrailer.id, JSON.parse(JSON.stringify(data.cargoList.map((cargo: CargoItem) => {
              // cargo.remainingNumberOfPackages = this.originalDeliveryNopMap.get(cargo.igmItemNo) ?? 0;
              cargo.disabled = false;
              return cargo;
            }))));
          });
          this.jobOrderVendorsGroupByInventoryId = data?.vendorDetails;


          // this.inventoryDetails.forEach(inventory => {
          //   inventory.forEach((cargo: CargoItem) => {
          //     cargo.remainingNumberOfPackages = this.originalDeliveryNopMap.get(cargo.igmItemNo) ?? 0;
          //     cargo.disabled = false
          //   });
          // });

          // this.truckAndCargoMap.forEach((cargoList, truckId) => {
          //   cargoList.forEach((cargo: CargoItem)=>{
          //
          //   })
          // })

          this.addTallyDetailsFromArrayForAssociatedTrucks(this.truckTrailerDetails);
          this.groupedTrailerTruckByTrailerNumber = this.groupTruckTrailerDetailsByTrailerNo(this.truckTrailerDetails);

          this.dataLoaded = true;
          this.loadingService.hide();
        },
        error: (error: any) => {
          console.log(error);
        }
      });

    }
  }

  get itemFormArray() {
    return this.lclDeliveryTallyForm.get("tallyDetails") as FormArray;
  }

  handleItemSelection(event: { item: any; index: number }) {
    this.changeDetectorRef.detectChanges()
    const smallElement = document.createElement("small");
    const formGroup = this.itemFormArray.at(event.index) as FormGroup;

    formGroup.get("inventoryId")?.setValue(event.item.id);
    formGroup.get("inventoryNo")?.setValue(event.item.vehicleNo);

    const componentsToLoad: any = [
      { component: StatsHorizontalComponent, data: { stats: this.getTruckTrailerStatsFor(event?.item?.vehicleNo) } },
      { component: DynamicFormComponent, data: { formConfig: this.lclDeliveryTallyFormJson, formGroup: formGroup } },
      {
        component: EditableTableComponent, data: {
          title: "Item information",
          tableHeaders: this.editableConfig,
          tableData: this.truckAndCargoMap.get(event.item.id)
        }
      }
    ];
    this.checkAndAddVendors(event, componentsToLoad);

    const componentRefs = this.itemSelectorComponent?.loadComponents(componentsToLoad);

    const editableTableComponentRef = componentRefs?.find(ref => ref.instance instanceof EditableTableComponent) as ComponentRef<EditableTableComponent>;
    if (!editableTableComponentRef) {
      return;
    }
    editableTableComponentRef.instance.dataUpdated.subscribe((data) => {
      this.truckAndCargoMap.set(event.item.vehicleId, data.value);

      data.value.forEach((cargo: any) => {
        if (!this.deliveredNopMapByTruck.has(cargo.igmItemNo)) {
          this.deliveredNopMapByTruck.set(cargo.igmItemNo, new Map());
        }
        const vehicleMap = this.deliveredNopMapByTruck.get(cargo.igmItemNo);
        vehicleMap?.set(event.item.vehicleNo, cargo.deliveryNOP);

        if (vehicleMap) {
          const totalDeliveredNop = Array.from(vehicleMap.values()).reduce((acc, val) => acc + Number(val), 0);
          const originalNop = this.originalDeliveryNopMap.get(cargo.igmItemNo) ?? 0;
          data.inputElement.max = originalNop - totalDeliveredNop;
          this.truckAndCargoMap.forEach(inventory => {
            inventory.forEach((i: any) => {
              if (i.igmItemNo !== cargo.igmItemNo)
                return;
              const remaining = originalNop - totalDeliveredNop;
              i.remainingNumberOfPackages = remaining;
              i.disabled = remaining <= 0 && i.deliveryNOP === undefined;
            });
          });
        }
      });
    });
  }

  private checkAndAddVendors(event: { item: any; index: number }, componentsToLoad: any) {
    if (this.jobOrderVendorsGroupByInventoryId[event.item?.id]?.length > 0) {
      componentsToLoad.splice(1, 0, {
        component: VendorDetailsComponent,
        data: { jobOrderVendors: this.jobOrderVendorsGroupByInventoryId[event.item?.id] }
      });
    }
  }

  cancel(): void {
  }

  save(): void {
    this.lclDeliveryTallyForm.markAllAsTouched();
    // if (!this.lclDeliveryTallyForm.valid) {
    //   console.log("Form is invalid");
    //   return;
    // }
    console.log(this.lclDeliveryTallyForm.value);
    const tallyData = {
      jobOrderNo: this.jobOrderInventoryDetails?.jobOrderNo,
      tallyType: getTallyTypeByJobOrderType(this.jobOrderInventoryDetails?.jobOrderType)?.code,
      tallyItems: this.lclDeliveryTallyForm.value.tallyDetails.map((detail: any) => {
        const cargoList = this.truckAndCargoMap.get(detail.inventoryId) || [];
        return cargoList.map((cargo: any) => ({
          ...detail,
          cargoId: cargo.id,
          documentNo: cargo.igmItemNo,
          itemPackagesLoaded: cargo.deliveryNOP,
          itemWeightLoaded: cargo.deliveryGrossWt,
          itemVolumeLoaded: cargo.deliveryGrossVolume,
          type: "stuffing_tally"
        }));
      }).flat(),

    };
    console.log(tallyData);
    this.saveTally(tallyData);
  }

  private saveTally(tallyData: Tally) {
    this.loadingService.show()
    this.operationService.saveTally(tallyData).subscribe({
      next: (response: any) => {
        this.toasterService.success("Tally saved successfully");
        this.goBack();
        console.log(response.data);
        this.loadingService.hide()
      },
      error: (error: any) => {
        this.toasterService.error(error.error.errorDesc)
        this.loadingService.hide()
      }
    });
  }

  private goBack() {
    this.router.navigateByUrl("/manage-operations/import/tally");
  }

  private addTallyDetailsFromArrayForAssociatedTrucks(associatedTrucks: Array<any> | undefined) {
    associatedTrucks?.forEach((truck: any) => {
      this.dynamicFormService.addToFormArray(this.lclDeliveryTallyForm, "tallyDetails", this.lclDeliveryTallyFormJson);
    });
  }

  private groupTruckTrailerDetailsByTrailerNo(truckTrailerDetails: any) {
    if (truckTrailerDetails.length === 0) {
      return;
    }

    return truckTrailerDetails.reduce((acc: any, detail: any) => {
      const trailerNo = detail?.vehicleNo;
      if (!acc[trailerNo]) {
        acc[trailerNo] = [];
      }
      acc[trailerNo].push(detail);
      return acc;
    }, {});
  }

  private getTruckTrailerStatsFor(trailerNo: any): any {
    const truckDetails: any = this.groupedTrailerTruckByTrailerNumber[trailerNo][0];
    return [{
      displayLabel: "Truck No.",
      value: truckDetails?.vehicleNo
    },
      {
        displayLabel: "Transporter Name",
        value: truckDetails?.transporterName
      },
      {
        displayLabel: "Vehicle Type",
        value: truckDetails?.vehicleTypeId
      },
      {
        displayLabel: "Driver Name",
        value: truckDetails?.driverName
      },
      {
        displayLabel: "Driver License No",
        value: truckDetails?.driverLicenseNo
      }
    ];
  }
}
