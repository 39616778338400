import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { TableListRequest } from "../models/table-list-request.interface";
import { Observable } from "rxjs";

const equipmentMasterEndPoint = "v1/equipments";
const serviceMasterEndPoint = "v1/services";

@Injectable({
  providedIn: "root"  // Ensures the service is available app-wide
})
export class MasterService {

    private baseUrl: string = environment.apiBaseUrl;

    constructor(private http: HttpClient) {
    }

    getEquipmentStats(): Observable<any> {
        return this.http.get(`${this.baseUrl}/${equipmentMasterEndPoint}/stats`);
    }

    getEquipmentList(listRequest: TableListRequest): Observable<any> {
        return this.http.post(
            `${this.baseUrl}/${equipmentMasterEndPoint}/list`,
            listRequest
        );
    }

    setEquipmentStatus(shouldSetActive: boolean, idList: string[]) {
        return this.http.patch(
            `${this.baseUrl}/${equipmentMasterEndPoint}/status?status=${shouldSetActive}`,
            idList
        );
    }

    getEquipment(id: string) {
        return this.http.get(
            `${this.baseUrl}/${equipmentMasterEndPoint}/${id}`
        );
    }

    addEquipment(equipment: any) {
        return this.http.post(
            `${this.baseUrl}/${equipmentMasterEndPoint}`,
            equipment
        );
    }

    updateEquipmentMaster(id: string, equipment: any) {
        return this.http.put(
            `${this.baseUrl}/${equipmentMasterEndPoint}/${id}`,
            equipment
        );
    }

    listVendorsByService(serviceId: string, onlyActive: boolean) {
        return this.http.get(
            `${this.baseUrl}/${serviceMasterEndPoint}/${serviceId}/vendors?activeOnly=${onlyActive}`
        );
    }

    listEquipmentsByService(serviceId: string, onlyActive: boolean) {
        return this.http.get(
            `${this.baseUrl}/${serviceMasterEndPoint}/${serviceId}/equipments?activeOnly=${onlyActive}`
        );
    }
}