import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { CheckboxComponent } from "../../../common/checkbox/checkbox.component";
import { MasterService } from '../../../../services/masters-service';
import { LoadingService } from '../../../../services/loading.service';
import { ToasterService } from '../../../../services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-equipment',
  standalone: true,
  imports: [SharedModule, CheckboxComponent],
  templateUrl: './manage-equipment.component.html',
  styleUrl: './manage-equipment.component.scss'
})
export class ManageEquipmentComponent {

  @Input() equipmentId: string = '';
  @Output() onSuccessfulSave = new EventEmitter<void>();

  equipmentForm!: FormGroup;

  constructor(private masterService: MasterService,
              private loadingService: LoadingService,
              private toasterService: ToasterService,
              private fb: FormBuilder,
              private router: Router
  ) {
    this.equipmentForm = this.initForm();
  }

  ngOnInit() {
    console.log("equipmentId", this.equipmentId);
    if (this.equipmentId) this.loadEquipmentData();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      "name": ["", Validators.required],
      "description": [null],
    })
  }

  closeRightPanel!: (params?: any) => void;


  close(params?: any) {
    if (this.closeRightPanel) {
      this.closeRightPanel(params);
    }
  }


  onSubmit(): void {
    this.equipmentForm?.markAllAsTouched();
    if (!this.equipmentForm.valid) return;
    const equipmentData = this.equipmentForm.value;
    const action$ = this.equipmentId ? this.masterService.updateEquipmentMaster(this.equipmentId, equipmentData) : this.masterService.addEquipment(equipmentData);

    action$.subscribe({
      next: () => {
        const message = this.equipmentId
          ? `Equipment ${equipmentData.name} has been updated!`
          : `Equipment ${equipmentData.name} has been added!`;
        this.toasterService.success(message);
        this.loadingService.hide();
        this.onSuccessfulSave.emit();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc || 'An error occurred');
        console.error('Error submitting form:', err);
        this.loadingService.hide();
      },
    });
  }

  private loadEquipmentData(): void {
      this.loadingService.show();
      this.masterService.getEquipment(this.equipmentId)
        .subscribe({
          next: (res: any) => {
            const equipmentData: any = res.data;
            this.patchForm(equipmentData);
            this.loadingService.hide();
          },
          error: (err) => {
            console.error('Error loading country data:', err);
            this.toasterService.error('Failed to load country data');
            this.loadingService.hide();
          },
      });
  }

  private patchForm(equipment: any):void {
    this.equipmentForm.patchValue(equipment)
  }
}
