<div class="notification-container">
    <div class="icon-wrapper">
      <app-svg-icon
      class="calendar-icon"
      [path]="'assets/icons/calendar_icon.svg'"
      >
        
      </app-svg-icon>
    </div>
    <div class="date-container">
      <div class="date-info">
        <span class="date-label">Issue Date: </span>
        <span class="date-value">{{issueDate}}</span>
      </div>
      <div>
        <span class="date-label">Expiry Date: </span>
        <span class="date-value">{{expiryDate}}</span>
      </div>
    </div>
  </div>