{
    "headers": [
        {
            "displayLabel": "Container No",
            "fieldName": "containerNo"
        },
        {
            "displayLabel": "Size",
            "fieldName": "containerSize"
        },
        {
            "displayLabel": "Type",
            "fieldName": "containerType"
        },
        {
            "displayLabel": "Line Seal No",
            "fieldName": "lineSealNo"
        },
        {
            "displayLabel": "Arrival Date",
            "fieldName": "gateInDate"
        },
        {
            "displayLabel": "DO Delivery Mode",
            "fieldName": "deliveryModeFromDO"
        },
        {
            "displayLabel": "DO Number",
            "fieldName": "doNo"
        },
        {
            "displayLabel": "DO Date",
            "fieldName": "doDate"
        },
        {
            "displayLabel": "DO Validity Date",
            "fieldName": "doValidityDate"
        },
        {
            "displayLabel": "Empty Drop Location",
            "fieldName": "toLocationName"
        }
    ]
}